* {
  box-sizing: border-box;
}

html {
  font-family: Arial, sans-serif;
  font-size: 0.9rem;
  color: $white;
  background-color: $bg-color-1;
  overflow-y: scroll;
  color-scheme: dark;

  // Remove tap highlight
  // https://stackoverflow.com/a/29961714/4353620
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;

  // overflow-y: auto; // scroll; // We will show the scroll bar all the time
}

body {
  #root {
    display: block;
  }

  #modal-root {
    display: block;
  }

  &.modal-open {
    position: fixed;
    overflow-y: hidden;
    width: 100vw;
  }
}

span {
  word-break: break-word !important;
}

a,
.link {
  color: $link-color;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  transition: color 200ms;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: $link-hover-color;
    }
  }

  &:active {
    color: $link-active-color;
  }

  &:focus {
  }
}
