.oi-upload-empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 161px;
  width: 161px;
  border-radius: 50%;
  background-image: linear-gradient(180deg, $oilogopink 0%, $oilogoblue 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  position: relative;

  &.drag-reject {
    background: #46474f;
  }

  &__inner-circle {
    background-color: #191b27;
    border-radius: 50%;
    width: 147px;
    height: 147px;
    display: flex;
    align-content: center;
    justify-content: center;

    &.drag-active {
      background-color: #252730;
    }
  }

  &__center-icon {
    font-size: 58px;
    margin: auto;

    &.drag-reject {
      color: #46474f;
    }
  }
}
