.discover-carousel {
  .carousel-button {
    background-color: rgba($color: #000000, $alpha: 0.4);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    z-index: 100;
    font-size: 24px;

    justify-content: center;
    color: white;
    transition: 0.2s;
    cursor: pointer;
    display: none;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #252730;
      }
    }
  }
  .left-button {
    position: absolute;
    left: 44px;
    top: 50%;
  }
  .right-button {
    position: absolute;
    right: 44px;
    top: 50%;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .carousel-button {
        display: flex;
      }
    }
  }
  .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    width: 100%;

    .custom-dot-list {
      justify-content: flex-end;
      height: 100%;
      top: -26px;
      right: 6px;

      @media screen and (max-width: 450px) {
        top: -20px;
      }
      .react-multi-carousel-dot {
        button {
          display: inline-block;
          width: 22px;
          height: 5px;
          border-radius: 12px;
          opacity: 1;
          transition: background 0.3s;
          border: none;
          margin: 0;
          margin-right: 6px;
          outline: 0;
          cursor: pointer;
          background: #474951;
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: -9px;
            left: -2px;
            width: 26px;
            height: 22px;
          }
        }
      }

      .react-multi-carousel-dot--active {
        button {
          background: white;
        }
      }
    }

    .react-multi-carousel-track {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform, transition;
    }

    .react-multi-carousel-dot-list {
      height: 16px;
      position: absolute;
      top: -10px;
      display: flex;
      left: 0;
      right: 0;
      justify-content: center;
      margin: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      text-align: center;
    }
  }
}
