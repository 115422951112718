.featuredCollections {
  width: 100%;
  margin-top: 30px;
  padding: 0 15px;

  .discover-carousel {
    border-bottom: 1px solid #2e3247;
    height: 350px;
  }

  &__descriptionArea {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__header {
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 39px;
    margin-left: 0.4rem;
  }

  &__icon {
    font-size: 42px;
    margin-bottom: 28px;

    svg {
      width: 42px;
    }
  }

  &__link {
    margin-top: 8px;
    margin-right: auto;
    font-size: 18px;
    line-height: 27px;
    color: #00b7ff !important;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    transition: color 200ms;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    color: $white;
  }

  &__description {
    margin-top: 28px;
    font-size: 18px;
    line-height: 27px;
    color: $grey;
  }

  @media screen and (max-width: 800px) {
    padding: 0 4px;
  }

  @media screen and (min-width: 800px) {
    .discover-carousel .react-multi-carousel-list .react-multi-carousel-dot-list {
      display: none;
    }
  }

  @media screen and (max-width: 650px) {
    .discover-carousel {
      height: 350px;
    }
    &__card {
      height: 225px;
    }

    &__header {
      font-size: 24px;
      margin-bottom: 24px;
    }

    &__icon {
      font-size: 32px;
      margin-bottom: 12px;

      svg {
        width: 32px;
      }
    }

    &__description {
      margin-top: 14px;
      font-size: 16px;
      line-height: 20px;
    }

    &__link {
      font-size: 16px;
    }
  }
}

.featuredCollectionsCard {
  border-radius: 15px;
  overflow: hidden;
  height: 300px;
  margin: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $lightblue;
  background-color: #232536;
  border-radius: 15px;
  position: relative;
  flex: 1;
  cursor: pointer;

  &__cover-image-container {
    aspect-ratio: 16/9;

    width: 100%;
    height: calc(100% - 70px);

    img {
      object-fit: cover;
    }
  }

  &__footer {
    width: 100%;
    background-color: $lightblue;
    height: 70px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 18px;
  }

  &__info {
    margin-left: 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--name {
      font-size: 16px;
      line-height: 18px;
    }

    &--slug {
      font-size: 14px;
      font-weight: 600;
      a {
        color: $oiblue !important;
      }
    }
  }

  &__chain-icon {
    margin: 0 auto;
    margin-top: 13px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: absolute;
    bottom: 82px;
    right: 12px;

    &--ethereum {
      background-color: $ethereum;
    }
    &--polygon {
      background-color: $polygon;
    }
    &--avalanche {
      background-color: $avalanche;
    }
    &--binance {
      background-color: $binance;
    }
    &--default {
      background-color: $oigreen;
    }
  }
}
