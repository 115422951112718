.productFeatures {
  margin: 0 30px;
  .featured {
    color: $oiblue;
  }
  &__more {
    margin-top: 10px;
    margin-right: auto;
    font-size: 18px;
    line-height: 27px;
    cursor: pointer;
    text-decoration: none;
    outline: none;
    transition: color 200ms;

    a {
      color: $oiblue !important;
    }
  }

  &__card {
    display: flex;
    justify-content: space-between;
    padding: 45px 0;
    border-bottom: 1px solid #2e3247;

    @media screen and (min-width: 800px) {
      &:nth-child(2n) {
        justify-content: flex-end;
        flex-direction: row-reverse;
        gap: 80px;
      }
    }
  }

  &__image {
    aspect-ratio: 16 / 9;
    border-radius: 25px;
    width: 50%;
  }

  &__buttons {
    .button {
      width: 200px;
      height: 50px;
      font-size: 18px;

      &:nth-child(1) {
        margin-right: 25px;
      }
    }
  }

  &__info {
    margin-top: 20px;
  }

  &__title {
    max-width: 600px;
    margin-bottom: 30px;
    font-size: 56px;
    line-height: 64px;
    word-break: normal;
    font-weight: 600;
  }

  &__subTitle {
    font-size: 36px;
    line-height: 41px;
    margin-bottom: 40px;
  }

  &__description {
    max-width: 400px;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 37px;
    color: $grey;
    width: 65%;
  }
  @media screen and (min-width: 1200px) {
    &__more {
      display: none;
    }
  }
  @media screen and (max-width: 1200px) {
    &__title {
      font-size: 36px;
      line-height: 41px;
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      margin-bottom: 15px;
    }
    &__subTitle {
      font-size: 24px;
      margin-bottom: 26px;
      line-height: 27px;
    }

    &__more {
      display: block;
    }

    &__buttons {
      display: none;
      .button {
        width: unset;
        font-size: 14px;
        &:nth-child(1) {
          margin-right: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    margin: 0 10px;

    &__card {
      flex-direction: column-reverse;
    }

    &__info {
      margin-top: 30px;
    }

    &__title {
      font-size: 36px;
      line-height: 41px;
    }

    &__subTitle {
      font-size: 24px;
      line-height: 27px;
    }

    &:nth-child(2n) {
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
    }

    &__buttons {
      display: none;
    }

    &__image {
      width: 100%;
    }
  }
}
