﻿$oired: #ff009e;
$oigreen: #00dcaf;
$oiblue: #00b7ff;
$oipurple: #8247E5;
$oiyellow: #ff9900;

$oilogopink: #09dfff;
$oilogoblue: #b603e9;

$white: #ffffff;
$off-white-1: #e0e0e0;
$black: #000000;
$grey: #aaaaaa;
$lightgrey: #cccccc;
$darkgrey: #666666;
$lightgrey: #b4b4b4;
$red: #ff0000;
$green: #00ffee;
$lightblue: #2e3146;
$lightestblue: #00b7ff;
$greyish: #606060;

$ethereum: #6B82EF;
$avalanche: #E84142;
$polygon: #8247E5;
$binance: #F3BA2F;

$link-color: #00b7ff;
$link-active-color: hsl(211, 70%, 44%);
$link-hover-color: hsl(207, 70%, 75%);

$bg-color-1: #191b27;
$bg-color-2: #2e3045;
$bg-color-2-accent-1: adjust-color($bg-color-2, $lightness: -5%);
$bg-color-3: adjust-color($bg-color-1, $lightness: -20%);
$bg-color-4: #0c0e19;
$bg-color-5: #535880;

$border-color-1: hsl(230, 5%, 50%);
$border-color-2: hsl(203, 80%, 56%);
$border-color-3: hsl(281, 90%, 48%);
/*$border-color-4: hsl(230, 5%, 17%);*/
$border-color-4: hsl(230, 21%, 23%);
$border-color-5: hsl(230, 5%, 21%);
$border-color-6: #535880;
$border-color-7: #00dcaf;
$hr-color: #434659;

$footer-height: 48px;
$progress-bar-height: 10px;

$margin-size-1: 0.4rem;
$margin-size-2: 0.8rem;
$margin-size-3: 1.4rem;
$margin-size-4: 2.4rem;
$margin-size-5: 4rem;

$media-break-sm: 576px;
$media-break-md: 798px;
$media-break-lg: 992px;
$media-break-xl: 1200px;

$text-size-smallest: 0.6rem;
$text-size-smaller: 0.7rem;
$text-size-small: 0.8rem;
$text-size-medium: 1rem;
$text-size-bigish: 1.2rem;
$text-size-big: 1.6rem;
$text-size-bigger: 2.4rem;
$text-size-biggest: 4rem;

$sidebarWidth: 70px;
$sidebarWidthWide: 280px;
$appheaderHeight: 72px;

$fw-regular: 400;
$fw-bold: 600;

$color-error: #b10000;
