﻿//@import "~video-react/styles/scss/video-react"; Needs an update for new division operator
@import '~overlayscrollbars/css/OverlayScrollbars.css';

@import '~@draft-js-plugins/emoji/lib/plugin.css';
@import '~@draft-js-plugins/hashtag/lib/plugin.css';
@import '~@draft-js-plugins/mention/lib/plugin.css';

#layout-app {
  background-color: $bg-color-1;
  margin-top: $appheaderHeight;

  > #app-header {
    background-color: $bg-color-1;
    border-bottom: 1px solid $border-color-4;
    position: fixed;
    top: 0;
    z-index: 99;

    .right {
      .bell-notification {
        position: relative;

        .bell {
          width: 40px;
          height: 40px;
          background-color: $bg-color-2;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }

        > .notification-count {
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: $oired;
          color: $white;
          position: absolute;
          top: 0;
          right: 0;
          font-size: 11px;
          line-height: 15px;
          text-align: center;
          vertical-align: middle;
          margin-top: 0px;
          margin-right: 0px;
        }
      }
    }

    @media screen and (max-width: $media-break-sm) {
      .balance {
        //display: none;
      }
      .right {
        .search-icon {
          margin-right: $margin-size-1;
        }
        .bell-notification {
          margin-right: $margin-size-1;
        }
      }
    }
  }

  > #app-footer {
    background-color: $bg-color-1;
  }

  &.without-footer {
    > #app-content {
      margin-bottom: 0;
    }
  }
}

#layout-app .profile-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  > .profile-info {
    display: flex;
    flex-grow: 1;

    > .profile-avatar-section {
      .profile-actions {
        display: none;
      }
    }

    .profile-avatar {
      flex-shrink: 0;
    }

    > .profile-data {
      width: 65%;
      .placeholder-component {
        min-width: 140px;
        width: 100%;
        margin: 0;
      }

      .actor-follow-state {
        font-size: $text-size-small;
        color: #9798a3;
        padding: 4px 8px;
        border-radius: 10px;
      }

      .number {
        color: white;
        display: flex;
      }

      .text {
        color: #91939e;
        > .placeholder-component {
          min-width: 100px;
          margin: 0;
        }
      }

      > .name {
        font-weight: 600;
        display: flex;
        align-items: center;
        width: 100%;

        > .profile-name {
          height: 26px;
          white-space: nowrap;
          margin-right: $margin-size-2;
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @media screen and (max-width: 992px) {
            max-width: 150px;
          }

          > .placeholder-component {
            width: 100%;
            margin: 0;
          }
        }

        > .antic-icon {
          margin-right: 0.8rem;
          margin-left: -0.3rem;
        }        
      }

      > .slug {
        margin-top: 4.06px;
        margin-bottom: 12px;
      }
    }
  }

  .profile-actions {
    flex-shrink: 0;

    > .edit-buttons {
      display: flex;
      align-items: center;

      > .icons {
        width: 34px;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $white;
        border-radius: 50%;
        transition: 0.5s;

        svg {
          margin-right: 2px;
          path {
            transform: scale(1.1);
          }
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: #434659;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: $media-break-md - 1) {
    // .profile-avatar {
    //   width: 80px !important;
    //   height: 80px !important;
    // }
  }

  @media screen and (max-width: $media-break-sm - 1) {
    > .profile-info {
      margin-left: $margin-size-2;
      margin-right: $margin-size-2;
      flex-direction: column;
      width: 100%;

      > .profile-avatar-section {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-size-3;
        > .placeholder-component {
          margin: 0;
          margin-bottom: $margin-size-2;
          div {
            width: 100px !important;
            height: 100px !important;
            margin: 0;
          }
        }

        .profile-actions {
          display: block;
        }

        > .profile-avatar {
          margin-bottom: $margin-size-2;
          height: 100px !important;
          width: 100px !important;

          .placeholder {
            height: 100px !important;
            width: 100px !important;
            img {
              height: 50px !important;
              width: 50px !important;
            }
          }

          img {
            height: 100px !important;
            width: 100px !important;
          }
        }
      }

      > .profile-data {
        margin-right: 0;
        margin-left: 0;
        width: 100%;

        .number {
        }

        .text {
        }

        > .name {
          > .profile-name {
          }
        }
      }
    }

    > .profile-actions {
      display: none;
      order: 3;
      width: 100%;
      flex-shrink: 0;
      margin-top: $margin-size-3;

      > .edit-buttons {
        display: none;
      }
    }
  }
}

#layout-app #page-upload {
  > .step-container {
    > .row-1 {
      .col-1 {
        width: 100%;
        order: 1;
      }

      .col-2 {
        width: 100%;
        order: 2;
        padding-left: $margin-size-1;
        padding-right: $margin-size-1;
      }

      @media screen and (min-width: $media-break-md) {
        .col-1 {
          width: 50%;
          order: 1;
        }

        .col-2 {
          width: 40%;
          order: 2;
        }
      }
    }

    &.upload-step-1 {
      > .row-1 {
        .col-1 {
          .icon-hold {
            width: 138px;
            height: 86px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon-wrap {
              height: 70px;
              width: 70px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
              background-origin: border-box;
              background-clip: content-box, border-box;
              position: relative;

              .icon-white-area {
                background-color: #191b27;
                border-radius: 50%;
                width: 50px;
                height: 50px;
                display: flex;
                align-content: center;
                justify-content: center;
              }

              i {
                font-size: 24px;
                margin: auto;
                z-index: 10;
              }
            }
          }
        }
      }

      .info {
        margin-top: 200px;
      }

      .row-2 {
        .input {
          .input-validation-error {
            display: none;
          }
        }
      }
    }

    &.upload-step-2 {
      .info {
        margin-top: 100px;
      }

      .upload-wrap {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        .col-1 {
          width: 30%;
          min-width: 256px;

          .list-item {
            width: 256px;
            margin-bottom: $margin-size-3;
            display: flex;
            flex-flow: row nowrap;

            .left {
              i {
                font-size: 18px;
                margin-right: $margin-size-1;
              }
            }

            .right {
            }
          }
        }

        .col-2 {
          width: 30%;

          .upload-box-wrap {
            width: 312px;
            height: 312px;
          }
        }

        .col-3 {
          width: 30%;
          display: flex;
          flex-flow: column nowrap;
          align-items: center;

          .button {
            width: 160px;
            margin: $margin-size-1 0;
          }

          .upload-option {
            background-color: white;
            border-radius: 24px;
            height: 42px;
            width: 120px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            img {
              object-fit: fill;
            }
          }

          .text {
            font-weight: bold;
          }
        }

        @media screen and (max-width: $media-break-lg - 1) {
          .col-1 {
            order: 2;
          }

          .col-2 {
            order: 1;
            width: 100%;
          }

          .col-3 {
            order: 3;
            width: 40%;
          }
        }

        @media screen and (max-width: $media-break-sm) {
          .col-1 {
            width: 100%;
            order: 3;

            .list-item {
              margin-left: auto;
              margin-right: auto;
            }
          }

          .col-3 {
            width: 100%;
            order: 2;
            margin-bottom: $margin-size-3;
          }
        }
      }
    }
  }
}

#layout-app #page-collection {
  .header {
    min-height: 210px;

    background-color: $bg-color-1;

    @media screen and (max-width: $media-break-sm) {
      padding: 0px;
    }
  }

  .discover-card-group-container {
    > .discover-card-group {
      > .all-group-items {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (min-width: 730px) {
        > .all-group-items {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      @media screen and (min-width: 990px) {
        > .all-group-items {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }

  > .bg-color-1 {
    > .content-container {
      > .block-text {
        width: 100%;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          font-size: 50px;
          margin-bottom: 32px;
          color: #474851;
        }

        .text {
          font-size: 14px;
          color: #474851;
          margin-bottom: 50px;
        }

        .button {
          width: 126px;
          height: 35px;
          border-radius: 18px;
          border: 1px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      > .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .selectable-item {
          display: flex;

          a {
            color: #8c8d93;
          }
          svg {
            fill: #8c8d93;
            color: #8c8d93;
          }

          > .collectibles {
            cursor: pointer;
          }

          > .tab {
            display: flex;
            align-items: center;
            padding: $margin-size-1 0;

            i {
              font-size: 16px;
            }

            &.with-border {
              border-left: 1px solid $lightblue;
              margin-left: 0;
            }
          }
          svg {
            path {
              fill: #8c8d93 !important;
            }
          }

          > .active-tabs {
            color: $white;
            cursor: pointer;
            svg {
              path {
                fill: white !important;
              }
            }
          }
        }

        .collection_svg__collection-svg {
          width: 16px;
          height: 16px;
          margin-right: $margin-size-1;
          fill: $grey;

          path {
            fill: $grey;
          }
        }

        > .header-info {
          display: flex;
          align-items: center;

          > .select-visibilty {
            width: 150px;
          }
        }
        @media screen and (max-width: $media-break-sm) {
          display: flex;
          width: 100%;
          > .selectable-item {
            flex-grow: 1;
            > .tab {
              margin: 0px 4px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 34%;

              svg {
                width: 22px;
                height: 22px;
              }

              i {
                font-size: 22px;
              }

              .collection_svg__collection-svg {
                width: 22px;
                height: 22px;
              }
            }

            > .secret {
              padding: 0;
              width: 50%;
            }
          }
          .option {
            display: none;
          }
        }
      }

      .empty-text {
        width: 100%;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          font-size: 50px;
          margin-bottom: 32px;
          color: #474851;
        }

        > .collection_svg__collection-svg {
          margin-bottom: 32px;
          height: 50px;
          > path {
            height: 50px;
            fill: #474851 !important;
          }
        }

        .text {
          font-size: 14px;
          color: #474851;
          margin-bottom: 50px;
        }

        .button {
          width: 126px;
          height: 35px;
          border-radius: 18px;
          border: 1px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      > .no-collectible {
        width: 100%;
        text-align: center;
        margin-top: $margin-size-5;
        color: $grey;
      }
    }
  }

  .empty-placeholder {
    width: 100%;
    border: 1px solid #2e3146;
    border-radius: 15px;

    .height-decider {
      margin-bottom: calc(100% + 80px);
    }
  }
}
#layout-app #page-profile {
  .header {
    min-height: 210px;

    background-color: $bg-color-1;

    @media screen and (max-width: $media-break-sm) {
      padding: $margin-size-2 0px;
    }
  }

  .discover-card-group-container {
    > .discover-card-group {
      > .all-group-items {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (min-width: 730px) {
        > .all-group-items {
          grid-template-columns: repeat(3, 1fr);
        }
      }
      @media screen and (min-width: 990px) {
        > .all-group-items {
          grid-template-columns: repeat(4, 1fr);
        }
      }
    }
  }

  > .bg-color-1 {
    > .content-container {
      > .block-text {
        width: 100%;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          font-size: 50px;
          margin-bottom: 32px;
          color: #474851;
        }

        .text {
          font-size: 14px;
          color: #474851;
          margin-bottom: 50px;
        }

        .button {
          width: 126px;
          height: 35px;
          border-radius: 18px;
          border: 1px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      > .top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .selectable-item {
          display: flex;

          a {
            color: #8c8d93;
          }
          svg {
            fill: #8c8d93;
            color: #8c8d93;
          }

          > .collectibles {
            cursor: pointer;
          }

          > .tab {
            display: flex;
            align-items: center;
            padding: $margin-size-1 0;

            i {
              font-size: 16px;
            }

            &.with-border {
              border-left: 1px solid $lightblue;
              margin-left: 0;
            }
          }
          svg {
            path {
              fill: #8c8d93 !important;
            }
          }

          > .active-tabs {
            color: $white;
            cursor: pointer;
            svg {
              path {
                fill: white !important;
              }
            }
          }
        }

        .collection_svg__collection-svg {
          width: 16px;
          height: 16px;
          margin-right: $margin-size-1;
          fill: $grey;

          path {
            fill: $grey;
          }
        }

        > .header-info {
          display: flex;
          align-items: center;

          > .select-visibilty {
            width: 150px;
          }
        }
        @media screen and (max-width: 760px) {
          display: flex;
          width: 100%;
          > .selectable-item {
            flex-grow: 1;
            > .tab {
              margin: 0px 4px;
              font-size: 18px;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 34%;

              svg {
                width: 22px;
                height: 22px;
              }

              i {
                font-size: 22px;
              }

              .collection_svg__collection-svg {
                width: 22px;
                height: 22px;
              }
            }

            > .secret {
              padding: 0;
              width: 50%;
            }
          }
          .option {
            display: none;
          }
        }
      }

      .empty-text {
        width: 100%;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          font-size: 50px;
          margin-bottom: 32px;
          color: #474851;
        }

        > .collection_svg__collection-svg {
          margin-bottom: 32px;
          height: 50px;
          > path {
            height: 50px;
            fill: #474851 !important;
          }
        }

        .text {
          font-size: 14px;
          color: #474851;
          margin-bottom: 50px;
        }

        .button {
          width: 126px;
          height: 35px;
          border-radius: 18px;
          border: 1px solid white;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      > .no-collectible {
        width: 100%;
        text-align: center;
        margin-top: $margin-size-5;
        color: $grey;
      }
    }
  }

  .empty-placeholder {
    width: 100%;
    border: 1px solid #2e3146;
    border-radius: 15px;

    .height-decider {
      margin-bottom: calc(100% + 80px);
    }
  }
}

#layout-app #page-searche {
  width: 100%;

  .list {
    position: fixed;
    top: $appheaderHeight;
    z-index: 1000;
    background-color: $bg-color-1;
    padding: 0 $margin-size-3;
    height: 60px;
    width: 100%;
    overflow: hidden;
    border-bottom: 1px solid #2e3247;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.has-account {
      width: calc(100% - 70px);
    }

    > .category-list {
      white-space: nowrap;
    }

    > .filter {
      cursor: pointer;
    }

    .filter-button {
      display: flex;
      align-items: center;
      font-size: 16px;
      cursor: pointer;
  
      &.active {
        color: $oiblue;
      }

      @media screen and (max-width: $media-break-sm) {
        & {
          display: none;
        }
      }
    }
  
  }

  @media screen and (max-width: $media-break-md) {
    .list {
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      width: 100%;

      &.has-account {
        width: 100%;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .discover-card-group {
    // padding: 0px $margin-size-2;
  }

  a {
    color: #8c8d93;
    flex-shrink: 0;
    font-size: 16px;
  }

  .active {
    color: $lightestblue;
  }

  > .carousel-container {
    > .fullpage-background {
      display: block;
    }
  }

  .load-button {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  > .top-bar {
    display: flex;
    justify-content: stretch;

    > .responsive-menu-button {
      display: none;
    }

    > .selectable-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .sort-button {
        cursor: pointer;
        height: 50px;
        display: flex;
        align-items: center;
      }

      > .select-input {
        display: flex;
        align-items: center;

        > .home-button {
          cursor: pointer;
          border-right: 1px solid #2e3045;
          height: 38px;
          padding-right: 18px;
          padding-left: $margin-size-3;
          display: flex;
          align-items: center;
        }

        .input-money {
          > .input-validation-error {
            display: none;
          }
        }

        > .clear-button {
          cursor: pointer;
          color: $lightestblue;
        }

        > .input-select {
          width: 160px;
          margin-right: $margin-size-1;
        }

        > .selectable {
          border-right: 1px solid $border-color-4;
        }
      }
    }

    > .settings {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      > .input-check {
        display: flex;
        align-items: center;
      }
    }
  }

  > .template {
    > .template-header {
      .template-title {
        font-size: 2em;
        font-weight: bold;
      }

      .template-description {
        color: $grey;
      }
    }
  }

  > .result-info {
    padding: $margin-size-2;
    margin-top: 72px;

    &.filter-open {
      margin-top: 15px;
    }

    @media screen and (max-width: $media-break-sm) {
      & {
        margin-top: 15px;
      }
    }

    > .result {
      font-size: $text-size-bigish;
      font-weight: 500;
      .text {
        color: #8c8d93;
      }

      > .hashtag {
        color: $lightestblue;
        display: inline-block;
      }
    }

    > .about {
      color: $grey;
      margin-top: $margin-size-1;

      > .follow {
        display: inline-block;
        color: $link-color;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: $media-break-md) {
    > .top-bar {
      flex-flow: column;
      position: relative;
      height: 60px;

      > .responsive-menu-button {
        position: absolute;
        display: inline-block;
        top: $margin-size-2;
        cursor: pointer;
        z-index: 101;
      }

      .selectable-item {
        display: flex;
        align-items: center;
        order: 2;
        width: 100%;
        z-index: 100;
        background-color: $bg-color-1;

        .select-input {
          margin-top: 24px;
          display: none;
          align-items: center;
          width: 100%;
          text-align: center;

          .home-button {
            display: none;
          }

          .input-select {
            display: block;
            width: 100%;
            margin-right: $margin-size-1;
          }

          &.active-select-input {
            display: block;
          }
        }

        > .sort-button {
          position: absolute;
          right: $margin-size-2;
          top: $margin-size-2;
        }
      }

      > .settings {
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        order: 1;
      }
    }

    > hr {
      display: none;
    }
  }
}

#layout-app #page-profile-search {
  width: 100%;

  .list {
    padding-left: $margin-size-3;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    overflow: hidden;

    > .filter {
      cursor: pointer;
    }
  }

  @media screen and (max-width: $media-break-md) {
    .list {
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .profile-info {
    display: flex;
    align-items: center;
    margin-right: $margin-size-2;
  }

  a {
    color: white;
    flex-shrink: 0;
  }

  .active {
    color: $lightestblue;
  }

  > .carousel-container {
    > .fullpage-background {
      display: block;
    }
  }

  .load-button {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  > .top-bar {
    display: flex;
    justify-content: stretch;

    > .responsive-menu-button {
      display: none;
    }

    > .selectable-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .sort-button {
        cursor: pointer;
        height: 50px;
        display: flex;
        align-items: center;
      }

      > .select-input {
        display: flex;
        align-items: center;

        > .home-button {
          cursor: pointer;
          border-right: 1px solid #2e3045;
          height: 38px;
          padding-right: 18px;
          padding-left: $margin-size-3;
          display: flex;
          align-items: center;
        }

        .input-money {
          > .input-validation-error {
            display: none;
          }
        }

        > .clear-button {
          cursor: pointer;
          color: $lightestblue;
        }

        > .input-select {
          width: 160px;
          margin-right: $margin-size-1;
        }

        > .selectable {
          border-right: 1px solid $border-color-4;
        }
      }
    }

    > .settings {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      > .input-check {
        display: flex;
        align-items: center;
      }
    }
  }

  > .template {
    > .template-header {
      .template-title {
        font-size: 2em;
        font-weight: bold;
      }

      .template-description {
        color: $grey;
      }
    }
  }

  > .result-info {
    padding: $margin-size-2;
    margin-top: $margin-size-2;

    > .result {
      font-size: $text-size-bigish;

      > .hashtag {
        color: $lightestblue;
        display: inline-block;
      }
    }

    > .about {
      color: $grey;
      margin-top: $margin-size-1;

      > .follow {
        display: inline-block;
        color: $link-color;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: $media-break-md) {
    > .top-bar {
      flex-flow: column;
      position: relative;
      height: 60px;

      > .responsive-menu-button {
        position: absolute;
        display: inline-block;
        top: $margin-size-2;
        cursor: pointer;
        z-index: 101;
      }

      .selectable-item {
        display: flex;
        align-items: center;
        order: 2;
        width: 100%;
        z-index: 100;
        background-color: $bg-color-1;

        .select-input {
          margin-top: 24px;
          display: none;
          align-items: center;
          width: 100%;
          text-align: center;

          .home-button {
            display: none;
          }

          .input-select {
            display: block;
            width: 100%;
            margin-right: $margin-size-1;
          }

          &.active-select-input {
            display: block;
          }
        }

        > .sort-button {
          position: absolute;
          right: $margin-size-2;
          top: $margin-size-2;
        }
      }

      > .settings {
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        order: 1;
      }
    }

    > hr {
      display: none;
    }
  }
}

#layout-app #page-discovere {
  width: 100%;

  .list {
    position: fixed;
    z-index: 1000;
    background-color: $bg-color-1;
    padding-left: $margin-size-3;
    display: flex;
    top: $appheaderHeight;
    align-items: center;
    border-bottom: 1px solid #2e3247;
    height: 60px;
    width: 100%;
    overflow: hidden;
  }

  .tab-container {
    padding-top: 2px;
    margin-top: $appheaderHeight;
  }

  a {
    font-size: 16px;
    color: white;
    flex-shrink: 0;
  }

  .active {
    color: $lightestblue;

    svg {
      path {
        fill: $lightestblue;
      }
    }
  }

  > .carousel-container {
    > .fullpage-background {
      display: block;
    }
  }

  .load-button {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  .top-bar {
    display: flex;
    justify-content: stretch;
    border-top: 1px solid $hr-color;
    border-bottom: 1px solid $hr-color;
    height: 45px;

    > .responsive-menu-button {
      display: none;
    }

    > .selectable-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .sort-button {
        cursor: pointer;
        padding: $margin-size-1 $margin-size-2;
        background-color: #20212e;
        border-radius: 5px;
      }

      > .select-input {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        > .home-button {
          cursor: pointer;
          height: 38px;
          padding-right: 18px;
          padding-left: $margin-size-3;
          display: flex;
          align-items: center;
        }

        .input-money {
          > .input-validation-error {
            display: none;
          }
        }

        > .clear-button {
          cursor: pointer;
          color: $lightestblue;
        }

        > .input-select {
          width: 160px;
          margin-right: $margin-size-1;
        }

        > .selectable {
          border-right: 1px solid $border-color-4;
        }
      }
    }

    > .settings {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      > .input-check {
        display: flex;
        align-items: center;
      }
    }
  }

  > .template {
    > .template-header {
      .template-title {
        font-size: 2em;
        font-weight: bold;
      }

      .template-description {
        color: $grey;
      }
    }
  }

  @media screen and (max-width: $media-break-md) {
    .list {
      overflow: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    > .top-bar {
      flex-flow: column;
      position: relative;
      height: 60px;

      > .responsive-menu-button {
        position: absolute;
        display: inline-block;
        top: $margin-size-2;
        cursor: pointer;
        z-index: 101;
      }

      .selectable-item {
        display: flex;
        align-items: center;
        order: 2;
        width: 100%;
        z-index: 100;
        background-color: $bg-color-1;

        .select-input {
          margin-top: 24px;
          display: none;
          align-items: center;
          width: 100%;
          text-align: center;

          .home-button {
            display: none;
          }

          .input-select {
            display: block;
            width: 100%;
            margin-right: $margin-size-1;
          }

          &.active-select-input {
            display: block;
          }
        }

        > .sort-button {
          position: absolute;
          right: $margin-size-2;
          top: $margin-size-2;
        }
      }

      > .settings {
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        order: 1;
      }
    }

    > hr {
      display: none;
    }
  }
}

#layout-app #page-mylibrarye {
  width: 100%;

  .list {
    padding-left: $margin-size-3;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    overflow: hidden;
  }

  a {
    color: white;
    flex-shrink: 0;
  }

  .active {
    color: $lightestblue;
  }

  > .carousel-container {
    > .fullpage-background {
      display: block;
    }
  }

  .load-button {
    width: 100%;
    text-align: center;
    cursor: pointer;
  }

  > .top-bar {
    display: flex;
    justify-content: stretch;
    height: 50px;

    > .responsive-menu-button {
      display: none;
    }

    > .selectable-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      background-color: $bg-color-1;

      .sort-button {
        cursor: pointer;
      }

      > .select-input {
        display: flex;
        align-items: center;

        > .home-button {
          cursor: pointer;
          border-right: 1px solid #2e3045;
          height: 38px;
          padding-right: 18px;
          padding-left: $margin-size-3;
          display: flex;
          align-items: center;
        }

        .input-money {
          > .input-validation-error {
            display: none;
          }
        }

        > .clear-button {
          cursor: pointer;
          color: $lightestblue;
        }

        > .input-select {
          width: 160px;
          margin-right: $margin-size-1;
        }

        > .selectable {
          border-right: 1px solid $border-color-4;
        }
      }
    }

    > .settings {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      > .input-check {
        display: flex;
        align-items: center;
      }
    }
  }

  > .template {
    > .template-header {
      .template-title {
        font-size: 2em;
        font-weight: bold;
      }

      .template-description {
        color: $grey;
      }
    }
  }

  > .card-group {
    padding: $margin-size-1 $margin-size-2;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;

    .collectible-card-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      .account-card {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: $media-break-md) {
    > .top-bar {
      flex-flow: column;
      position: relative;
      height: 60px;

      > .responsive-menu-button {
        position: absolute;
        display: inline-block;
        top: $margin-size-2;
        cursor: pointer;
        z-index: 101;
      }

      .selectable-item {
        display: flex;
        align-items: center;
        order: 2;
        width: 100%;
        z-index: 100;

        .select-input {
          margin-top: 24px;
          display: none;
          align-items: center;
          width: 100%;
          text-align: center;

          .home-button {
            display: none;
          }

          .input-select {
            display: block;
            width: 100%;
            margin-right: $margin-size-1;
          }

          &.active-select-input {
            display: block;
          }
        }

        > .sort-button {
          position: absolute;
          right: $margin-size-2;
          top: $margin-size-2;
        }
      }

      > .settings {
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        order: 1;
      }
    }

    > hr {
      display: none;
    }
  }
}

#layout-app #page-my-library {
  width: 99%;

  .list {
    padding: $margin-size-1 $margin-size-2;
  }

  a {
    color: white;
  }

  .active {
    color: $lightestblue;
  }

  > .top-bar {
    display: flex;
    justify-content: stretch;

    > .responsive-menu-button {
      display: none;
    }

    > .selectable-item {
      display: flex;
      align-items: center;

      > .select-input {
        display: flex;
        align-items: center;

        > .input-select {
          width: 120px;
          margin-right: $margin-size-1;
        }

        > .selectable {
          border-right: 1px solid $border-color-4;
        }
      }
    }

    > .settings {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
      align-items: center;

      > .input-check {
        display: flex;
        align-items: center;
      }
    }
  }

  @media screen and (max-width: $media-break-md) {
    > .top-bar {
      flex-flow: column;
      position: relative;

      > .responsive-menu-button {
        position: absolute;
        display: inline-block;
        top: 15px;
      }

      > .selectable-item {
        display: flex;
        align-items: center;
        order: 2;
        width: 100%;

        > .select-input {
          display: none;
          align-items: center;
          width: 100%;
          text-align: center;

          > .input-select {
            display: block;
            width: 100%;
            margin-right: $margin-size-1;
          }

          &.active-select-input {
            display: block;
          }
        }
      }

      > .settings {
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        order: 1;
      }
    }
  }
}

#layout-app #page-collectible-edit {
  > .row-1 {
    .col-1 {
      width: 100%;
      order: 1;
    }

    .col-2 {
      width: 100%;
      order: 2;
      padding-left: $margin-size-1;
      padding-right: $margin-size-1;
    }

    @media screen and (min-width: $media-break-md) {
      .col-1 {
        width: 50%;
        order: 1;
      }

      .col-2 {
        width: 40%;
        order: 2;
      }
    }
  }

  .dropdown-button-container {
    display: flex;
    flex-direction: row-reverse;

    .dropdown-button {
      position: relative;

      .icon-container {
        cursor: pointer;
        position: absolute;
        top: 0px;
        right: 0px;
        border-left: 1px solid black;
        padding: $margin-size-2;
        height: 100%;
        display: flex;
        align-items: center;
        text-align: right;
      }

      .sub-button {
        position: absolute;
        top: 45px;
        right: 0;
      }
    }
  }

  > .row-2 {
    .tab-panel {
      .tab-content {
        i {
          margin-right: $margin-size-1;
          color: #2e3146;
        }

        .switch-row {
          margin: $margin-size-3 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .switch-col {
            display: flex;
            align-items: center;

            i {
              margin-right: $margin-size-1;
              color: #2e3146;
            }
          }
        }

        .grey-text {
          font-size: $text-size-small;
          color: #5e5f67;
        }

        .entertainment-advance {
          .file-progress-container {
            width: 50px;
            height: 6px;
            background-color: white;
            border-radius: 6px;
            overflow: hidden;

            .file-progress {
              background-color: #00b7ff;
              height: 6px;
            }
          }
        }

        .basic {
          .img-category-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        > div {
          > .col-1 {
            width: 50%;
            margin-top: $margin-size-3;
            padding-left: $margin-size-1;
            padding-right: $margin-size-3;
          }

          > .col-2 {
            width: 50%;
            margin-top: $margin-size-3;
            padding-left: $margin-size-3;
            padding-right: $margin-size-1;
          }

          @media screen and (max-width: $media-break-md - 1) {
            .col-1,
            .col-2 {
              width: 100%;
              padding-left: $margin-size-1;
              padding-right: $margin-size-1;
            }
          }
        }
      }
    }
  }
}

#layout-app #page-collectible {
  $column-2-width: 320px;
  $column-3-width: 480px;
  $row-1-height: 600px;

  > .area-container {
    width: 100%;
    padding: 0 $margin-size-4;
    display: grid;
    grid-template-columns: auto minmax(400px, 480px);
    grid-template-rows: minmax(0px, auto) minmax(0px, auto) minmax(0px, auto) minmax(0px, auto);
    grid-template-areas: 'view similar' 'infobar similar' 'info similar' 'details similar' 'comment similar';
    grid-column-gap: $margin-size-2;
    grid-row-gap: $margin-size-2;

    > .area-1 {
      grid-area: view;

      > .preview {
        position: relative;
        background-color: $bg-color-3;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: 0;
        padding-top: 56.25%;
        overflow: hidden;
        min-width: 680px;

        > .collectible-preview-carousel {
          display: flex;
          justify-content: center;

          > .image {
            display: flex;
            justify-content: center;

            > img {
              position: absolute;
              top: 0;
              width: auto;
              height: 100%;
            }
          }
        }

        .vertical-ellipsis {
          position: absolute;
          top: $margin-size-3;
          right: $margin-size-3;
          cursor: pointer;
        }

        .audio-player {
          $coverpad: 75px;
          $coversize: 500px - 2 * $coverpad;

          width: 100%;
          height: 100%;

          > .background-cover {
            position: absolute;
            top: -10px;
            left: -10px;
            right: -10px;
            bottom: -10px;
            background-repeat: no-repeat;
            background-size: cover;
            filter: blur(10px) brightness(0.5);
          }

          > .left-container {
            position: absolute;
            left: 0;
            top: $coverpad;
            right: $coverpad + $coversize;
            height: $coversize;
            display: flex;
            align-items: center;
            justify-content: center;

            > .content-wrap {
              text-align: center;

              .play-button {
                cursor: pointer;
                width: 60px;
                height: 60px;
                border: 2px solid white;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                margin: 0 auto;
              }
            }
          }

          > .conver-container {
            position: absolute;
            top: $coverpad;
            right: $coverpad;

            > img {
              width: $coversize;
              height: $coversize;
            }
          }

          > .waveform-container {
            position: absolute;
            left: 10px;
            bottom: 10px;
            right: 10px;
            height: 100px;
          }
        }
      }
    }

    > .area-2 {
      grid-area: similar;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      min-width: 400px;
      max-width: 480px;

      > .title {
        padding: $margin-size-3 $margin-size-2;
        border-bottom: 1px solid $lightblue;
        font-size: 18px;
      }
    }

    > .area-3 {
      grid-area: infobar;
      padding-bottom: $margin-size-2;
      border-bottom: 1px solid $border-color-4;

      .share-button-active {
        color: #00dcaf;
      }
    }

    > .area-4 {
      grid-area: info;
    }

    > .area-5 {
      grid-area: details;
      padding-bottom: $margin-size-2;
      border-bottom: 1px solid $border-color-4;

      .show-more {
        margin-top: $margin-size-2;
        color: $grey;
        padding-left: $margin-size-1;
        cursor: pointer;
        font-weight: 600;
        font-size: $text-size-small;
      }

      .collectible-details-container {
        max-width: 70%;
        margin-left: $margin-size-1;

        &.short {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          -webkit-box-orient: vertical;
        }

        .collectible-description {
          //margin-left: calc(36px + #{$margin-size-2});
        }

        .collectible-details-table {
          display: flex;
          flex-flow: row nowrap;

          .col-1,
          .col-2 {
            width: 50%;

            > .row {
              display: flex;
              flex-flow: row nowrap;
              justify-content: space-between;
              border-bottom: 1px solid $border-color-4;
              padding: $margin-size-2 0;
              max-height: 40px;
            }
          }

          .col-1 {
            margin-right: $margin-size-3;
          }

          .button {
            padding: 4px 0.8rem;
            margin-left: $margin-size-1;
          }
        }
      }
    }

    > .area-6 {
      grid-area: comment;

      .comment-box {
        display: flex;
        flex-flow: row nowrap;
        word-break: break-all;

        .comment-input-container {
          flex-grow: 1;

          .input {
            width: 100%;
          }
        }
      }

      .comment-container {
        min-height: 240px;

        .comment-display {
          margin-bottom: $margin-size-3;

          .row-1 {
            display: flex;
            flex-flow: row nowrap;

            > .col-1 {
            }

            > .col-2 {
              flex-grow: 1;

              .comment-header {
                font-size: $text-size-small;
              }

              .comment-content {
                margin-bottom: $margin-size-1;
                margin-top: $margin-size-2;
              }

              .comment-details {
                font-size: $text-size-small;
                .comment {
                  @media (hover: hover) and (pointer: fine) {
                    &:hover {
                      color: $oiblue;
                      cursor: pointer;
                    }
                  }
                }
                .icon {
                  font-size: 12px;
                  margin-right: 4px;
                }

                .button {
                  padding-left: $margin-size-1;
                  padding-right: $margin-size-1;
                }
              }
            }
          }

          .row-2 {
          }
        }
      }
    }
  }

  @media screen and (max-width: $media-break-xl - 1) {
    > .area-container {
      grid-template-columns: auto;
      grid-template-rows: auto auto auto auto auto 1fr;
      grid-template-areas: 'view' 'infobar' 'info' 'details' 'comment' 'similar';

      > .area-2 {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  @media screen and (max-width: $media-break-lg - 1) {
    > .area-container {
      grid-template-columns: auto;
      grid-template-rows: auto auto auto auto auto 1fr;
      grid-template-areas: 'view' 'infobar' 'info' 'details' 'comment' 'similar';

      > .area-1 {
        > .preview {
          min-width: 0px;
        }
      }
    }
  }
}

#layout-app #page-settings {
  display: flex;
  align-items: center;
  justify-content: center;

  > .settings-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    margin-top: $margin-size-4;

    .side-icon {
      border: 1px solid $lightestblue;
      border-radius: 50%;
      margin: 0px;
      color: $lightestblue;
      cursor: pointer;
      font-size: 12px;
      width: 39px;
      height: 39px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    > .header {
      width: 100%;

      > .title {
        font-size: $text-size-big;
      }

      > .subtext {
        font-size: $text-size-small;
        margin-top: $margin-size-1;
        margin-bottom: $margin-size-1;
        color: $lightgrey;
      }
    }

    .title {
      font-size: $text-size-bigish;
    }

    .subtext {
      font-size: $text-size-small;
      margin-top: $margin-size-1;
      margin-bottom: $margin-size-1;
      color: $lightgrey;
    }

    .danger-text {
      color: red;
      font-size: $text-size-small;
    }

    > .account-info {
      margin-top: $margin-size-4;
      margin-bottom: $margin-size-2;
      width: 100%;

      .mock-input {
        height: 39px;
        display: flex;
        align-items: center;

        .oix {
          color: #757575;
        }

        .url {
          color: white;
        }
      }

      .verified {
        color: #00dcaf;
        font-size: $text-size-small;
      }

      > .inputs-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .input-field {
          margin-top: $margin-size-4;
          display: flex;
          width: 50%;
          flex-grow: 1;
          align-items: flex-end;

          > .input {
            width: 100%;
            margin-right: $margin-size-1;
          }
        }

        > .text-field {
          display: flex;
          flex-direction: column;
          width: 80%;
          flex-grow: 1;

          > .text {
            color: white;
          }

          > .subtext {
            color: $lightgrey;
            font-size: $text-size-small;
          }
        }

        > .button {
          width: 40%;
        }
      }
    }

    > .account-control {
      margin-top: $margin-size-4;
      margin-bottom: $margin-size-2;
      width: 100%;

      > .inputs-area {
        margin-left: $margin-size-2;
        margin-top: $margin-size-4;

        > .row {
          margin-top: $margin-size-3;
          margin-bottom: $margin-size-3;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;

          > .text-field {
            flex-grow: 1;
            width: 70%;
            margin-right: $margin-size-4;
          }

          .button {
            padding-left: 0;
            padding-right: 0;
          }

          > .button {
            width: 30%;
          }
        }
      }
    }
  }

  @media screen and (max-width: $media-break-lg) {
    > .settings-content-container {
      > .account-control {
        > .inputs-area {
          > .row {
            > .text-field {
            }

            > .button {
              width: 40%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $media-break-md) {
    > .settings-content-container {
      > .account-control {
        > .inputs-area {
          > .row {
            > .text-field {
            }

            > .button {
              width: 55%;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $media-break-sm) {
    > .settings-content-container {
      > .account-info {
        > .inputs-row {
          flex-direction: column;

          > .input-field {
            width: 100%;
          }

          > .mr-4 {
            margin-right: 0;
          }
        }
      }

      > .account-preferences {
        > .inputs-row {
          flex-direction: column;

          > .input-field {
            width: 100%;
          }
        }
      }

      > .account-control {
        width: 100%;

        > .inputs-area {
          > .row {
            display: flex;
            flex-direction: column;
            width: 100%;

            > .text-field {
              width: 100%;
            }

            > .button {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

#layout-app #page-display-settings {
  display: flex;
  align-items: center;
  justify-content: center;

  .settings-select {
    width: 410px;
    margin-bottom: 40px;
  }

  .settings-container {
    width: 100%;

    > .settings-area {
      width: 100%;
      display: flex;
      justify-content: space-between;

      > .cell-1 {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;

        > .settings-title-container {
          display: flex;
          align-items: center;

          > .settings-icon {
            color: #00dcaf;
          }
        }
      }

      > .cell-2 {
        margin-left: $margin-size-2;
        display: flex;
        align-items: center;
      }
    }

    > .developer-expand {
      > .row {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: flex-end;

        > .cell-1 {
          width: 48%;
        }

        > .cell-2 {
          width: 48%;
        }
      }

      > .endpoint-container {
      }

      > .button-container {
        display: flex;
        justify-content: flex-end;

        > .cell-1 {
          min-width: 25%;
          max-width: 50%;
        }
      }
    }
  }
  > .settings-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    margin-top: $margin-size-4;

    > .header {
      width: 100%;

      > .title {
        font-size: $text-size-big;
      }

      > .subtext {
        font-size: $text-size-small;
        margin-top: $margin-size-1;
        margin-bottom: $margin-size-1;
        color: $lightgrey;
      }
    }

    .title {
      font-size: $text-size-bigish;
    }

    .subtext {
      font-size: $text-size-small;
      margin-top: $margin-size-1;
      margin-bottom: $margin-size-1;
      color: $lightgrey;
    }

    .danger-text {
      color: red;
      font-size: $text-size-small;
    }

    > .account-display {
      margin-top: $margin-size-4;
      margin-bottom: $margin-size-2;
      width: 100%;

      > .inputs-row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .input-field {
          margin-top: $margin-size-4;
          display: flex;
          width: 50%;
          flex-grow: 1;
          align-items: flex-end;

          > .input {
            width: 100%;
            margin-right: $margin-size-1;
          }
        }

        > .text-field {
          display: flex;
          flex-direction: column;
          width: 80%;
          flex-grow: 1;

          > .text {
            color: white;
          }

          > .subtext {
            color: $lightgrey;
            font-size: $text-size-small;
          }
        }

        > .button {
          width: 40%;
        }
      }
    }

    > .account-languages {
      margin-top: $margin-size-4;
      margin-bottom: $margin-size-3;
      width: 100%;

      > .inputs-row {
        display: flex;
        justify-content: space-between;

        > .input-field {
          margin-top: $margin-size-4;
          display: flex;
          width: 50%;
          flex-grow: 1;
          align-items: flex-end;

          > .input {
            display: flex;
            width: 100%;
            margin-right: $margin-size-1;
          }
        }

        > .text-field {
          display: flex;
          flex-direction: column;
          width: 80%;
          flex-grow: 1;

          > .text {
            color: white;
          }

          > .subtext {
            color: $lightgrey;
            font-size: $text-size-small;
          }
        }

        > .button {
          width: 40%;
        }
      }
    }
  }

  @media screen and (max-width: $media-break-sm) {
    > .settings-content-container {
      > .account-display {
        > .inputs-row {
          flex-direction: column;

          > .input-field {
            width: 100%;
          }

          > .mr-4 {
            margin-right: 0;
          }
        }
      }

      > .account-languages {
        > .inputs-row {
          flex-direction: column;

          > .input-field {
            width: 100%;
          }
        }
      }
    }
  }
}

#layout-app #page-linked-accounts {
  display: flex;
  align-items: center;
  justify-content: center;

  > .linked-accounts-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    margin-top: $margin-size-4;

    > .header {
      width: 100%;

      > .title {
        font-size: $text-size-big;
      }

      > .subtext {
        font-size: $text-size-small;
        margin-top: $margin-size-1;
        margin-bottom: $margin-size-1;
        color: $lightgrey;
      }
    }

    .title {
      font-size: $text-size-bigish;
    }

    .subtext {
      font-size: $text-size-small;
      margin-top: $margin-size-1;
      margin-bottom: $margin-size-1;
      color: $lightgrey;
    }

    > .accounts {
      margin-top: $margin-size-4;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      > .account-container {
        width: 100%;

        > .account {
          width: 100%;
          display: flex;
          justify-content: space-between;

          > .cell-1 {
            display: flex;
            align-items: center;

            > .account-icon-container {
              margin-right: $margin-size-3;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 60px;
              height: 60px;
              background-color: $lightblue;
              border-radius: 1.4rem;

              > .account-icon {
                color: white;
                font-size: 28px;
              }
            }

            > .account-name-container {
              display: flex;
              align-items: center;

              > .account-name {
                margin-right: $margin-size-1;
              }

              > .linked-icon {
                color: #00dcaf;
              }
            }
          }

          > .cell-2 {
            width: 150px;
            display: flex;
            align-items: center;
          }
        }
      }
    }
  }
}

#layout-app #page-discover {
  > .type-filter {
    padding: $margin-size-2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .left-content {
      > .category {
        cursor: pointer;
      }

      > .active {
        color: $border-color-2;
      }
    }

    > .right-content {
      > .filter {
        cursor: pointer;
      }
    }
  }

  > .welcome-text {
    > .welcome {
      font-size: $text-size-big;
      font-weight: bold;
    }
  }
}

#layout-app #page-search {
  > .type-filter {
    padding: $margin-size-2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .left-content {
      display: flex;
      width: 80%;

      > .category {
        cursor: pointer;
      }

      > .active {
        color: $border-color-2;
      }
    }

    > .right-content {
      > .filter {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 880px - 1) {
    > .type-filter {
      > .left-content {
        position: relative;
        width: 80%;
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
      }
    }
  }

  > .search-result {
    > .search {
      font-size: $text-size-big;
    }

    > .result-number {
      font-size: $text-size-medium;
      color: $grey;
    }
  }

  > .group-items {
    padding: $margin-size-1 $margin-size-2;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;

    .collectible-card-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      .account-card {
        width: 100%;
      }
    }
  }
}

#layout-app #page-advance-settings {
  display: flex;
  align-items: center;
  justify-content: center;

  > .settings-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    margin-top: $margin-size-4;

    > .header {
      width: 100%;

      > .title {
        font-size: $text-size-big;
      }

      > .subtext {
        font-size: $text-size-small;
        margin-top: $margin-size-1;
        margin-bottom: $margin-size-1;
        color: $lightgrey;
      }
    }

    > .settings {
      margin-top: $margin-size-4;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .title {
        font-size: $text-size-bigish;
      }

      .subtext {
        font-size: $text-size-small;
        color: $lightgrey;
        margin-top: $margin-size-1;
      }

      > .settings-container {
        width: 100%;

        > .settings-area {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > .cell-1 {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            flex-grow: 1;
            width: 100%;

            > .settings-title-container {
              display: flex;
              align-items: center;

              > .settings-icon {
                color: #00dcaf;
              }
            }
          }

          > .cell-2 {
            margin-left: $margin-size-2;
            width: 150px;
            display: flex;
            align-items: center;
          }
        }

        > .analytics-expand {
          > .row {
            display: flex;
            width: 100%;
            justify-content: space-between;

            > .cell-1 {
              width: 48%;
            }

            > .cell-2 {
              width: 48%;
            }
          }

          > .buttons-row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;

            > .cell-2 {
              min-width: 25%;
              max-width: 50%;
            }
          }
        }

        > .developer-expand {
          > .row {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: flex-end;

            > .cell-1 {
              width: 48%;
            }

            > .cell-2 {
              width: 48%;
            }
          }

          > .endpoint-container {
          }

          > .button-container {
            display: flex;
            justify-content: flex-end;

            > .cell-1 {
              min-width: 25%;
              max-width: 50%;
            }
          }
        }
      }
    }

    @media screen and (max-width: $media-break-sm) {
      > .settings {
        > .settings-container {
          > .analytics-expand {
            > .row {
              flex-direction: column;

              > .cell-1 {
                width: 100%;
              }

              > .cell-2 {
                margin-top: $margin-size-3;
                width: 100%;
              }
            }
          }

          > .developer-expand {
            > .row {
              flex-direction: column;

              > .cell-1 {
                width: 100%;
              }

              > .cell-2 {
                margin-top: $margin-size-3;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

#layout-app #page-notifications-settings {
  display: flex;
  align-items: center;
  justify-content: center;

  .email {
    i {
      font-size: 20px;
    }
    .add {
      cursor: pointer;
      color: $oiblue;
    }

    .verified-email {
      i {
        font-size: 16px;
        cursor: pointer;
        color: $oiblue;
      }
    }
  }

  > .settings-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    margin-top: $margin-size-4;

    > .header {
      width: 100%;

      > .title {
        font-size: $text-size-big;
      }

      > .subtext {
        font-size: $text-size-small;
        margin-top: $margin-size-1;
        margin-bottom: $margin-size-1;
        color: $lightgrey;
      }
    }

    > .settings {
      margin-top: $margin-size-4;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;

      .title {
        font-size: $text-size-bigish;
      }

      .text {
        color: $lightgrey;
      }

      .subtext {
        font-size: $text-size-small;
        color: $lightgrey;
        margin-top: $margin-size-1;
      }

      > .settings-container {
        padding: $margin-size-1 0;
        width: 100%;

        > .settings-area {
          width: 100%;
          display: flex;
          //          align-items: center;
          align-items: flex-start;
          justify-content: space-between;

          > .cell-1 {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            flex-grow: 1;
            width: 100%;

            > .settings-title-container {
              display: flex;
              align-items: flex-start;
              flex-direction: column;

              > .settings-icon {
                color: #00dcaf;
              }
            }
          }

          > .cell-2 {
            margin-left: $margin-size-2;
            width: 40px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    @media screen and (max-width: $media-break-sm) {
    }
  }
}

#layout-app #page-password-settings {
  display: flex;
  align-items: center;
  justify-content: center;

  > .settings-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: white;
    margin-top: $margin-size-4;

    > .header {
      width: 100%;

      > .title {
        font-size: $text-size-big;
      }

      > .subtext {
        font-size: $text-size-small;
        margin-top: $margin-size-1;
        margin-bottom: $margin-size-1;
        color: $lightgrey;
      }
    }

    .title {
      font-size: $text-size-bigish;
    }

    .subtext {
      font-size: $text-size-small;
      margin-top: $margin-size-1;
      margin-bottom: $margin-size-1;
      color: $lightgrey;
    }

    > .password-info {
      margin-top: $margin-size-4;
      margin-bottom: $margin-size-2;
      width: 100%;

      > .form {
        > .password-area {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: space-between;

          > .inputs-col {
            width: 50%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            > .input-field {
              display: flex;
              flex-grow: 1;
              align-items: flex-end;

              > .input {
                display: flex;
                width: 100%;
                margin-right: $margin-size-1;
              }
            }
          }

          > .vertical-line {
            margin: $margin-size-3 $margin-size-3 0px $margin-size-3;
            border: none;
            width: 1px;
            background-color: #535880;
          }

          > .validation-col {
            width: 50%;

            > .validation-info {
              min-width: 40%;
              min-width: 40%;
              margin-top: $margin-size-2;
              height: 90%;
              display: flex;
              align-items: flex-start;
              justify-content: center;
              flex-direction: column;
            }
          }
        }

        > .button-container {
          display: flex;
          justify-content: flex-end;

          > .cell-1 {
            min-width: 25%;
            max-width: 50%;
          }
        }
      }
    }

    > .auth-container {
      margin-top: $margin-size-4;
      margin-bottom: $margin-size-2;
      width: 100%;

      > .inputs-area {
        margin-left: $margin-size-2;
        margin-top: $margin-size-4;

        > .row {
          margin-top: $margin-size-3;
          margin-bottom: $margin-size-3;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;

          > .text-field {
            line-height: 1.2rem;
            width: 75%;
            margin-right: $margin-size-4;
          }
        }
      }
    }
  }

  @media screen and (max-width: $media-break-sm) {
    > .settings-content-container {
      > .password-info {
        > .form {
          > .password-area {
            flex-direction: column;

            > .inputs-col {
              width: 100%;

              > .input-field {
                > .input {
                }
              }
            }

            > .vertical-line {
            }

            > .validation-col {
              width: 100%;

              > .validation-info {
                min-width: 40%;
                min-width: 40%;
                margin-top: $margin-size-2;
                height: 90%;
                display: flex;
                align-items: flex-start;
                justify-content: center;
                flex-direction: column;
              }
            }
          }

          > .button-container {
            > .cell-1 {
              min-width: 25%;
              max-width: 50%;
            }
          }
        }
      }

      > .auth-container {
        > .inputs-area {
          > .row {
            > .text-field {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }
}

#layout-app #page-feed {
  .new-posts {
    transition: 0.2s;
    text-align: center;
    display: flex;
    justify-content: center;
    &--sticky {
      position: sticky;
      top: 102px;
      z-index: 999;
    }
    &--fixed {
      position: absolute;
      top: 80px;
      width: 100%;
    }

    &__button {
      transition: 0.5s;

      cursor: pointer;
      font-size: 14px;
      width: 120px;
      height: 30px;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background-color: $oiblue;

      .icon {
        margin-right: 5px;
        font-size: 12px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: lighten($oiblue, 10%);
        }
      }
    }
  }

  > .content-container {
    display: flex;

    align-items: flex-start;
    .icon-hold {
      width: 37px;
      height: 37px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;

      .icon-wrap {
        height: 37px;
        width: 37px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        position: relative;

        .icon-white-area {
          background-color: #242536;
          border-radius: 50%;
          width: 31px;
          height: 31px;
          display: flex;
          align-content: center;
          justify-content: center;
        }

        i {
          font-size: 15px;
          margin: auto;
          z-index: 10;
        }
      }
    }

    > .main-content {
      flex-grow: 1;
      position: relative;

      .content-upload {
        margin-bottom: $margin-size-4;
        border-radius: $margin-size-3;
        overflow: hidden;
        color: white;

        .top-row {
          background: $bg-color-2;
          display: flex;
          align-items: center;

          .content-upload-btn {
            border: 2px solid $border-color-3;
            border-radius: 50%;
            font-size: 16px;
            padding: 8px;
          }
        }

        .bottom-row {
          background: $bg-color-2-accent-1;
        }
      }

      .collectible-card-detailed {
        margin-bottom: $margin-size-4;
      }
    }

    > .side-content {
      .side-container {
        border-radius: 1.5rem;
        padding: 1rem 0;
        width: 100%;
        background-color: $lightblue;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.2rem 1rem;
          font-size: $text-size-small;
        }

        > .content {
          margin: 0;
        }

        > .footer {
          font-size: $text-size-small;
          padding: 0 1rem;
          padding-top: $margin-size-1;

          > .click {
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: $media-break-lg - 1) {
    .side-content {
      display: none;
    }
  }

  @media (max-width: 656px) {
    > .content-container {
      width: 100%;

      > .main-content {
        .collectible-card-detailed {
          margin-bottom: 12px;
        }
      }
    }
  }
}

#layout-app #page-empty {
  > .side-container {
    border-radius: 1.5rem;
    padding: $margin-size-3 0;
    width: 300px;
    background-color: $lightblue;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .header {
      padding: 0 $margin-size-3;
    }

    > .content {
      margin: $margin-size-2 0;
    }

    > .footer {
      font-size: $text-size-small;
      padding: 0 $margin-size-3;
    }
  }
}

#layout-app #page-bids {
  > .bids {
    @media screen and (max-width: 780px) {
      width: 100%;
      > .page-title,
      .page-description {
        padding: 0 12px;
      }

      .bids-table-content {
        hr {
          display: none;
        }
      }
    }

    .no-content {
      height: 450px;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      color: #474951;

      > .text {
        margin-top: 32px;
        margin-bottom: 40px;
      }

      svg {
        path {
          transform: scale(1);
          fill: #474951;
        }
      }
    }
    > .page-title {
      margin-top: $margin-size-5;
      font-size: 32px;
    }

    > .page-description {
      font-size: 16px;
      margin-top: 8px;
      color: #75767d;
    }

    > .analytics {
      > .change {
        display: flex;

        > .active {
          color: $lightestblue;
        }

        > .change-link {
          cursor: pointer;
        }
      }

      > .analytics-data {
        > .all-data {
          display: grid;
          grid-template-columns: 24% 24% 24% 24%;
          grid-column-gap: 1%;

          > .data-card {
            margin: $margin-size-2;
            padding: $margin-size-2;
            background-color: #272838;
            border-radius: 10px;
            display: flex;
            flex-direction: column;

            > .current-box {
              display: flex;
              align-items: center;
              position: relative;

              > .chart {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                > .count {
                  margin-top: $margin-size-1;
                  position: absolute;
                }
              }

              > .analytics-content {
                display: flex;
                flex-direction: column;
                margin-left: $margin-size-2;

                > .count {
                  font-size: $text-size-big;
                }

                > .text {
                  margin-top: $margin-size-2;

                  > .name {
                    color: $white;
                  }

                  > .date {
                    color: $grey;
                  }
                }
              }

              > .expanded-icon {
                position: absolute;
                bottom: 0;
                right: 0;
                font-size: $text-size-bigish;
                cursor: pointer;
              }
            }

            > .expanded-row {
              > .space {
                display: flex;
                justify-content: space-between;
                color: $grey;
              }
            }
          }
        }

        @media screen and (max-width: $media-break-lg) {
          > .all-data {
            grid-template-columns: 47% 47%;
            grid-column-gap: 6%;
          }
        }

        @media screen and (max-width: $media-break-sm) {
          > .all-data {
            grid-template-columns: 100%;
          }
        }
      }
    }

    > .filter-bar {
      display: flex;
      justify-content: space-between;

      > .filter-collectible {
        display: flex;

        > .input-select {
          width: 130px;
        }
      }

      > .filter-select {
        display: flex;

        > .input-select {
          width: 130px;
        }
      }

      @media screen and (max-width: $media-break-sm) {
        &.filter-bar {
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }

    > .bids-table-content {
      margin-bottom: $margin-size-5;
      margin-top: $margin-size-4;

      > .bids-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $margin-size-3;

        > .table-name {
          font-size: $text-size-big;
        }

        > .total-info {
          > .total-money {
            font-size: $text-size-bigish;
          }

          > .money-sub-info {
            color: $grey;
            font-size: $text-size-small;
            text-align: right;
          }
        }
      }

      > .MuiPaper-root {
        border-radius: 0;
        box-shadow: none;

        > .MuiTable-root {
          background-color: $bg-color-1;

          > .MuiTableHead-root {
            > .MuiTableRow-root {
              > .MuiTablecell-root {
                color: $grey;
                text-align: center;

                > .search-sort {
                  display: flex;
                  align-items: center;
                  color: $white;

                  > .search-input {
                    width: 70%;
                  }
                }
              }

              > .MuiTableCell-alignRight {
              }
            }
          }

          > .MuiTableBody-root {
            > .MuiTableRow-root {
              > .MuiTableCell-root {
                color: $grey;
                padding: 10px;
                text-align: center;

                > .table-content-card {
                  display: flex;
                  align-items: center;

                  > .collapse-icon-container {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 1rem;
                  }

                  > .collapse-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 60px;
                    height: 60px;
                  }

                  > .content-image {
                    width: 60px;
                    height: 60px;
                    border-radius: 15px;
                  }

                  > .content-info {
                    display: flex;
                    flex-direction: column;

                    > .name {
                      color: $white;
                      font-size: $text-size-medium;
                      text-align: left;
                    }

                    > .category {
                      font-size: $text-size-small;
                      color: $lightestblue;
                      text-align: left;
                    }

                    > .date {
                      font-size: $text-size-smaller;
                      color: $grey;
                      text-align: left;
                    }
                  }
                }

                > .flex-item {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  > .license-border {
                    padding: 4px 15px;
                    border: 1px solid $grey;
                    border-radius: 10px;
                    color: $white;
                  }
                }

                > .subscriber {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                }

                > .revenue {
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  color: $white;

                  > .revenue-money {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;

                    > .money {
                      font-size: $text-size-big;
                    }

                    > .revenue-money-info {
                      color: $grey;
                      font-size: $text-size-small;
                    }
                  }
                }
              }
            }
          }
        }

        > .muiTablePagination-root {
          background-color: $bg-color-1;
          color: $white;
        }
      }
    }
  }
}

.basket-x {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $margin-size-2;

  .number-hold-x {
    border: 2px solid $border-color-3;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    color: $lightgrey;
    cursor: pointer;
    user-select: none;
  }
}

.basket-open-x {
  width: 400px;
  height: 540px;
  min-height: 240px;
  max-height: 540px;
  border: 1px solid #333;
  background: adjust-color(#191b27, $lightness: 5%);
  border-radius: 10px;
  box-shadow: 1px 1px 3px 0px #000;
  overflow: auto;
  z-index: 99;

  .item-container {
    .item-x {
      display: flex;
      flex-flow: row nowrap;
      padding: $margin-size-2 0;
      margin: 0 $margin-size-3;
      border-bottom: 1px solid $border-color-4;

      .cell-1 {
        margin-right: $margin-size-2;

        img {
          width: 100px;
          border-radius: 5px;
          user-select: none;
        }
      }

      .cell-2 {
        flex-grow: 1;
      }

      .cell-3 {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-around;

        .price {
          font-size: $text-size-bigish;
          font-weight: 500;
        }
      }
    }
  }

  .summary-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  .footer-row {
    display: flex;
    flex-flow: row nowrap;
    background: #191b27;
    padding: $margin-size-2 0;
    position: absolute;
    bottom: 0;
    width: 100%;

    .cell-1 {
      width: 25%;
      font-size: 18px;
      padding: $margin-size-2 $margin-size-3;
      text-align: center;
      border-right: 1px solid $border-color-1;
      cursor: pointer;
    }

    .cell-2 {
      width: 25%;
      font-size: 18px;
      padding: $margin-size-2 $margin-size-3;
      text-align: center;
      border-right: 1px solid $border-color-1;
      cursor: pointer;
    }

    .cell-3 {
      flex-grow: 1;
      font-size: 20px;
      text-align: center;
      padding: $margin-size-2 $margin-size-3;
      cursor: pointer;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  padding: $margin-size-3;

  .item {
    width: 100%;
    padding-bottom: 100%;
    background-color: #2e3045;
    border-radius: 20px;
  }
}

#layout-app #page-interest-selection {
  display: flex;
  align-items: center;
  justify-content: center;

  > .interest-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .welcome-text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: $margin-size-5;

      > .text {
        font-size: $text-size-bigger;
        margin: 0;
        text-align: center;
      }

      > .subtext {
        text-align: center;
        margin: 0;

        > i {
          color: $grey;
        }
      }
    }

    > .interest-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: $margin-size-5;

      > .interest-header {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-size-1;

        > .follow-check {
          display: flex;
          align-items: center;
          justify-content: center;

          > .checkbox {
            cursor: pointer;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            outline: 0;
            background: $bg-color-1;
            height: 16px;
            width: 16px;
            border: 1px solid $white;
            border-radius: 5px;

            &.checkbox:checked {
              color: $white;
              border: 1px solid $lightestblue;
            }

            &.checkbox:after {
              content: '';
              position: relative;
              left: 40%;
              top: 20%;
              width: 15%;
              height: 40%;
              border: solid #fff;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
              display: none;
            }

            &.checkbox:checked:after {
              display: block;
            }
          }

          > .text {
            font-size: $text-size-medium;
            margin-left: $margin-size-1;
            text-align: center;
          }
        }

        > .search {
          > input {
            border: none;
            border-radius: 10px;
            height: 25px;
            background-color: $lightblue;
            text-indent: 10px;
          }

          @media (hover: hover) and (pointer: fine) {
            > input:hover {
              border: $lightestblue;
              border-radius: 10px;
            }
          }
        }
      }

      > .interest-all {
        width: 100%;
        height: 350px;
        display: grid;
        grid-template-columns: 47% 47%;
        grid-auto-rows: minmax(min-content, max-content);
        grid-column-gap: 6%;
        overflow-y: scroll;
        margin-top: $margin-size-2;
        margin-bottom: $margin-size-2;

        > .active {
          > .flex {
            > .name-box {
              color: #5e5f67;

              > .box {
                border: 1px solid #5e5f67 !important;
                color: #5e5f67;

                > p {
                  color: #5e5f67;
                }
              }
            }

            > .follow {
              color: #114967 !important;
            }
          }
        }

        > .interest-content {
          display: inline-block;
          margin-bottom: $margin-size-3;

          > .flex {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            > .name-box {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              > .box {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $white;
                border-radius: 5px;
                margin-right: $margin-size-2;

                > p {
                  font-size: $text-size-bigish;
                }
              }
            }

            > .follow {
              color: $lightestblue;
              margin-right: $margin-size-1;
              cursor: pointer;
            }
          }
        }
      }

      > .next-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        > p {
          color: $lightestblue;
        }
      }
    }
  }

  @media screen and (max-width: $media-break-md) {
    > .interest-content-container {
      > .interest-content {
        > .interest-all {
          grid-template-columns: 100%;
        }
      }
    }
  }
}

#layout-app #page-creator-category {
  display: flex;
  align-items: center;
  justify-content: center;

  > .creator-category-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .welcome-text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: $margin-size-5;

      > .text {
        font-size: $text-size-bigger;
        text-align: center;
        margin: 0;
      }

      > .subtext {
        margin: 0;
        text-align: center;

        > i {
          color: $grey;
        }
      }
    }

    > .creator-category-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: $margin-size-5;

      > .creator-category-header {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $margin-size-1;

        > .info {
          display: flex;
          align-items: center;
          width: 20%;

          > p {
            display: flex;
            align-items: center;
          }
        }
      }

      > .creator-category-all {
        width: 100%;
        height: 350px;
        display: grid;
        grid-template-columns: 47% 47%;
        grid-column-gap: 6%;
        grid-auto-rows: minmax(min-content, max-content);
        overflow-y: scroll;
        margin-top: $margin-size-2;
        margin-bottom: $margin-size-2;

        > .creator-category-content {
          display: inline-block;
          margin-bottom: $margin-size-3;

          > .flex {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            > .name-box {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              > .box {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $white;
                border-radius: 50%;
                margin-right: $margin-size-2;

                > p {
                  font-size: $text-size-bigish;
                }
              }

              > .name {
                > Icon {
                  display: none;
                }
              }
            }

            > .select {
              color: $lightestblue;
              margin-right: $margin-size-1;
              cursor: pointer;
            }
          }

          &.disable {
            > .flex {
              > .name-box {
                color: #5e5f67;

                > .box {
                  border: 1px solid #5e5f67;
                  color: #5e5f67;

                  > p {
                    color: #5e5f67;
                  }
                }
              }

              > .select {
                color: #114967;
                cursor: not-allowed;
              }
            }
          }
        }
      }

      > .next-button {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        > p {
          font-size: $text-size-biggest;
          color: $lightestblue;
        }
      }
    }
  }

  @media screen and (max-width: $media-break-md) {
    > .creator-category-content-container {
      > .creator-category-content {
        > .creator-category-all {
          grid-template-columns: 100%;
        }
      }
    }
  }
}

#layout-app #page-invite-friends {
  display: flex;
  align-items: center;
  justify-content: center;

  > .invite-friends-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .header-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: $margin-size-5;

      > .text {
        font-size: $text-size-bigger;
      }

      > .sub-text {
        text-align: center;

        > .plus {
          margin: $margin-size-1;
          padding: 2px $margin-size-1;
          border-radius: 15px;
          background-color: $border-color-2;
        }
      }
    }

    > .progress-bar {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $margin-size-1;

      > .progress {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        > .join {
          display: block;
          width: 200px;
          text-align: right;
        }

        > .plus {
          display: block;
          width: 200px;
        }
      }
    }

    > .invite-email {
      width: 100%;

      > .send-invite-friends {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .email {
          display: flex;
          align-items: center;
          width: 45%;

          > .email-icon {
            padding: 12px;
            border: 1px solid $grey;
            border-radius: 50%;
          }

          > .text {
            color: $link-color;
            margin-left: $margin-size-2;
          }
        }

        > .name {
          width: 45%;
          display: flex;
          align-items: center;
        }
      }
    }

    > .slim-scroll-style {
      width: 100%;

      > .os-padding {
        > .os-viewport {
          > .os-content {
            > .social-madia-list {
              width: 100%;
              height: 350px;
              display: grid;
              grid-template-columns: 47% 47%;
              grid-auto-rows: minmax(min-content, max-content);
              grid-column-gap: 6%;
              margin-top: $margin-size-2;
              margin-bottom: $margin-size-2;

              .social-madia-content {
                display: inline-block;
                margin-bottom: $margin-size-3;

                > .flex {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  justify-content: space-between;

                  > .name-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    > .icon {
                      width: 41px;
                      height: 41px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border: 1px solid $white;
                      border-radius: 50%;
                      margin-right: $margin-size-2;
                      font-size: $text-size-bigish;
                    }
                  }

                  > .share {
                    color: $lightestblue;
                    margin-right: $margin-size-1;
                    cursor: pointer;

                    > .share {
                      color: $grey;
                    }
                  }
                }
              }
            }
          }
        }
      }

      > .os-scrollbar {
        > .os-scrollbar-track {
          > .os-scrollbar-handle {
            color: #2e3146;
            background-color: #2e3146;
          }
        }
      }
    }

    > .share-done {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > .share {
        display: flex;
        align-items: center;
        cursor: pointer;

        > .share-button {
          padding: 12px;
          border: 1px solid $grey;
          border-radius: 50%;
        }

        > .text {
          color: $link-color;
          margin-left: $margin-size-2;
        }
      }
    }
  }

  @media screen and (max-width: $media-break-md) {
    > .invite-friends-content {
      > .invite-email {
        > .send-invite-friends {
          flex-direction: column;
          align-items: flex-start;

          > .email {
            width: 100%;
          }

          > .name {
            width: 89%;
            margin-left: 43px;
            flex-direction: column;
          }
        }
      }

      > .slim-scroll-style {
        > .os-padding {
          > .os-viewport {
            > .os-content {
              > .social-madia-list {
                grid-template-columns: 100%;
                grid-auto-rows: minmax(min-content, max-content);
                padding-right: $margin-size-2;
              }
            }
          }
        }
      }
    }
  }
}

#layout-app #page-notification {
  > .content-container {
    display: flex;
    align-items: flex-start;

    > .main-content {
      flex-grow: 1;

      .back-notification {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon {
          cursor: pointer;
          color: #898a91;
          font-size: 18px;
        }
        > .icon-name {
          display: flex;
          align-items: center;
          > .name {
            font-size: 18px;
          }
        }
      }

      .collectible-card-detailed {
        margin-bottom: $margin-size-4;
      }

      > .show-more {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      > .no-notification {
        text-align: center;
        font-size: $text-size-bigish;
        color: $grey;
        margin: $margin-size-5;
      }
    }

    .empty-text {
      width: 100%;
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon {
        font-size: 50px;
        margin-bottom: 32px;
        color: #474851;
      }

      .text {
        font-size: 14px;
        color: #474851;
        margin-bottom: 50px;
      }

      .button {
        min-width: 126px;
        height: 35px;
        border-radius: 18px;
        border: 1px solid white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > .side-content {
      .side-container {
        border-radius: 1.5rem;
        padding: 1rem 0;
        width: 100%;
        background-color: $lightblue;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.2rem 1rem;
          font-size: $text-size-small;
        }

        > .content {
          margin: 0;
        }

        > .footer {
          font-size: $text-size-small;
          padding: 0 1rem;
          padding-top: $margin-size-1;

          > .click {
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: $media-break-lg - 1) {
    .side-content {
      display: none;
    }
  }
}

#layout-app #page-followers {
  > .content-container {
    display: flex;
    align-items: flex-start;

    > .main-content {
      flex-grow: 1;

      .back {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .icon-name {
          display: flex;
          align-items: center;

          > .icon {
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            background-color: $bg-color-2;
            color: white;

            > .icon {
              font-size: 18px;
            }
          }

          .name {
            font-size: $text-size-bigish;
            font-weight: bold;
          }

          .slug {
            font-size: $text-size-small;
            color: $grey;
            margin-top: 2px;
          }
        }
      }

      > .options {
        margin-top: $margin-size-3;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0px $margin-size-1;

        a {
          color: $grey;
        }

        a.is-active {
          color: $lightestblue;
          font-weight: bold;
        }
      }

      .collectible-card-detailed {
        margin-bottom: $margin-size-4;
      }

      > .show-more {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    > .side-content {
      .side-container {
        border-radius: 1.5rem;
        padding: 1rem 0;
        width: 100%;
        background-color: $lightblue;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0.2rem 1rem;
          font-size: $text-size-small;
        }

        > .content {
          margin: 0;
        }

        > .footer {
          font-size: $text-size-small;
          padding: 0 1rem;
          padding-top: $margin-size-1;

          > .click {
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: $media-break-lg - 1) {
    .side-content {
      display: none;
    }
  }
}

#page-collectible_ {
  margin: 0px;
  padding: 0px;
  -webkit-box-align: stretch;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(255, 255, 255);
  display: flex;
  flex: 1 0 auto;
  min-height: 100vh;
  min-width: 0px;
  max-width: 100%;
  -webkit-box-flex: 1;
  position: relative;
  overflow: hidden;

  .divider {
    height: 1px;
    width: 28px;
    background-color: $darkgrey;
  }

  .loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon-button {
    background-color: rgba(25, 27, 39, 0.6);
    position: absolute;
    top: $margin-size-2;
    right: $margin-size-2;
    border: 1.2px solid white;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    font-size: 14px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #252730;
        border: none;
      }
    }
  }
  .no-comment {
    height: 350px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #252731;
    .info {
      i {
        margin-bottom: $margin-size-1;
        font-size: 105px;
      }
    }
  }

  .icon-container {
    border: 1px solid white;
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(25, 27, 39, 0.6);
    margin-left: $margin-size-2;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        cursor: pointer;
        background-color: #252730;

        border: none;
      }
    }
  }
  > .expand {
    background-color: #000;
    width: 100vw;
    height: 100vh;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-container {
      position: absolute;
      top: $margin-size-2;
      right: $margin-size-2;
      margin-right: 0;
    }

    .expand-info {
      color: white;
      position: absolute;
      bottom: $margin-size-3;
      left: $margin-size-3;

      div,
      a {
        font-size: 1.2rem;
        font-weight: 700;
        color: white;
      }
    }

    .collectible-container {
      width: 80%;
      height: 80%;
      display: flex;
      align-items: center;
      justify-content: center;
      //      border-radius: 4%;
      overflow: hidden;

      .collectible-preview {
        &__model-viewer-wrapper {
      
          model-viewer {
            
            &::part(default-ar-button){
             left: unset; 
             right: 16px;
             transform-origin: bottom right;
            }      
          }  
        }
      }

      .restricted-content {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        > .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          > .icon {
            font-size: $text-size-bigger;
          }

          > .title {
            font-size: $text-size-bigish;
            font-weight: 700;
          }

          > .sub-title {
            font-size: $text-size-medium;
          }

          > .button {
          }

          @media only screen and (max-width: 400px) {
            & {
              width: 80%;
            }
          }
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .video-react-big-play-button {
        display: none;
      }
    }
  }

  > .logo {
    width: 30px;
    height: 30px;
    position: absolute;
    top: $margin-size-3;
    left: $margin-size-3;
    z-index: 1000;

    > img {
      width: 100%;
    }
  }

  > .collectible-content {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: black;
    position: relative;

    > .collectible {
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100vw - 535px);
      height: calc(100vw - 535px);
      padding: 40px;
      max-height: 700px;
      max-width: 700px;
      //      border-radius: 4%;
      overflow: hidden;
      position: relative;

      .restricted-content {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        > .text {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          > .icon {
            font-size: $text-size-bigger;
          }

          > .title {
            font-size: $text-size-bigish;
            font-weight: 700;
          }

          > .sub-title {
            font-size: $text-size-medium;
          }

          @media only screen and (max-width: 400px) {
            & {
              width: 80%;
            }
          }
        }
      }

      video {
        height: 100%;
        width: 100%;
      }

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      > video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    > .collectible-button-group {
      position: absolute;
      top: $margin-size-2;
      right: $margin-size-2;
      display: flex;
      align-items: center;

      .loader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      #wave {
        height: 34px;
        width: 70px;
        fill: #fff;
      }

      @for $i from 1 through 9 {
        #Line_#{$i} {
          animation: pulse 1s infinite;
          animation-delay: $i * 0.15s;
        }
      }

      @keyframes pulse {
        0% {
          transform: scaleY(1);
          transform-origin: 50% 50%;
        }

        50% {
          transform: scaleY(0.7);
          transform-origin: 50% 50%;
        }

        100% {
          transform: scaleY(1);
          transform-origin: 50% 50%;
        }
      }
    }

    > .collectible-icons {
      position: absolute;
      bottom: $margin-size-2;
      right: $margin-size-2;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 15;
      //      margin-right: 0.8rem;

      .collectible-icon {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid $lightgrey;
        background-color: rgba(0, 0, 0, 0.5);
        text-shadow: 0px 0px 3px $black;

        &--borderless {
          border: none;
        }
        &--ethereum {
          background-color: $ethereum;
        }
        &--polygon {
          background-color: $polygon;
        }
        &--avalanche {
          background-color: $avalanche;
        }
        &--binance {
          background-color: $binance;
        }
        &--default {
          background-color: $oigreen;
        }
      }
    }
  }

  > .collectible-info-container {
    width: 515px;
    // min-width: 515px;
    height: 100vh;

    @media only screen and (max-width: 515px) {
      min-width: unset;
    }

    .profile-container {
      padding: $margin-size-2;
      display: flex;
      align-items: center;

      > .profile-info {
        padding-left: $margin-size-3;

        > .first-row {
          > .name {
            display: flex;
            align-items: center;
  
            a {
              font-size: 18px;
              font-weight: 600;
              color: white;
              max-width: 250px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
  
              @media screen and (max-width: $media-break-sm) {
                max-width: 140px;
              }
            }
          }
        }

        > .second-row {
          
          .slug {
            color: $grey;
            font-size: 14px;
            font-weight: 500;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .date {
            margin-left: 0.3rem;
            color: $grey;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
          }
        }
      }

      .share-button-active {
        color: #00dcaf;
      }
    }

    .collectible-info {
      padding: $margin-size-2;

      > .title {
        font-size: 1.8rem;
        font-weight: 600;
      }
      > .edition {
        color: #8c8d93;
        font-size: 1.2rem;
      }

      > .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;

        .comment-icon {
          font-size: 20px;
          width: 70px;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #d1d1d3;

          .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            transition: 0.5s;
            font-weight: 100;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                width: 40px;
                height: 40px;
                background-color: #434659;
                color: #00b7ff;
              }
            }
          }

          span {
            font-size: 14px;
          }
        }

        .share-icon {
          font-size: 20px;
          width: 70px;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #d1d1d3;

          .shared {
            color: #00dcaf;
          }

          .icon {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            transition: 0.5s;
            font-weight: 100;

            svg {
              width: 22px;
            }

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                width: 40px;
                height: 40px;
                background-color: #434659;

                path {
                  fill: #00dcaf;
                }
              }
            }

            path {
              fill: #d1d1d3;
            }

            .shared {
              path {
                fill: #00dcaf;
              }
            }
          }

          span {
            font-size: 14px;
          }
        }
      }

      > .description {
        max-width: 95%;
        .collectible-details-container {
          &.short {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        }
        > .show-more-button {
          cursor: pointer;
          color: $grey;
        }
      }

      > .tab-groups {
        display: flex;
        align-items: center;

        > .tab {
          color: $oiblue;
          padding: 0.3rem $margin-size-2;
          border: 1px solid #2e3146;
          border-radius: 15px;
          width: 95px;
          text-align: center;
          transition: 0.2s;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              cursor: pointer;
              background-color: $lightblue;
              color: white;

              border: none;
            }
          }
        }

        > .active {
          background-color: $lightblue;
          color: white;
        }
      }
    }

    .tab-content {
      .collectible-attributes {
        padding: 15px 0;

        .collectible-attributes-details {
          color: $grey;
          font-size: 16px;
          margin-bottom: 10px;
          padding: 5px 0;
          border-bottom: 1px solid $lightblue;
          padding: 0 10px 10px 10px;
        }
        .collectible-attributes-wrapper {
          padding: 0 10px;
          grid-gap: 10px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          font-size: 14px;

          .collectible-attribute {
            background-color: #20212e;
            height: 50px;
            border-radius: 6px;
            color: $white;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            overflow: hidden;

            .collectible-attribute-col {
              max-width: 50%;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
        }
      }

      &.diffrent-platform {
        > .comments {
          > .comment-box {
            bottom: 0;
            word-break: break-all;
          }

          > .comments-newest-loader {
            margin-bottom: 90px;
          }
        }

        .collectible-details,
        .activity-content {
          margin-bottom: 0;
          padding-bottom: 240px;
        }
      }

      &.oix-platform {
        > .comments {
          > .comment-box {
            bottom: 176px;
            word-break: break-all;
          }

          > .comments-newest-loader {
            margin-bottom: 240px;
          }
        }

        .collectible-details,
        .activity-content {
          margin-bottom: 178px;
          padding-bottom: 240px;
        }
      }

      &.oix-platform-not-button {
        > .comments {
          > .comment-box {
            bottom: 103px;
            word-break: break-all;
          }

          > .comments-newest-loader {
            margin-bottom: 170px;
          }
        }

        .collectible-details,
        .activity-content {
          margin-bottom: 105px;
          padding-bottom: 240px;
        }
      }

      @media only screen and (max-width: 768px) {
      }

      &.auction {
        > .comments {
          > .comment-box {
            bottom: 175px;
          }

          > .comments-newest-loader {
            margin-bottom: 265px;
          }
        }
      }

      > .comments {
        position: relative;
        padding: $margin-size-2;
        > .comments-header {
          margin-top: $margin-size-2;
        }

        > .comment-container {
          height: 100%;
        }

        > .comment-box {
          right: 0;
          padding: $margin-size-2;
          background-color: $bg-color-1;
          position: fixed;
          z-index: 100;
          width: 515px;
          margin-top: $margin-size-2;

          @media only screen and (max-width: 1224px) {
            width: 500px;
          }
        }
      }

      > .collectible-details {
        > .category-line {
          display: flex;
          justify-content: space-between;
          font-size: 0.9rem;
          padding: $margin-size-2;
        }
      }
      > .collectible-owners {
        padding: $margin-size-2;

        > .owners-header {
          font-size: 0.9rem;
          color: $grey;
          display: flex;
          justify-content: space-between;
        }
      }
      > .collectible-bids {
        padding: $margin-size-2;

        > .bids-header {
          font-size: 0.9rem;
          color: $grey;
          display: flex;
          justify-content: space-between;
        }
      }
      > .collectible-activity {
        padding: $margin-size-2;

        > .activity-header {
          font-size: 0.9rem;
          color: $grey;
          display: flex;
          justify-content: space-between;
        }

        > .activity-content {
          > div > .hr-wrap {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            > hr {
              width: calc(100% - 50px - 1.4rem);
            }
          }
        }

        > .activity-show-more {
          cursor: pointer;
          font-size: 14px;
          color: #85868c;
        }
      }
      .generic-card {
        display: flex;
        align-items: center;

        > .creator-info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          > .specs {
            display: flex;

            flex-direction: column;
            justify-content: center;
            height: 100%;
            > .title {
              display: flex;

              > .name {
                font-size: 16px;
              }
              > .antic-icon {
                width: 16px;
                height: 16px;
                margin-left: 0.2rem;
                margin-right: 0;
              }  
            }
            > .sub-title {
              color: white;
              font-size: 14px;
            }
            > .description {
              font-size: 12px;
              margin-top: $margin-size-1;
              color: #76787e;
            }
          }

          > .actions {
            display: flex;
            align-items: center;
            height: 100%;
            position: relative;

            > .props {
              display: flex;
              align-items: center;
              justify-content: center;
              > .prop {
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #2e3146;
                border-radius: 15px;
                width: 90px;
                font-size: 12px;
                text-align: center;
                transition: 0.2s;
                height: 28px;
              }
            }

            .dropdown-icon {
              color: #85868c;
              width: 35px;
              height: 35px;
              font-size: 14px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.5s;

              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  cursor: pointer;
                  background-color: #434659;
                  color: white;
                }
              }
            }
          }
        }
      }
    }

    .bid-container {
      position: fixed;
      bottom: 0;
      width: 515px;
      z-index: 90;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: $bg-color-1;

      > .bid-info {
        padding: 6px $margin-size-2;
        width: 100%;
        display: flex;
        height: 105px;

        .text {
          color: $grey;
          margin-bottom: $margin-size-3;
        }

        > .left {
          padding: 3px 0;
          width: 100%;
          border-right: 1px solid $lightblue;
          display: flex;
          flex-direction: column;

          > .price-eth {
            font-size: 1.8rem;
            font-weight: 600;

            @media screen and (max-width: 576px) {
              font-size: 1.2rem;
              padding-right: 4px;
            }
          }

          > .price-usd {
            color: $grey;
          }

          > .not-listed {
            width: 30px;
            height: 5px;
            border-radius: 10px;
            background-color: #ffffff;
            border: 1px solid #707070;
            margin-top: 5px;
          }
        }

        > .right {
          padding: 3px 0px;
          width: 100%;
          display: flex;
          flex-direction: column;
          padding-left: $margin-size-3;

          > .time {
            display: flex;

            .slug {
              margin-right: 4px;
            }

            > .hours,
            .minutes {
              margin-right: $margin-size-4;
            }
            > .hours,
            .minutes,
            .seconds {
              display: flex;
              flex-direction: column;

              > .value {
                font-size: 1.8rem;
                font-weight: 600;
              }

              > .text {
                margin: 0;
                color: $grey;
              }
            }
          }

          > .owned-by {
            display: flex;
            align-items: center;

            > .account {

              > .first-row {
                display: flex;
                align-items: center;
  
                > .name {
                  font-size: 16px;
                  font-weight: 600;
                  color: white;
                  max-width: 100px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                
                > .antic-icon {
                  width: 16px;
                  height: 16px;
                  margin-left: 0.2rem;
                  margin-right: 0;
                }
              }

              > .second-row {
                display: flex;
                align-items: center;  
                
                > .slug {
                  color: $grey;
                  font-size: 14px;
                  max-width: 100px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }              
  
                > .time {
                  color: #8c8d93;
                  font-size: 14px;
                  margin-left: 0.3rem;
                }
              }
            }
          }

          > .congratulations {
            > .congratulations-text {
              color: #8c8d93;
              font-size: 12px;
            }
            > .learn-more {
              color: #00b7ff;
              font-size: 14px;
            }
          }

          > .start-auction {
            > .start-auction-text {
              color: #8c8d93;
              font-size: 12px;
            }
            > .learn-more {
              color: #00b7ff;
              font-size: 14px;
              margin-top: 14px;
            }
          }
        }
      }
      > .button-container {
        padding: 0 $margin-size-2;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  @media only screen and (min-width: 900) and (max-width: 1200px) {
    & {
      > .collectible-content {
        height: auto;

        > .collectible {
          width: calc(85vw - 500px);
          height: calc(85vw - 500px);
          margin: $margin-size-4 0;
        }
      }

      > .collectible-info-container {
        width: 400px !important;
      }
    }
  }

  @media only screen and (max-width: 900px) {
    & {
      display: flex;
      flex-direction: column;

      > .collectible-content {
        height: auto;

        > .collectible {
          width: calc(100vw);
          height: calc(100vw);
          margin: 0;
          border-radius: 0;
        }

        > .collectible-button-group {
          right: $margin-size-5;
        }
      }

      > .collectible-info-container {
        width: 100%;
        height: 100%;

        overflow-y: hidden;
        .tab-content {
          > .comments {
            > .comment-box {
              width: 100%;
              right: 0;
            }
          }
        }
        .bid-container {
          right: 0;
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    & {
      > .logo {
        width: 20px;
        height: 20px;
        top: $margin-size-2;
        left: $margin-size-2;
      }

      > .collectible-info-container {
        .tab-content {
          .props {
            flex-direction: column;
            .prop {
              margin: 0;

              &:nth-child(2) {
                margin-top: $margin-size-1;
              }
            }
          }
        }
      }
    }
  }
}

#layout-app .page-help-support {
  &__header {
    > h2 {
      font-size: 24px;
      font-weight: 500;
      margin: 0;
    }

    > p {
      padding: 0;
      font-size: 12px;
      color: $lightgrey;
    }
  }

  &__help-content {
    &__title {
      font-size: 16px;
      font-weight: 500;

      > i {
        font-size: 14px;
        margin: auto;
        z-index: 10;
        color: $lightgrey;
      }
    }

    &__sub-title {
      font-size: 12px;
      color: $lightgrey;
    }

    &__link-box {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 26px;

      .help-content-box {
        width: 100%;
        padding-bottom: 100%;
        background-color: #232536;
        border: 1px solid $lightblue;
        border-radius: 25px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        position: relative;

        > div {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: absolute;
        }

        &__icon-wrap {
          height: 75px;
          width: 75px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
          background-origin: border-box;
          background-clip: content-box, border-box;
          position: relative;

          .icon-white-area {
            background-color: #2e3146;
            border-radius: 50%;
            width: 70px;
            height: 70px;
            display: flex;
            align-content: center;
            justify-content: center;
          }

          i {
            font-size: 24px;
            margin: auto;
            z-index: 10;
          }
        }
      }

      @media screen and (max-width: 768px) {
        & {
          grid-template-columns: 1fr 1fr;
        }
      }

      @media screen and (max-width: 442px) {
        & {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
