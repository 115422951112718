.meetOix {
  width: 100%;
  padding-top: 30px;

  &__videoFrame {
    aspect-ratio: 16/9;
    height: 640px;
  }
  @media screen and (max-width: 1200px) {
    &__videoFrame {
      height: 400px;
    }
  }
  @media screen and (max-width: 800px) {
    &__videoFrame {
      height: 201px;
    }
  }

  &__title {
    font-weight: 600;
    margin-left: 30px;
    font-size: 30px;
    line-height: 33px;
    margin-bottom: 11px;
  }

  &__subTitle {
    margin-left: 30px;

    font-size: 18px;
    line-height: 20px;
    color: $grey;
    margin-bottom: 68px;
  }

  &__videoContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: black;

    .video-react-video {
      width: 100%;
      height: 100%;
    }

    .video-react {
      max-height: 430px;
    }

    .video-react-control-bar,
    .video-react-button {
      display: none;
    }
  }

  &__playButton {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    border-radius: 50%;
    font-size: 64px;
  }

  @media screen and (max-width: 800px) {
    &__title {
      margin-left: 10px;
      font-size: 24px;
      line-height: 27px;
      margin-bottom: 11px;
    }

    &__subTitle {
      margin-left: 10px;
      margin-bottom: 35px;
    }
  }
}
