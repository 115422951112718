svg path.collection-svg {
  fill: red !important;
}

.fullpage-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.text {
  &-white {
    color: $white;
  }

  &-black {
    color: $black;
  }

  &-grey {
    color: $grey;
  }

  &-lightgrey {
    color: $lightgrey;
  }

  &-darkgrey {
    color: $darkgrey;
  }

  &-red {
    color: $red;
  }

  &-size-reset {
    font-size: 1rem;
  }

  &-small {
    font-size: $text-size-small;
  }

  &-smaller {
    font-size: $text-size-smaller;
  }

  &-smallest {
    font-size: $text-size-smallest;
  }

  &-bigish {
    font-size: $text-size-bigish;
  }

  &-big {
    font-size: $text-size-big;
  }

  &-bigger {
    font-size: $text-size-bigger;
  }

  &-biggest {
    font-size: $text-size-biggest;
  }

  &-center {
    text-align: center;
  }

  &-shadow {
    text-shadow: 0px 0px 4px $black;
  }

  &-bold {
    font-weight: 500;
  }

  &-bolder {
    font-weight: 600;
  }

  &-align-start {
    text-align: start;
  }

  &-align-left {
    text-align: left;
  }

  &-align-center {
    text-align: center;
  }

  &-align-end {
    text-align: end;
  }

  &-align-right {
    text-align: right;
  }

  &-underline {
    text-decoration: underline;
  }
}

.bg-color {
  &-1 {
    background-color: $bg-color-1;
  }

  &-2 {
    background-color: $bg-color-2;
  }

  &-3 {
    background-color: $bg-color-3;
  }
}

.m {
  &-0 {
    margin: 0;
  }

  &-1 {
    margin: $margin-size-1;
  }

  &-2 {
    margin: $margin-size-2;
  }

  &-3 {
    margin: $margin-size-3;
  }

  &-4 {
    margin: $margin-size-4;
  }

  &-5 {
    margin: $margin-size-5;
  }

  &x {
    &-0 {
      margin-left: 0;
      margin-right: 0;
    }

    &-1 {
      margin-left: $margin-size-1;
      margin-right: $margin-size-1;
    }

    &-2 {
      margin-left: $margin-size-2;
      margin-right: $margin-size-2;
    }

    &-3 {
      margin-left: $margin-size-3;
      margin-right: $margin-size-3;
    }

    &-4 {
      margin-left: $margin-size-4;
      margin-right: $margin-size-4;
    }

    &-5 {
      margin-left: $margin-size-5;
      margin-right: $margin-size-5;
    }

    &-auto {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &y {
    &-0 {
      margin-top: 0;
      margin-bottom: 0;
    }

    &-1 {
      margin-top: $margin-size-1;
      margin-bottom: $margin-size-1;
    }

    &-2 {
      margin-top: $margin-size-2;
      margin-bottom: $margin-size-2;
    }

    &-3 {
      margin-top: $margin-size-3;
      margin-bottom: $margin-size-3;
    }

    &-4 {
      margin-top: $margin-size-4;
      margin-bottom: $margin-size-4;
    }

    &-5 {
      margin-top: $margin-size-5;
      margin-bottom: $margin-size-5;
    }
  }

  &l {
    &-0 {
      margin-left: 0;
    }

    &-1 {
      margin-left: $margin-size-1;
    }

    &-2 {
      margin-left: $margin-size-2;
    }

    &-3 {
      margin-left: $margin-size-3;
    }

    &-4 {
      margin-left: $margin-size-4;
    }

    &-5 {
      margin-left: $margin-size-5;
    }
  }

  &r {
    &-0 {
      margin-right: 0;
    }

    &-1 {
      margin-right: $margin-size-1;
    }

    &-2 {
      margin-right: $margin-size-2;
    }

    &-3 {
      margin-right: $margin-size-3;
    }

    &-4 {
      margin-right: $margin-size-4;
    }

    &-5 {
      margin-right: $margin-size-5;
    }
  }

  &t {
    &-0 {
      margin-top: 0;
    }

    &-1 {
      margin-top: $margin-size-1;
    }

    &-2 {
      margin-top: $margin-size-2;
    }

    &-3 {
      margin-top: $margin-size-3;
    }

    &-4 {
      margin-top: $margin-size-4;
    }

    &-5 {
      margin-top: $margin-size-5;
    }
  }

  &b {
    &-0 {
      margin-bottom: 0;
    }

    &-1 {
      margin-bottom: $margin-size-1;
    }

    &-2 {
      margin-bottom: $margin-size-2;
    }

    &-3 {
      margin-bottom: $margin-size-3;
    }

    &-4 {
      margin-bottom: $margin-size-4;
    }

    &-5 {
      margin-bottom: $margin-size-5;
    }
  }
}

.p {
  &-0 {
    padding: 0;
  }

  &-1 {
    padding: $margin-size-1;
  }

  &-2 {
    padding: $margin-size-2;
  }

  &-3 {
    padding: $margin-size-3;
  }

  &-4 {
    padding: $margin-size-4;
  }

  &-5 {
    padding: $margin-size-5;
  }

  &x {
    &-0 {
      padding-left: 0;
      padding-right: 0;
    }

    &-1 {
      padding-left: $margin-size-1;
      padding-right: $margin-size-1;
    }

    &-2 {
      padding-left: $margin-size-2;
      padding-right: $margin-size-2;
    }

    &-3 {
      padding-left: $margin-size-3;
      padding-right: $margin-size-3;
    }

    &-4 {
      padding-left: $margin-size-4;
      padding-right: $margin-size-4;
    }

    &-5 {
      padding-left: $margin-size-5;
      padding-right: $margin-size-5;
    }
  }

  &y {
    &-0 {
      padding-top: 0;
      padding-bottom: 0;
    }

    &-1 {
      padding-top: $margin-size-1;
      padding-bottom: $margin-size-1;
    }

    &-2 {
      padding-top: $margin-size-2;
      padding-bottom: $margin-size-2;
    }

    &-3 {
      padding-top: $margin-size-3;
      padding-bottom: $margin-size-3;
    }

    &-4 {
      padding-top: $margin-size-4;
      padding-bottom: $margin-size-4;
    }

    &-5 {
      padding-top: $margin-size-5;
      padding-bottom: $margin-size-5;
    }
  }

  &l {
    &-0 {
      padding-left: 0;
    }

    &-1 {
      padding-left: $margin-size-1;
    }

    &-2 {
      padding-left: $margin-size-2;
    }

    &-3 {
      padding-left: $margin-size-3;
    }

    &-4 {
      padding-left: $margin-size-4;
    }

    &-5 {
      padding-left: $margin-size-5;
    }
  }

  &r {
    &-0 {
      padding-right: 0;
    }

    &-1 {
      padding-right: $margin-size-1;
    }

    &-2 {
      padding-right: $margin-size-2;
    }

    &-3 {
      padding-right: $margin-size-3;
    }

    &-4 {
      padding-right: $margin-size-4;
    }

    &-5 {
      padding-right: $margin-size-5;
    }
  }

  &t {
    &-0 {
      padding-top: 0;
    }

    &-1 {
      padding-top: $margin-size-1;
    }

    &-2 {
      padding-top: $margin-size-2;
    }

    &-3 {
      padding-top: $margin-size-3;
    }

    &-4 {
      padding-top: $margin-size-4;
    }

    &-5 {
      padding-top: $margin-size-5;
    }
  }

  &b {
    &-0 {
      padding-bottom: 0;
    }

    &-1 {
      padding-bottom: $margin-size-1;
    }

    &-2 {
      padding-bottom: $margin-size-2;
    }

    &-3 {
      padding-bottom: $margin-size-3;
    }

    &-4 {
      padding-bottom: $margin-size-4;
    }

    &-5 {
      padding-bottom: $margin-size-5;
    }
  }
}

.w {
  &-100 {
    width: 100%;
  }

  &-75 {
    width: 75%;
  }

  &-50 {
    width: 50%;
  }

  &-40 {
    width: 40%;
  }

  &-30 {
    width: 30%;
  }

  &-25 {
    width: 25%;
  }

  &-20 {
    width: 20%;
  }

  &-0 {
    width: 0;
  }
}

.d {
  &-flex {
    display: flex;
  }

  &-block {
    display: block;
  }

  &-none {
    display: none !important;
  }
}

.flex {
  &-wrap {
    flex-wrap: wrap;
  }

  &-direction-column {
    flex-direction: column;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}

.justify {
  &-start {
    justify-content: flex-start;
  }

  &-end {
    justify-content: flex-end;
  }

  &-center {
    justify-content: center;
  }

  &-stretch {
    justify-content: stretch;
  }

  &-space-around {
    justify-content: space-around;
  }

  &-space-between {
    justify-content: space-between;
  }

  &-space-evenly {
    justify-content: space-evenly;
  }
}

.align {
  &-start {
    align-items: flex-start;
  }

  &-end {
    align-items: flex-end;
  }

  &-center {
    align-items: center;
  }

  &-stretch {
    align-items: stretch;
  }
}

.border {
  &-top {
    border-top: 1px solid $border-color-4;
  }

  &-right {
    border-right: 1px solid $border-color-4;
  }

  &-bottom {
    border-bottom: 1px solid $border-color-4;
  }

  &-left {
    border-left: 1px solid $border-color-4;
  }

  &-dashed {
    border-style: dashed;
  }

  &-dotted {
    border-style: dotted;
  }

  &-color {
    &-white {
      border-color: $white;
    }

    &-black {
      border-color: $black;
    }

    &-1 {
      border-color: $border-color-1;
    }

    &-2 {
      border-color: $border-color-2;
    }

    &-3 {
      border-color: $border-color-3;
    }

    &-4 {
      border-color: $border-color-4;
    }
  }
}

.input {
  display: inline-block;
  position: relative;
  text-align: start;

  > label {
    display: inline-block;
    margin-left: $margin-size-2;
    color: $darkgrey;
    margin-bottom: $margin-size-1;
  }

  > .input-container {
    .input-validation-error {
      color: $red;
      margin-top: $margin-size-1;
      margin-left: $margin-size-2;
      @extend .text-small;
    }
    > .input-wrap {
      position: relative;
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
      border-radius: 1.5rem;
      outline: none;
      width: 100%;
      border: 1px solid #2e3146;
      @extend .px-2;
      background-color: $lightblue;
      > .info {
        background-color: #20212e;
        margin-left: -0.8rem;
        border-top-left-radius: 19px;
        border-bottom-left-radius: 19px;
        overflow: hidden;
        width: 100%;
        padding: 11px 0.6rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $grey;

        > .left-area {
          margin-left: $margin-size-1;
          display: flex;
          align-items: center;

          .icon {
            margin-right: $margin-size-2;
          }
        }
      }

      > .loading-container {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(0, 0, 0, 0.75);
        top: 0;
        left: 0;
        border-radius: 1.5rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        > .loader {
          margin-right: $margin-size-1;
          border: 2.4px solid white;
          border-top: 2.4px solid $grey;
          border-radius: 50%;
          width: 24px;
          height: 24px;
          animation: spin 2s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }
      }

      .editor {
        word-break: break-all;
        display: inline-block;
        cursor: text;
        background: $lightblue;
        position: relative;
        border: 1px solid $border-color-6;
        box-shadow: none;
        padding: 0 0;
        padding-right: 25px;
        margin: 2px 0;
        outline: none;
        border: none;
        width: 100%;

        .editor-content-length {
          position: absolute;
          bottom: 0;
          right: 30px;
          color: $grey;
          font-size: 0.6rem;

          &.limit {
            color: $red;
          }
        }

        .public-DraftStyleDefault-block {
          line-height: 18px;
          padding: calc(#{$margin-size-2} - 2px) 0;
        }

        .esyutjr {
          display: none;
        }
        .public-DraftEditorPlaceholder-root {
          color: rgb(186, 186, 193);
          margin-bottom: -16px;
          position: absolute;
          pointer-events: none;
          padding: 10px 0px;
        }

        .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
          // bottom: 30px !important;
          // right: 0 !important;
        }

        .mentionUser {
          color: $lightestblue !important;
          cursor: pointer !important;
        }

        .mentionHashtag {
          color: $oired !important;
          cursor: pointer !important;
        }

        .mentionCollectible {
          color: $border-color-7 !important;
          cursor: pointer !important;
        }

        .public-DraftEditor-content {
          max-height: 100px;
          overflow: auto;
          -ms-overflow-style: none; /* Internet Explorer 10+ */
          scrollbar-width: none; /* Firefox */

          &::-webkit-scrollbar {
            display: none; /* Safari and Chrome */
          }
        }

        .mnw6qvm {
          // mention box
          background-color: $lightblue;
          box-shadow: none;
          border-radius: 12px;
          background-color: $black;
          border-radius: 12px;
          border: 1px solid #20212e;

          > div {
            display: flex;
          }

          .mtiwdxc {
            width: 100%;
            background-color: #000;
            padding: 10px 10px;
          }
          .myz2dw1 {
            width: 100%;
            background-color: #191b27;
            padding: 10px 10px;
          }

          .mention-entry-container {
            display: flex;
            align-items: center;

            .slug {
              margin-top: 2px;
              font-size: 12px;
              color: $grey;
            }
          }
        }

        .m126ak5t {
          // mention user hover
          background-color: $bg-color-5;
        }

        .m1ymsnxd {
          // mention user
          padding: 0;
        }

        .m6zwb4v {
          background-color: transparent;
          color: #67a8e4;
        }

        .options {
          position: absolute;
          right: 0;
          bottom: 6px;
          background-color: transparent;

          .e183m4hm {
            font-family: monospace;
            .e8k2yoa {
              // emoji button
              background-color: transparent;
              border: none;
              color: $greyish;
              font-size: 16px;
              width: 1.5rem;
            }

            .e13wqaj6 {
              width: 1.5rem;
              // emoji button hover
              border: none;
              background-color: transparent;
              color: $lightgrey;
              font-size: 16px;
              width: 1.5rem;
            }

            .ejr02pv {
              // emoji box
              box-shadow: none;
              border-color: $bg-color-5;
              transform: translate(-95%, -110%);
              font-size: 13px;
              background-color: $black;
              border-radius: 12px;
              // transform: translate(-96%, -3%) !important;
              border: 1px solid #20212e;
            }
          }

          > .draftJsEmojiPlugin__emojiSelect__34S1B {
            > .draftJsEmojiPlugin__emojiSelectButton__3sPol {
              width: 1.5rem;
            }

            > .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
              width: 1.5rem;
            }

            > .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
              > .draftJsEmojiPlugin__emojiSelectPopoverGroups__35t9m {
                margin: 10px;
                overflow: hidden;

                div:not(.class)::-webkit-scrollbar {
                  display: none;
                  cursor: pointer;
                }

                .draftJsEmojiPlugin__emojiSelectPopoverEntryIcon__1yNaC {
                  cursor: pointer !important;
                }
              }
            }

            > .draftJsEmojiPlugin__emojiSelectButton__3sPol {
              border: none;
              color: white;
              background: $bg-color-2;
            }

            > .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
              background-color: transparent;
              border: none;
              outline: none;
            }

            > .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
              background-color: $bg-color-2;
              box-shadow: none;
              border-radius: 1.5rem;
              border: 1px solid $border-color-6;
            }
          }
        }

        .draftJsEmojiPlugin__emojiSelectPopoverNavItem__qydCX {
          cursor: pointer !important;

          li {
            cursor: pointer !important;
          }
        }

        .draftJsEmojiPlugin__emojiSuggestions__2ffcV {
          // background: $bg-color-2 !important;
          // box-shadow: none !important;
          // border-radius: 1.5rem !important;
          // border: 1px solid $border-color-6 !important;
          // overflow: hidden;
          // position: absolute;
          // left: 0 !important;

          .draftJsEmojiPlugin__emojiSuggestionsEntry__2-2p_ {
            transition: none;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                overflow: hidden;
                background-color: #393c4f;
              }
            }
          }

          .draftJsEmojiPlugin__emojiSuggestionsEntryFocused__XDntY {
            background-color: transparent;
          }
        }

        .mention-info {
          position: absolute;
          top: 18px;
          display: none !important;
          display: flex;
          padding: 5px 10px;
          background: $bg-color-2;
          box-shadow: none;
          border-radius: 1rem;
          border: 1px solid $border-color-6;
          overflow: hidden;
          position: absolute;
          left: 0 !important;
          align-items: center;
          z-index: 999;

          .name {
            font-weight: bold;
            color: white;
          }

          .slug {
            color: $grey;
          }
        }

        .m6zwb4v {
          color: $link-color !important;
          background-color: transparent !important;
          position: relative !important;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              .mention-info {
                position: absolute !important;
                display: flex !important;
                z-index: 999;
              }
            }
          }
        }

        .mnw6qvm {
          box-shadow: none;
          overflow: hidden;
          position: absolute;
          left: 0 !important;
          background-color: $black;
          border-radius: 12px;
          border: 1px solid #20212e;

          .m126ak5t,
          .m1ymsnxd {
            transition: none;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                overflow: hidden;
                background-color: #393c4f;
              }
            }

            > .mention-entry-container {
              display: flex;
              align-items: center;

              .name {
                font-weight: bold;
              }

              .slug {
                color: $grey;
              }

              .actor-follow-state {
                font-size: $text-size-smallest;
                color: $lightgrey;
                padding: 2px 4px;
                border-radius: 10px;
                border: solid 0.5px $border-color-1;
                margin: 4px 0;
                position: relative;
                width: max-content;
              }
            }

            > .hashtag-entry-container {
              display: flex;
              flex-direction: column;

              .name {
                font-weight: bold;
              }

              .count {
                color: $grey;
                font-size: $text-size-small;
              }
            }
          }

          .draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
            background-color: transparent;
          }
        }
      }

      > input {
        display: inline-block;
        width: 100%;
        outline: none;
        border: none;
        background: transparent;
        color: white;
        padding-top: calc(#{$margin-size-2} - 2px);
        padding-bottom: calc(#{$margin-size-2} - 2px);
        margin-top: 2px;
        margin-bottom: 2px;

        &::placeholder,
        &:-ms-input-placeholder,
        ::-ms-input-placeholder {
          color: $grey;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px $lightblue inset;
          -webkit-text-fill-color: $white;
        }
      }

      > textarea {
        display: inline-block;
        width: 100%;
        outline: none;
        border: none;
        background: transparent;
        color: white;
        resize: none;
        padding-top: calc(#{$margin-size-2} - 2px);
        padding-bottom: calc(#{$margin-size-2} - 2px);
        margin-top: 2px;
        margin-bottom: 2px;

        &::placeholder,
        &:-ms-input-placeholder,
        ::-ms-input-placeholder {
          color: $grey;
        }

        &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0px 1000px $lightblue inset;
          -webkit-text-fill-color: $white;
        }
      }

      > .characterLimit {
        font-size: 12px;
        position: absolute;
        bottom: 4px;
        right: 8px;
      }
    }
  }

  > .input-has-buttons {
    > .input-container {
      display: flex;
    }
  }

  > .input-buttons-align-left {
    > .input-container {
      > .input-wrap {
        @extend .ml-1;
        order: 4;
      }
    }
  }

  > .input-buttons-align-right {
    > .input-container {
      > .input-wrap {
        @extend .mr-1;
        order: 0;
      }
    }
  }

  > .input-validation-error {
    color: $red;
    margin-top: $margin-size-1;
    margin-left: $margin-size-2;
    @extend .text-small;
  }

  &.input-password {
    > .input-container {
      > .input-wrap {
        > input {
          @extend .pr-5;
        }

        > .icon {
          @extend .px-2;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &.input-text {
    > .input-container {
      > .input-wrap {
        > input {
        }

        > .icon {
          @extend .px-2;
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &.input-money {
    > .input-validation-error {
      display: none;
    }

    > .input-container {
      > .input-wrap {
        > input {
          text-align: right;
        }

        > input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }

  &.input-comment {
    > .input-container {
      > .input-wrap {
        background-color: #20212e;

        > .editor {
          background-color: transparent;

          > .options {
            > .draftJsEmojiPlugin__emojiSelect__34S1B {
              > .draftJsEmojiPlugin__emojiSelectButton__3sPol {
                background-color: transparent;
              }
            }
          }
        }

        > input {
          text-align: right;
        }
      }
    }

    &.input-focused {
      > .input-container {
        > .input-wrap {
          box-shadow: none;
          border: none;
          border: 1px solid $border-color-6;
        }
      }
    }
  }

  &.input-select {
    cursor: default;

    > .input-container {
      .input-wrap {
        @extend .px-0;

        &-disabled {
          background-color: #20212e;
        }

        .react-select {
          .css-1n7v3ny-option {
            background-color: $bg-color-1;
            cursor: pointer;
            color: white;
          }
          .react-select__control {
            background: transparent;
            border: none;
            box-shadow: none;

            .react-select__value-container {
              .react-select__placeholder {
                color: $grey;
              }

              .react-select__single-value {
                color: $off-white-1;
              }

              .css-b8ldur-Input {
                color: $white;
              }
            }

            .react-select__indicators {
              .react-select__indicator-separator {
                display: none;
                background-color: $darkgrey;
              }

              .react-select__indicator {
                color: $darkgrey;
              }
            }
          }

          .react-select__menu {
            z-index: 1000;
            background-color: $bg-color-2;
            color: $lightgrey;

            .react-select__menu-list {
              .react-select__option {
                &.react-select__option--is-selected {
                  background-color: adjust-color($bg-color-2, $lightness: -4%);
                }

                &.react-select__option--is-focused {
                  background-color: adjust-color($bg-color-2, $lightness: -8%);
                }

                &.react-select__option--is-focused.react-select__option--is-selected {
                }
              }
            }
          }
        }

        > .searchable-select {
          .no-result-container {
            cursor: pointer;
            opacity: 1;
            text-align: center;

            > .text {
              font-size: $text-size-medium;
            }

            > .subtext {
              font-size: $text-size-small;
              letter-spacing: 0.04rem;
            }
          }

          > .react-select__control {
            background: transparent;
            border: none;
            box-shadow: none;

            > .react-select__value-container {
              .react-select__placeholder {
                color: $grey;
              }

              .react-select__single-value {
                color: $off-white-1;
              }
            }

            > .react-select__indicators {
              > .react-select__indicator-separator {
                background-color: $darkgrey;
              }

              > .react-select__indicator {
                color: $darkgrey;
              }
            }
          }

          .green {
            color: $border-color-7;
          }

          > .react-select__menu {
            background-color: $bg-color-2;
            color: $lightgrey;
            border-radius: 1.5rem;

            hr {
              background-color: #6d6f7e;
            }

            .react-select__option.react-select__option--is-focused {
              background-color: transparent;
            }

            .react-select__menu-list {
              .react-select__option {
                cursor: pointer;
                color: white;

                &.react-select__option--is-selected {
                  background-color: transparent;
                }

                &.react-select__option--is-focused {
                  background-color: adjust-color($bg-color-2, $lightness: -8%);
                }

                &.react-select__option.react-select__option--is-focused {
                  background-color: transparent;
                }

                &.react-select__option--is-focused.react-select__option--is-selected {
                }
              }
            }
          }
        }
      }
    }

    &.input-dark {
      > .input-container {
        > .input-wrap {
          border: none;
          border-right: 1px solid $bg-color-2;
          border-radius: 0;
          background-color: $bg-color-1;
          color: $white;
          box-shadow: none;

          > .react-select {
            > .react-select__control {
              > .react-select__value-container {
                .react-select__placeholder {
                  color: $grey;
                }
              }

              > .react-select__indicators {
                > .react-select__indicator-separator {
                  display: none;
                }

                > .react-select__indicator {
                  color: $grey;
                }
              }
            }
          }
        }
      }
    }
  }

  &.input-radio {
    > .MuiRadio-colorSecondary.Mui-checked {
      color: $oiblue;
    }

    > .MuiRadio-root {
      color: #2e3146;
    }
  }

  &.input-switch {
    cursor: default;

    > .input-container {
      > .input-wrap {
        @extend .px-0;

        .react-switch-checkbox {
          height: 0;
          width: 0;
          visibility: hidden;
        }

        .react-switch-label {
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          width: 50px;
          height: 25px;
          background: #707070;
          border-radius: 100px;
          position: relative;
          transition: background-color 0.2s;
        }

        .react-switch-label .react-switch-button {
          content: '';
          position: absolute;
          top: 0px;
          left: 0px;
          width: 25px;
          height: 25px;
          border-radius: 45px;
          transition: 0.2s;
          background: #fff;
          box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
        }

        .react-switch-checkbox:checked + .react-switch-label .react-switch-button {
          left: calc(100% - 0px);
          transform: translateX(-100%);
        }

        .react-switch-label:active .react-switch-button {
          width: 60px;
        }
      }
    }
  }

  &.input-checkbox {
    > .input-container {
      padding: 0;
      border: none;

      > .input-wrap {
        padding: 0;
        border: none;
        background-color: transparent;

        > .MuiCheckbox-root {
          color: $oiblue;
        }

        > .MuiButtonBase-root {
          padding: 0;
        }
      }
    }

    &.primary {
      > .input-container {
        > .input-wrap {
          > .MuiCheckbox-root {
            color: $oigreen;
          }
        }
      }
    }

    &.secondary {
      > .input-container {
        > .input-wrap {
          > .MuiCheckbox-root {
            color: $oired;
          }
        }
      }
    }

    &.input-focused {
      > .input-container {
        > .input-wrap {
          border: none;
        }
      }
    }
  }

  &.input-focused {
    > .input-container {
      > .input-wrap {
        border: 1px solid $border-color-6;
      }
    }
  }
  &.read-only {
    > .input-container {
      > .input-wrap {
        background-color: #20212e;
        border: 1px solid #20212e;

        > input {
          color: #aaaaaa;
        }
      }
    }
  }
}

.comment-editor {
  > .input-container {
    > .input-wrap {
      > .post-button {
        cursor: pointer;
      }
    }
  }
}

.search {
  > .input-container {
    > .input-wrap {
      > .icon {
        padding-right: $margin-size-1;
        color: #606060;
        cursor: pointer;
      }
    }
  }
}

.form {
  > .input {
    width: 100%;
    @extend .my-1;
  }
}

.icon {
  user-select: none;
}

.layout {
  min-height: calc(100vh - 72px);
  display: flex;
  flex-flow: column nowrap;

  > #app-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    z-index: 9;
    height: $appheaderHeight;
    width: 100%;

    > .header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: 0px 8px;
    }

    > .no-sidebar {
      padding: 0px 16px;
    }

    > .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      cursor: pointer;
      color: #52598b;
      min-width: 70px;
      height: 40px;

      @media (max-width: 656px) {
        margin: 0 0 0 0;
      }
    }
    .left {
      width: 210px;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      .logo {
        img {
          width: 100px;
        }
      }
    }

    .center {
      display: flex;
      flex: 0 1 948px;
      flex-direction: row;
      align-items: center;

      .global-search {
        flex: 1;
        margin: 0 24px;
        position: relative;
        display: flex;
        justify-content: center;

        .icon-container {
          display: flex;
          align-items: center;

          i {
            color: grey;
          }
        }

        .input-suffix {
          margin-left: 0;
        }

        .clear-icon-container {
          cursor: pointer;
          font-size: 14px;
          visibility: hidden;
          width: 37px;
          height: 37px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: -0.8rem;
          -webkit-border-top-right-radius: 10px;
          -webkit-border-bottom-right-radius: 10px;
          -moz-border-radius-topright: 10px;
          -moz-border-radius-bottomright: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;

          i {
            color: grey;
          }

          &.visible {
            visibility: visible;
          }
        }

        .input-validation-error {
          display: none;
        }

        > .history-box {
          border: 1px solid $border-color-6;
          position: absolute;
          top: 46px;
          width: 100%;
          border-radius: 20px;
          background-color: $bg-color-2;
          height: auto;
          
          .recently {
            color: #9798a3;
            padding: $margin-size-2 $margin-size-2;
          }
          
          .recently-searched-accounts {
            display: flex;
            align-items: center;
            gap: 20px;
            margin: 0 $margin-size-2;
            height: 60px;

            > .content-wrapper {
              display: flex;
              align-items: center;
              gap: 10px;
              width: 100%;
              cursor: pointer;

              .profile-avatar {
                > img, .placeholder {
                  border: 1px solid;
                  border-color: #00B7FF !important;
                }
              }
              
              .account-info {
                .account-name {
                  color: $white;
                  font-size: 16px;
                  font-weight: bold;
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis; 
                  -o-text-overflow: ellipsis;
                  max-width: 120px;
                }

                .account-slug {
                  color: rgba(#ffffff, 0.5);
                  font-size: 16px;
                }
              }

              .recently-account-icon {
                display: none;
              }
            }
          }

          i {
            color: #9798a3;
          }

          .history-item {
            padding: 0 $margin-size-2;
            color: $white;
            height: 60px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > .history-item-left-content {
              display: flex;
              align-items: center;
              .search-icon-container {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(#ffffff, 0.1);
                margin-right: $margin-size-2;
              }
            }

            > .history-item-right-content {
              rotate: 45deg;
            }

            &.hashtag {
              color: $oiblue;
            }

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: rgba(#000000, .1);
              }
            }
          }

          .search-item {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 $margin-size-2;
            cursor: pointer;

            &:hover {
              background-color: rgba(#000000, .1);
            }

            > .left-side {
              display: flex;
              align-items: center;
              gap: 10px;

              .hashtag-icon {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid $white;
                border-radius: 50%;
                
                i {
                  color: $white;
                  font-size: 16px;
                }
              }

              .content {
                .name {
                  font-size: 16px;
                  font-weight: bold;
                  color: $white;
                }

                .category {
                  font-size: 16px;
                  color: #00B7FF;
                }

                .slug {
                  color: rgba(#ffffff, .5);
                }
              }

              path {
                fill: #9798a3;
              }

              &.square {
                .profile-avatar {
                  > img {
                    border: none;
                    border-radius: 5px;
                  }

                  > .placeholder {
                    border: 1px solid;
                    border-radius: 5px;
                  }
                }
              }

              &:not(.square) {
                .profile-avatar {
                  > img, .placeholder {
                    border: 1px solid;
                    border-color: #00B7FF !important;
                  }
                }
              }
            }

            > .right-side {
              display: flex;
              align-items: center;
              gap: 5px;
              color: rgba(#ffffff, 0.5);
              visibility: hidden;
            }

            &:hover {
              > .right-side {
                visibility: visible;
              }
            }
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 210px;
      .home-link {
        display: flex;
        flex-flow: row nowrap;
        font-size: 20px;
        align-items: flex-end;

        img {
          height: 40px;
        }
      }

      .button-group {
        flex-wrap: nowrap;

        .button {
          margin-right: 0;
        }
      }

      .discover-link {
        color: white;
      }

      > .user-menu {
        > .search-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: $bg-color-2;
          color: white;
        }
      }

      @media screen and (max-width: 340px) {
        .discover-link {
          display: none;
        }
      }

      .loading-account {
        padding-right: 8px;
        display: flex;
        align-items: center;
        height: 40px;
      }
      .loading-placeholder {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        cursor: pointer;
        height: 40px;
        .balance {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: bold;
          margin-right: -10px;
          padding: 0 12px;
          padding-right: 18px;
          height: 30px;
          min-width: 90px;
          max-width: 200px;
          border-top: 1px solid #2e3146;
          border-bottom: 1px solid #2e3146;
          border-left: 1px solid #2e3146;
          border-top-left-radius: 40px;
          border-bottom-left-radius: 40px;
        }
      }
      .connect-account {
        //padding-right: 8px;
        > .connect-containerr {
          width: 164px;
          height: 40px;
          border-top-right-radius: 25px;
          border-top-left-radius: 25px;
          border-bottom-right-radius: 25px;
          border-bottom-left-radius: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-image: linear-gradient(90deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);

          > .connect-inner {
            border-top-right-radius: 25px;
            border-top-left-radius: 25px;
            border-bottom-right-radius: 25px;
            border-bottom-left-radius: 25px;
            background-color: $bg-color-1;
            width: 158px;
            height: 34px;
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: $bg-color-2;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: $media-break-sm) {
      padding: 0 16px;
      .balance {
        // display:none;
      }
      .header-content {
        margin-left: 0;
        padding-left: 0;
        padding: 0;
      }
      > .icon-container {
        min-width: 40px;
        padding-right: 10px;
      }

      .left {
        width: max-content;
      }
      .center {
        display: none;
      }
      .right {
        width: max-content;
        > .user-menu {
          > .connect-account {
            > .connect-containerr {
              width: 115px;
              > .connect-inner {
                width: 109px;
              }
            }
          }
        }
      }
      .connect-wallet {
        display: none;
      }
    }
  }

  > #app-content {
    flex-grow: 1;
    z-index: 9;
    width: 100%;
  }

  > #app-footer {
    margin-top: $margin-size-4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9;

    > .footer-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 $margin-size-2;

      .community-section {
        width: 95%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: $margin-size-4 0;

        > .social-media {
          p {
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 31px 0;
          }

          .icons {
            display: flex;
            gap: 15px;

            a {
              width: 50px;
              height: 50px;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(#ffffff, 0.1);
              color: white;

              i {
                font-size: 24px;
              }
            }
          }
        }

        > .subscribe {
          p {
            font-size: 18px;
            font-weight: bold;
            margin: 0 0 33px 0;
          }

          > .sub-input {
            display: flex;
            align-items: center;
            gap: 16px;

            .input-container {
              width: 100%;
              position: relative;

              .input {
                width: 418px;
                font-size: 16px;
              }

              p {
                position: absolute;
                bottom: -25px;
                left: 0;
                font-size: 12px;
                color: red;
              }
            }

            .button {
              height: 37px;
              width: 150px;
              font-size: 16px;
            }
          }

          > .send-email-message {
            font-size: 14px;
            color: rgba(#ffffff, 0.5);
            margin-top: $margin-size-2;
          }
        }

        > hr {
          display: none;
        }
      }

      > .oix-info {
        width: 95%;
        height: 280px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: $margin-size-4 0;

        > .oix-text {
          width: 447px;
          color: rgba(#ffffff, 0.5);
          line-height: 24px;
          font-size: 18px;

          img {
            width: 100px;
            margin-bottom: 29px;
          }
        }

        > .oix-menus {
          display: flex;
          justify-content: space-between;
          gap: $margin-size-3;

          > .menus {
            height: 100%;
            margin-left: $margin-size-5;

            p {
              font-size: 18px;
              font-weight: bold;
              margin: 0 0 35px 0;
            }

            div {
              display: flex;
              flex-direction: column;
              gap: 16px;
              font-size: 18px;

              .send-feedback {
                color: rgba(#ffffff, 0.5);
                cursor: pointer;
              }

              a {
                color: rgba(#ffffff, 0.5);
                cursor: pointer;
              }
            }
          }
        }
      }

      > .footer-content {
        height: 80px;
        width: 95%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        font-size: 18px;

        hr {
          display: none;
        }

        .menu {
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          gap: 18px;

          a {
            color: rgba(255, 255, 255, 0.5);
          }

          .lang {
            color: rgba(255, 255, 255, 0.5);
            cursor: pointer;
            gap: $margin-size-1;

            i:first-child {
              font-size: 24px;
            }
          }

          > .menu-item {
            display: flex;
            align-items: center;
            white-space: nowrap;
            @extend .mx-1;
          }
        }

        .social-links {
          display: flex;
          align-items: center;
          color: #828389;
          a {
            color: #828389;
            font-size: 16px;
            @extend .mr-2;
          }
        }
      }

      @media screen and (max-width: $media-break-lg) {
        > .community-section {
          > .subscribe {
            width: 40%;

            > .sub-input {
              flex-direction: column;

              .input-container {
                .input {
                  width: 100%;
                  font-size: 14px;
                }
              }

              .button {
                width: 100%;
                font-size: 14px;
              }
            }
          }
        }

        > .oix-info {
          > .oix-menus {
            width: 80%;
          }
        }

        > .footer-content {
          flex-direction: column;
          margin: 30px 0;
          height: auto;

          > .menu {
            gap: 8px;
            > .menu-item {
              height: auto;
              font-size: 14px;
            }
          }

          > .social-links {
            margin-top: $margin-size-3;
            font-size: 14px;
          }
        }
      }

      @media screen and (max-width: $media-break-md) {
        > .community-section {
          flex-direction: column;
          height: 340px;

          > .social-media {
            width: 100%;
            margin-bottom: $margin-size-3;
          }

          > .subscribe {
            width: 100%;

            > .sub-input {
              flex-direction: column;

              .input-container {
                .input {
                  width: 100%;
                  font-size: 14px;
                }
              }

              .button {
                width: 100%;
                font-size: 14px;
              }
            }
          }

          > hr {
            display: initial;
          }
        }

        > .oix-info {
          margin: $margin-size-2 0;
          height: 450px;

          > .oix-text {
            display: none;
          }

          > .oix-menus {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
            gap: 47px;

            > .menus {
              margin-left: 0;

              p {
                font-size: 24px;
              }
            }
          }
        }

        > .footer-content {
          flex-direction: column;
          margin: 30px 0;
          height: auto;

          hr {
            display: initial;
            margin: 20px 0;
          }

          > .menu {
            flex-direction: column;
            gap: 15px;

            .menu-item {
              margin: 0;
            }

            .lang {
              margin-top: 37px;
            }
          }

          > .social-links {
            margin-top: $margin-size-2;
            font-size: 14px;
          }
        }
      }
    }
  }

  &.without-header {
    margin-top: 0 !important;
  }

  &.has-sidebar {
    > #app-content {
      width: calc(100% - #{$sidebarWidth});
      margin-left: $sidebarWidth;
    }

    > #app-footer {
      width: calc(100% - #{$sidebarWidth});
      margin-left: $sidebarWidth;
    }

    > #app-sidebar {
      display: flex;
      position: fixed;
      left: 0;
      top: $appheaderHeight;
      height: calc(100vh - #{$appheaderHeight});
      width: $sidebarWidth;
      z-index: 90;
      background-color: #191b26;

      .sidebar {
        width: 100%;

        hr {
          width: 90%;
          margin: $margin-size-1 auto;
        }

        a {
          color: white;
          font-size: $text-size-medium;
        }

        a.active {
          color: $oiblue;

          .swap_svg__swap-svg {
            path {
              fill: $oiblue;
            }
          }

          i {
            color: white;
          }
        }

        @media (hover: hover) and (pointer: fine) {
          a:hover {
            background-color: #12141d;
          }
        }

        > .sidebar-wrap {
          overflow: auto;
          border-right: 1px solid $hr-color;
          height: 100%;

          > .sidebar-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;

            .borderless-icon-container {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 20px;
              height: 20px;
              margin-right: $margin-size-2;

              .icon {
                font-size: 16px;
              }
            }

            .notification {
              position: relative;
              > .point {
                position: absolute;
                top: 0;
                right: -5px;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: #ff009e;
              }
            }

            .swap-icon {
              width: 20px;
              height: 20px;
            }

            .icon-container {
              height: 24px;
              width: 24px;
              border: 1px solid white;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 0.3rem;
              margin-right: $margin-size-2;

              .icon {
                font-size: 12px;
                margin: 0;
              }
            }

            .menu-dropdown {
              padding: $margin-size-2 $margin-size-3;
              display: flex;
              justify-content: space-between;
              align-items: center;

              > .cell-1 {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $text-size-medium;
              }

              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  background-color: #12141d;
                  cursor: pointer;
                }
              }
            }

            a.active {
              i {
                color: $oiblue;
              }
            }

            .menu-item {
              display: flex;
              align-items: center;
              padding: $margin-size-2 $margin-size-3;

              .active {
                color: red;
              }

              & .sub {
                margin-left: 24px;
                height: 22px;
                display: flex;
                align-items: center;
                color: #8c8d93;
                padding-left: $margin-size-2;
              }
            }

            > .top-content {
              > .header {
                display: none;
              }

              > .menu-links {
                margin-top: $margin-size-2;
              }

              .library-menu {
                > .menu-item {
                  padding: $margin-size-1 $margin-size-3;
                }
              }

              .create-box {
                height: 40px;
                border: none;
                background-color: $bg-color-1;
                position: relative;
                overflow: hidden;
                width: 80%;
                > .box-wrap {
                  width: calc(100% - 6px);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: $bg-color-1;
                  height: 34px;
                  border-radius: 17px;
                  z-index: 100;

                  @media (hover: hover) and (pointer: fine) {
                    &:hover {
                      background-color: $bg-color-2;
                      cursor: pointer;
                    }
                  }
                }

                > .border {
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  border-radius: 20px; /* !importanté */
                  background: linear-gradient(90deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
                }

                @media screen and (max-width: $media-break-sm) {
                  & {
                    width: 100%;
                  }
                }
              }
            }

            > .bottom-content {
              color: #8c8d93;
              font-size: 14px;
              > .settings-links {
                display: flex;
                flex-direction: column;
              }

              a {
                color: #8c8d93;
              }

              > .bottom-info {
                margin-top: $margin-size-2;
                margin-left: $margin-size-3;
                display: flex;
                flex-direction: column;
                color: #8c8d93;
                a {
                  cursor: pointer;
                }
              }

              > .love {
                margin: $margin-size-3 0 $margin-size-2 $margin-size-3;

                > .subtext {
                  font-size: 12px;
                  color: $grey;
                }

                i {
                  margin: 0;
                }

                > .text {
                  font-size: $text-size-small;
                  color: white;
                }
              }
            }
          }

          &::-webkit-scrollbar {
            // TODO: We should change this with default scrollbar of app when we have it
            display: none;
          }
        }
      }

      .click-block {
        display: none;
        position: fixed;
        left: $sidebarWidthWide;
        top: 0;
        background-color: rgba(0, 0, 0, 0.35);
        height: 100%;
        width: 100%;
        z-index: 90;
      }

      &.sidebar-small {
        > .sidebar {
          a.active {
            i {
              color: $oiblue;
            }
          }

          > .sidebar-wrap {
            > .sidebar-content {
              a {
                margin-top: $margin-size-1;
                font-size: $text-size-smaller;
              }

              > .top-content {
                > .profile {
                  display: none;
                }

                > .menu-links {
                  width: 100%;
                  margin: 0px;

                  .borderless-icon-container {
                    margin: 0;
                  }

                  > .menu-item {
                    padding: 0;
                    flex-direction: column;
                    margin: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 70px;
                  }

                  > .menu-dropdown {
                    padding: 0;

                    > .cell-1 {
                      font-size: $text-size-smaller;
                      width: 100%;
                      height: 70px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                    }

                    > .borderless-icon-container {
                      display: none;
                    }
                  }
                }

                .settings-links {
                  display: none;
                }

                > .library-menu {
                  display: none;
                }
              }

              > .bottom-content {
                display: none;
              }
            }
          }
        }
      }
    }

    &.sidebar-open {
      > #app-sidebar {
        width: $sidebarWidthWide;
      }

      > #app-content {
        width: calc(100% - #{$sidebarWidthWide});
        margin-left: $sidebarWidthWide;
      }

      > #app-footer {
        width: calc(100% - #{$sidebarWidthWide});
        margin-left: $sidebarWidthWide;
      }
    }

    @media screen and (max-width: $media-break-xl) {
      &.sidebar-open {
        > #app-sidebar {
          width: $sidebarWidthWide;

          > .click-block {
            display: block;
          }
        }

        > #app-content {
          width: calc(100% - #{$sidebarWidth});
          margin-left: $sidebarWidth;
        }

        > #app-footer {
          width: calc(100% - #{$sidebarWidth});
          margin-left: $sidebarWidth;
        }
      }
    }

    @media screen and (max-width: $media-break-md) {
      > #app-content {
        width: 100%;
        margin-left: 0;
      }

      > #app-footer {
        width: 100%;
        margin-left: 0;
      }

      > #app-sidebar {
        &.sidebar-small {
          display: none;
        }
      }

      &.sidebar-open {
        > #app-content {
          width: 100%;
          margin-left: 0;
        }
      }
    }

    @media screen and (max-width: $media-break-sm) {
      &.sidebar-open {
        > #app-sidebar {
          width: 100%;

          > .click-block {
            display: none;
          }
        }
      }
    }
  }
}

.content-container {
  @extend .mx-auto;
  width: 93.5%;

  @media screen and (min-width: $media-break-sm) {
    width: 93.5%;
  }

  @media screen and (min-width: $media-break-md) {
    width: 720px;
  }

  @media screen and (min-width: $media-break-lg) {
    width: 900px;
  }
}

.profile-avatar {
  width: 120px;
  height: 120px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > .placeholder {
    border: 2px solid $white;
    border-radius: 50%;
    background-color: #2e3146;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }

  > img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 2px solid;
    border-radius: 50%;
    object-fit: cover;
    overflow: hidden;
    background-color: #2e3146;
  }
}

#app-header .user-menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  > .icon-hold {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-wrap {
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      position: relative;

      .icon-white-area {
        transition: 0.2s;
        background-color: #191b27;
        border-radius: 50%;
        width: 34px;
        height: 34px;
        display: flex;
        align-content: center;
        justify-content: center;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: lighten(#191b27, 10%);
          }
        }
      }

      i {
        color: white;
        font-size: 15px;
        margin: auto;
        z-index: 10;
      }
    }
  }

  .content-upload-btn {
    border: 2px solid $border-color-3;
    border-radius: 50%;
    font-size: 16px;
    padding: 8px;

    i {
    }
  }

  .profile-wrap {
    //padding-right: 8px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    cursor: pointer;

    .balance {
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      margin-right: -10px;
      padding: 0 12px;
      padding-right: 18px;
      height: 30px;
      min-width: 90px;
      max-width: 200px;
      border-top: 1px solid #2e3146;
      border-bottom: 1px solid #2e3146;
      border-left: 1px solid #2e3146;
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
    }

    .profile-avatar {
      $size: 48px;
      width: $size;
      height: $size;
    }

    .fa-ellipsis-v {
      font-size: 16px;
      color: $white;
    }
  }
}

.social-buttons {
  display: flex;
  flex-flow: row wrap;
  margin: $margin-size-2 0;
  color: #8c8d93;

  > a {
    color: #8c8d93;
    @extend .m-1;

    .icon {
      font-size: 24px;
    }
  }
}

.create-box {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed $lightblue;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
  height: 100%;

  > .hight-decider {
    width: 100%;
    padding-bottom: calc(100% + 80px);
  }

  > .drop-area {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .icon-hold {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .icon-wrap {
        height: 100px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        position: relative;

        .icon-white-area {
          background-color: #191b27;
          border-radius: 50%;
          width: 85px;
          height: 85px;
          display: flex;
          align-content: center;
          justify-content: center;
        }

        i {
          font-size: 38px;
          margin: auto;
          z-index: 10;
          color: $white;
        }
      }

      p {
        color: $white;
        font-size: 12px;
      }
    }
  }

  &.collection {
    > .drop-area {
      .icon-hold {
        .icon-wrap {
          background-image: linear-gradient(0deg, #ff7400 0%, #ff00eb 50.62%, #7167ff 100%);
        }
      }
    }
  }
}

.upload-box {
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;

  .current-box {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .chart {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .count {
        margin-top: $margin-size-1;
        position: absolute;
        font-size: $text-size-biggest;
      }

      .icon {
        font-size: 45px;
      }
    }
  }

  > .drop-area {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;

    .icon-hold {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-wrap {
        height: 161px;
        width: 161px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
        background-origin: border-box;
        background-clip: content-box, border-box;
        position: relative;

        .icon-white-area {
          background-color: #191b27;
          border-radius: 50%;
          width: 147px;
          height: 147px;
          display: flex;
          align-content: center;
          justify-content: center;
        }

        i {
          font-size: 58px;
          margin: auto;
          z-index: 10;
        }
      }
    }

    .processing {
      display: none;
    }

    &[data-isdragging='true'] {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  > .error-box {
    background: adjust-color($black, $alpha: -0.8);
    width: 100%;
    height: 100%;
    display: none;

    .error-title {
      color: $red;
      border-bottom: 1px solid $red;
    }
  }

  &[data-isprocessing='true'] {
    > .drop-area {
      .icon-hold {
        @keyframes spin_001 {
          100% {
            transform: rotate(360deg);
          }
        }

        i.icon {
          animation: spin_001 1500ms ease-in-out infinite;
        }
      }

      .choose-file-btn {
        display: none;
      }

      .processing {
        display: block;
      }
    }
  }

  &[data-isshowingerrors='true'] {
    > .drop-area {
      display: none;
    }

    > .error-box {
      display: block;
    }
  }
}

.progress-bar {
  width: 100%;

  .pb-rail {
    width: 100%;
    height: $progress-bar-height;
    background-color: $white;
    border-radius: $progress-bar-height;
    overflow: hidden;

    .pb-fill {
      height: $progress-bar-height;
      background-color: $border-color-2;
      transition: width ease-out 200ms;
    }
  }
}

.tab-panel {
  > .tab-line {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > .content-url {
      font-size: 0.8rem;

      > .icon {
        cursor: pointer;
      }
    }

    .tab-menu-container {
      .tab-item {
        &.button-tab {
          background-color: transparent;
          color: $grey;
          min-width: unset;
          transition: color linear 200ms;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: $lightgrey;
            }
          }

          &.active {
            color: $link-color;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: $link-hover-color;
              }
            }
          }
        }
      }
    }
  }

  > .tab-content-container {
    > .tab-content-wrapper {
      display: none;

      &[data-active] {
        display: block;
      }

      > .tab-content {
      }
    }
  }
}

.collectible-card {
  $detail-height: 50px;
  $ratio: 100;
  width: 100%;

  box-sizing: content-box;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid $border-color-4;

  .height-decider {
    padding-bottom: calc(#{$ratio}% + 0px);
  }

  .collectible-top-container {
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer;

    .collectible-image,
    .collectible-video > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .collectible-cover-image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .collectible-topbar {
      @extend .text-smaller;
      width: 100%;
      height: 35px;
      position: absolute;
      top: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.2);
      background: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);

      > .collectible-icon {
        > .collectible-favorite {
          font-size: 12px;
        }

        > .collectible-seen {
        }
      }

      .collectible-more {
        font-size: 16px;
      }
    }

    .price-container {
      @extend .text-smaller;
      width: 50px;
      height: 30px;
      position: absolute;
      bottom: $margin-size-1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 5px 5px 0;
    }

    .collectible-icon {
      @extend .text-small;
      width: 25px;
      height: 25px;
      position: absolute;
      bottom: $margin-size-2;
      right: $margin-size-2;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px 0px 2px 0px $white inset;
      border: 1px solid $lightgrey;
      text-shadow: 0px 0px 3px $black;
    }
  }

  .collectible-detail {
    height: $detail-height;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    z-index: 100;

    .collectible-title {
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .collectible-category {
      @extend .text-small;
      color: $oiblue;
      margin-top: 4px;
    }
  }
}

.feature-card {
  $detail-height: 50px;
  $ratio: 0.75 * 100;
  width: 100%;
  box-sizing: content-box;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column nowrap;

  .height-decider {
    padding-bottom: calc(#{$ratio}% + #{$detail-height});
  }

  .feature-icon {
    font-size: 24px;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    // box-shadow: 0px 0px 2px 0px $white inset;
    border: 2px solid #fff;
    text-shadow: 0px 0px 3px $black;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: calc(50% - 25px);
    right: calc(50% - 25px);
  }

  .feature-text {
    font-size: 18px;
    width: 200px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 60%;
    right: calc(50% - 100px);
  }

  .feature-container {
    width: 100%;
    height: calc(100%);
    position: absolute;
    cursor: pointer;

    .feature-image {
      // filter: brightness(70%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.collectible-card-detailed {
  overflow: hidden;
  border-radius: 25px;
  background-color: $bg-color-2;

  .card-headline {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin: $margin-size-1 $margin-size-2;
    padding: $margin-size-1 0;
    background-color: $bg-color-2;
    border-bottom: solid 1px $hr-color;
    font-size: $text-size-small;
    color: #9798a3;
    font-size: 14px;
    .followees-actions {
      display: flex;
      align-items: center;
    }
    i,
    a {
      color: #9798a3;
      font-size: 14px;
    }

    .headline-icon,
    svg {
      path {
        fill: #9798a3;
      }
      margin: 0 2px;
      width: 22px;
      height: 17px;
      transform: scale(0.85);
    }

    .icon {
      margin-left: $margin-size-1;
      margin-right: 4px;
    }
  }

  .card-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: $margin-size-2 $margin-size-2;
    background-color: $bg-color-2;

    > .collectible-more {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      transition: 0.5s;
      font-weight: 100;
      color: #85868c;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          cursor: pointer;
          background-color: #434659;
          color: white;
        }
      }
    }

    > .info {
      flex-grow: 1;
      margin: 0 $margin-size-1;
      margin-left: 10px;

      .category {
        color: $oiblue;
        font-size: 14px;
      }

      .time {
        color: #9798a3;
        font-size: 12px;
      }

      > .account-name {
        color: $white;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 600;

        > .name {
          display: flex;
          margin-right: $margin-size-2;
          font-size: 16px;
          font-weight: 600;
          cursor: pointer;

          > .profile-name {
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: $media-break-sm) {
              max-width: 150px;
            }
          }

          > .slug {
            color: $grey;
            font-size: 14px;
            font-weight: 500;
            margin-left: 6px;
            max-width: 100px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: $media-break-sm) {
              max-width: 50px;
            }
          }

          > .date {
            margin-left: 6px;
            color: $grey;
            font-size: 14px;
            font-weight: 500;
            white-space: nowrap;
          }
        }

        > .follow {
          > .follow-button {
            font-size: 14px;
            color: #00b7ff;
            cursor: pointer;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: lighten(#00b7ff, 10%);
              }
            }
          }

          > .following-button {
            font-size: 14px;
            cursor: pointer;
            color: #8d8f9b;
            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: lighten(#8d8f9b, 10%);
              }
            }
          }
        }
      }
    }
  }

  .collectible-preview {
    max-height: 300px;
    min-height: 250px;
    background-color: $black;
    position: relative;
    text-align: center;

    .collectible-image {
      max-height: 300px;
      min-height: 250px;
      max-width: 100%;
    }

    .collectible-icon {
      position: absolute;
      right: $margin-size-2;
      bottom: $margin-size-2;
    }
  }

  .card-footer-1 {
    background-color: $bg-color-2;
    margin: 0 $margin-size-2;
    padding: $margin-size-2 0;
    display: flex;
    font-size: $text-size-bigish;
    justify-content: space-between;
    border-bottom: solid 1px $hr-color;

    > .description {
      > .collectible-title {
        cursor: pointer;
      }

      > .collectible-title {
        font-size: 14px;
      }

      > .collectible-category {
        font-size: 14px;
        color: $oiblue;
        cursor: pointer;
        width: max-content;
        margin-top: 2px;
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: lighten($lightestblue, 10%);
          }
        }
      }
    }

    > .collectible-shopping-cart-button {
      height: 32px;
      min-width: 97px;
      max-width: 130px;
      border-radius: 16px;
      border: 2px solid #6d6f7e;
      padding: $margin-size-1 $margin-size-3;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
  }

  .card-footer-2 {
    background-color: $bg-color-2;
    margin: 0 $margin-size-2;
    padding: $margin-size-2 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.border-bottom {
      border-bottom: 1px solid $hr-color;
    }

    .collectible-stats {
      .comment-icon {
        font-size: 20px;
        width: 70px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #d1d1d3;

        .icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          transition: 0.5s;
          font-weight: 100;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              width: 40px;
              height: 40px;
              background-color: #434659;
              color: #00b7ff;
            }
          }
        }

        span {
          font-size: 14px;
        }
      }

      .share-icon {
        font-size: 20px;
        width: 70px;
        display: flex;
        align-items: center;
        cursor: pointer;
        color: #d1d1d3;
        .shared {
          color: #00dcaf;
        }
        .icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          transition: 0.5s;
          font-weight: 100;

          svg {
            width: 22px;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              width: 40px;
              height: 40px;
              background-color: #434659;

              path {
                fill: #00dcaf;
              }
            }
          }

          > .shared {
            path {
              fill: #00dcaf;
            }
          }
          path {
            fill: #d1d1d3;
          }
        }

        span {
          font-size: 14px;
        }
      }
    }
  }

  > .annotation {
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .collectible-footer-2 {
        .collectible-shopping-cart-button {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 656px) {
    & {
      border-radius: 0;
      width: 100vw;
    }
  }
}

.collectible-preview {
  &__player-wrapper {
    width: 100%;
    height: 100%;
  }

  &__model-viewer-wrapper {
    width: 100%;
    height: 100%;

    model-viewer {
      width: 100%;
      height: 100%; 

      &::part(default-progress-bar){
        background-color: $bg-color-1;
      }

      &::part(default-ar-button){
       left: 16px; 
       right: unset;
       transform-origin: bottom left;
      }      
    }  
  }
}

.collectible-card-mini {
  border-radius: $margin-size-3;
  width: 100px;
  overflow: hidden;

  .collectible-preview {
    max-height: 100px;
    max-width: 100px;
    background-color: $black;
    text-align: center;

    .collectible-image {
      max-height: 100px;
      max-width: 100px;
    }
  }

  .card-footer-1 {
    background-color: $bg-color-2;
    padding: $margin-size-1;
    display: flex;

    .collectible-title {
      font-size: $text-size-smaller;
    }

    .collectible-category {
      font-size: $text-size-smallest;
    }
  }
}

.collectible-group {
  > .group-header {
    margin: $margin-size-2;

    > .group-title {
      padding-bottom: 4px;
      padding-left: 2px;
    }

    > .group-line {
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background: adjust-color($border-color-1, $lightness: -0%);
        border-radius: 4px;
      }

      > .group-subtitle {
        font-size: $text-size-small;
        color: $grey;
        padding-top: 2px;
        padding-left: 2px;
      }
    }
  }

  > .group-items {
    padding: $margin-size-1 $margin-size-2;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 1rem;

    .collectible-card-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      .collectible-card {
        width: 100%;
      }
    }
  }
}

.collectible-card-recommend {
  display: flex;
  padding: $margin-size-2;
  border-bottom: 1px solid $lightblue;

  > .image-container {
    > .preview-image {
      width: 100px;
      height: 100px;
      border-radius: 20px;

      > .collectible-image {
        width: 100%;
        height: 100%;
        border-radius: 24px;
      }
    }
  }

  > .collectible-info {
    width: 100%;
    height: calc('100%');
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .image-container {
      > .preview-image {
        cursor: pointer;
      }
    }

    .price-category {
      display: flex;

      > .price {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid white;
        border-radius: 20px;
        width: 65px;
        height: 28px;
        margin-right: $margin-size-2;
      }

      > .category {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $white;
        border-radius: 5px;
      }
    }

    > .collectible-detail {
      display: flex;
      justify-content: space-between;

      > .detail {
        > .title {
          font-size: $text-size-bigish;
          color: $white;
          margin-bottom: 2px;
          cursor: pointer;
        }

        > .type-name {
          font-size: $text-size-medium;
          color: $lightestblue;
          font-weight: 500;
          margin-bottom: 2px;
          cursor: pointer;
        }

        > .created-name {
          font-size: $text-size-small;
          color: $greyish;
          cursor: pointer;
        }
      }

      > .icon {
      }
    }

    > .collectible-more {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > .collectible-count {
        display: flex;
      }
    }
  }
}

.collectible-preview-selection {
  display: flex;
  flex-direction: column;
  align-items: center;

  .input-validation-error {
    color: $red;
    margin-top: $margin-size-1;
    margin-left: $margin-size-2;
    @extend .text-small;
  }

  > .preview-container {
    width: 370px;
    height: 210px;
    position: relative;
    background-color: $bg-color-2;

    > .images {
      position: relative;

      > .cover-image {
        width: 100%;
        height: 100%;
      }

      > .slider-button {
        width: 100%;
        display: flex;
        justify-content: center;
        bottom: $margin-size-2;
        position: absolute;

        > .slider-point {
          cursor: pointer;
          background-color: $grey;
          width: 8px;
          height: 8px;
          border-radius: 50%;

          &.active {
            background-color: $white;
          }
        }
      }

      > .loading {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        > .loading-area {
          border-radius: 5px;
          overflow: hidden;
          width: 70%;
          height: 10px;
          background-image: linear-gradient(90deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
          display: flex;
          justify-content: flex-end;

          > .progress {
            background-color: $lightblue;
            height: 10px;
          }
        }

        > .spinner-area {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          animation: spin 4s linear infinite;
          background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);

          @keyframes spin {
            from {
              transform: rotate(0deg);
            }

            to {
              transform: rotate(359deg);
            }
          }

          > .spinner {
            background-color: $bg-color-2;
            width: 80px;
            height: 80px;
            border-radius: 50%;
          }
        }

        > .text {
          position: absolute;
          bottom: 30px;
        }
      }
    }

    > .add-cover {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      > .add-cover-button {
        border: 1px $white solid;
        font-size: $text-size-bigish;
        border-radius: 20px;
        padding: 10px 15px;
        cursor: pointer;
      }
    }

    > .delete-replace {
      cursor: pointer;
      position: absolute;
      top: $margin-size-3;
      right: $margin-size-3;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $text-size-medium;
      border: 1px solid $white;
      border-radius: 50%;

      .icon {
        color: white !important;
        margin: 0 !important;
      }
    }
  }

  > .image-list-container {
    display: flex;
    justify-content: center;
    width: 100%;

    > .center-image-list {
      width: 370px;
      display: flex;
      align-items: flex-end;

      > .divider {
        height: 50px;
        width: 1px;
        background-color: $bg-color-2;
      }

      > .cover-image-list {
        > .input-title {
          color: #5e5f67;
          font-size: $text-size-small;
        }

        > .list {
          display: flex;
          align-items: center;

          > .image {
            cursor: pointer;
            width: 50px;
            height: 50px;
            position: relative;
            border-radius: 10px;
            overflow: hidden;
            background-color: $bg-color-2;

            .shadow {
              background-color: #202020;
              opacity: 0.8;
              position: absolute;
              height: 100%;
            }

            .loading {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;

              > .loading-area-container {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                i {
                  color: white !important;
                }

                > .loading-area {
                  border-radius: 5px;
                  overflow: hidden;
                  width: 65%;
                  height: 5px;
                  background-image: linear-gradient(90deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
                  display: flex;
                  justify-content: flex-end;
                  position: absolute;
                  bottom: 0.55rem;

                  > .progress {
                    background-color: $lightblue;
                    height: 6px;
                  }
                }
              }

              > .spinner-area {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                animation: spin 4s linear infinite;
                background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);

                @keyframes spin {
                  from {
                    transform: rotate(0deg);
                  }

                  to {
                    transform: rotate(359deg);
                  }
                }

                > .spinner {
                  background-color: $bg-color-2;
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  > i {
                    color: white !important;
                  }

                  > .icon {
                    color: white;
                    animation: spin 4s linear infinite reverse;

                    @keyframes spin {
                      from {
                        transform: rotate(0deg);
                      }

                      to {
                        transform: rotate(359deg);
                      }
                    }
                  }
                }
              }

              > .text {
                position: absolute;
                bottom: 30px;
              }
            }

            > .collectible-image {
              width: 100%;
              height: 100%;
            }
          }

          > .add-image {
            .icon {
              color: white !important;
              margin: 0 !important;
            }

            cursor: pointer;
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $bg-color-2;
            border-radius: 10px;

            > .circle {
              border: 1px solid $white;
              border-radius: 50%;
              width: 32px;
              height: 32px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: $text-size-medium;
            }
          }
        }
      }
    }
  }
}

.collectible-card-horizontal {
  > .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $margin-size-2;

    > .info {
      display: flex;
      align-items: center;

      > .item-number {
        color: $lightgrey;
      }

      > .card-image {
        width: 60px;
        height: 60px;
        border-radius: 10px;
      }

      > .collectible-info {
        > .title {
          font-size: $text-size-bigish;
          font-weight: 600;
        }

        > .category {
          font-weight: 600;
          font-size: $text-size-medium;
          color: $lightestblue;
        }

        > .date {
          font-size: $text-size-medium;
          color: $grey;
        }
      }
    }

    > .column-right {
      display: flex;
      align-items: center;

      > .collectible-category-box {
        border: 1px solid $white;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.active {
      background: $bg-color-5;
      border-radius: 10px;
    }
  }
}

.category-box {
  color: $white;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 138px;
  cursor: pointer;

  &.has-border-bottom {
    border-bottom: 1px solid $border-color-4;
  }

  .category-icon {
    width: 68px;
    height: 68px;
    margin-bottom: 18px;
    border: 2px solid $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 25px;
    }
  }

  .category-name {
    margin-top: $margin-size-1;
    font-size: $text-size-small;
    text-align: center;
  }

  .selection-indicator {
    display: none;
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
  }

  &[data-isselected='true'] {
    color: $border-color-2;

    .category-icon {
      border-color: $border-color-2;
    }

    .selection-indicator {
      display: flex;
      justify-content: center;

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $border-color-2;
      }
    }
  }

  &.is-small {
    width: 64px;

    > .category-icon {
      width: 50px;
      height: 50px;
      border-width: 1px;
      margin-bottom: 0;

      .icon {
        font-size: 16px;
      }
    }
  }
}

.type-box {
  color: $white;
  position: relative;

  &.has-border-bottom {
    border-bottom: 1px solid $border-color-4;
  }

  .type-icon {
    width: 60px;
    height: 60px;
    border: 2px solid $white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon {
      font-size: 22px;
    }
  }

  .type-name {
    margin-top: $margin-size-1;
    font-size: $text-size-small;
    text-align: center;
  }

  .selection-indicator {
    display: none;
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
  }

  &[data-selected='true'] {
    color: $border-color-2;

    .type-icon {
      border-color: $border-color-2;
    }

    .selection-indicator {
      display: flex;
      justify-content: center;

      &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: $border-color-2;
      }
    }
  }

  &.is-small {
    .type-icon {
      width: 40px;
      height: 40px;
      border-width: 1px;

      .icon {
        font-size: 16px;
      }
    }
  }
}

.category-selector {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  > .category-box {
    padding: $margin-size-3 $margin-size-2;
  }

  &[data-isselected='true'] {
    > .category-box {
    }

    > .category-box:not([data-isselected='true']) {
      color: $darkgrey;

      .category-icon {
        border-color: $darkgrey;
      }
    }
  }

  &.is-small {
    > .category-box {
      padding: $margin-size-2 $margin-size-1;
    }
  }
}

.type-selector {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  .type-box {
    padding: $margin-size-3 $margin-size-2;
  }

  &[data-selected='true'] {
    .type-box {
    }

    .type-box:not([data-selected='true']) {
      color: $darkgrey;

      .type-icon {
        border-color: $darkgrey;
      }
    }
  }

  &.is-small {
    .type-box {
      padding: $margin-size-2 $margin-size-1;
    }
  }
}

hr {
  margin: 0;
  height: 1px;
  border: none;
  width: 100%;
  background-color: #2e3146;
}

.modal {
  line-height: 1.2rem;
  position: fixed;
  top: -200vh;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 999;

  &.modal-show {
    top: 0;

    > .modal-backdrop {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  &[data-modal-opening='true'] {
    @keyframes modal-opening {
      from {
        transform: translateY(-100vh);
      }

      to {
        transform: translateY(0);
      }
    }

    @keyframes modal-opening-backdrop {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    > .modal-box {
      animation: modal-opening 200ms ease-in;
    }

    > .modal-backdrop {
      animation: modal-opening-backdrop 200ms ease-in;
    }
  }

  &[data-modal-closing='true'] {
    @keyframes modal-closing {
      from {
        transform: translateY(0);
      }

      to {
        transform: translateY(-100vh);
      }
    }

    @keyframes modal-closing-backdrop {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }

    top: 0;
    animation: modal-closing 200ms ease-in;

    > .modal-backdrop {
      animation: modal-closing-backdrop 200ms ease-in;
    }
  }

  > .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  > .modal-container {
    width: 600px;
    max-width: 100vw;
    background-color: $bg-color-4;
    border-radius: 25px;
    padding: 30px 18px 18px 18px;

    > .modal-box {
      z-index: 999;
      width: 100%;
      max-height: 90vh;
      // @extend .py-1, .px-1;

      > .modal-header {
        @extend .mb-2;
        display: flex;
        align-items: center;

        .icon-container {
          .icon-button {
            border: 2px solid white;
            border-radius: 50%;
            color: white;
            cursor: pointer;
            font-size: 14px;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: #252730;
                border: none;
              }
            }
          }
        }

        > .modal-header-top {
          display: flex;
          align-items: center;
          width: 100%;

          > .info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            //  text-align:center;
            .title {
              font-size: 24px;
            }

            .subtext {
              @extend .text-small;
              margin-top: $margin-size-1;
              color: $lightgrey;
              font-size: 12px;
            }
          }
        }

        &.back-button {
          > .modal-header-top {
            display: flex;
            justify-content: flex-start;
          }
        }
      }

      > .modal-content {
        overflow: auto;
        text-align: center;
        max-height: 70vh;

        > .edit-profile-modal {
          .ejr02pv {
            transform: translate(-96%, -3%) !important;
          }
          .input-validation-error {
            position: absolute;
          }
          > .all-content {
            width: 100%;

            > .img-container {
              display: flex;
              justify-content: center;
              width: 100%;
              position: relative;

              .shadow {
                position: absolute;
                z-index: 2;
                height: 142px;
                width: 142px;
                border-radius: 50%;
                overflow: hidden;
                background-color: rgba(0, 0, 0, 0.4);
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid white;
                cursor: pointer;
                top: 5px;

                i {
                  font-size: 24px;
                }
              }
            }

            .image-error {
              margin: $margin-size-2 0;
              color: red;
            }

            > .social-text-container {
              display: flex;
              flex-direction: column;

              > .social-texts {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
              }
            }

            > .footer {
              float: right;
            }
          }
          @media screen and (max-width: $media-break-sm) {
            .right-area {
              display: none;
            }
          }
        }

        > .edit-collection-modal {
          .oilogo {
            width: 14px;
            height: 14px;
            margin-right: $margin-size-2;
            filter: invert(70%) sepia(0%) saturate(0%) hue-rotate(50deg) brightness(210%) contrast(160%);
          }
          .ejr02pv {
            transform: translate(-96%, -3%) !important;
          }
          .input-validation-error {
            position: absolute;
          }
          .input > .input-container > .input-wrap .editor {
            // We will move this editor into its own new component
            // that matches with all the requirements of design better
            .public-DraftEditorPlaceholder-root {
              color: #585a6b;
              font-size: 16px;
              padding-right: 25px;
              word-break: initial;
            }

            .public-DraftEditor-content {
              font-size: 16px;
              height: 124px;
              max-height: 124px;
              min-height: 124px;
              word-break: initial;
            }
          }
          > .all-content {
            width: 100%;

            > .images-container {
              position: relative;
              height: 220px;

              > .img-container {
                display: flex;
                justify-content: center;
                width: 100%;
                position: relative;

                .profile-avatar {
                  position: absolute;
                  top: -92px;
                  left: 15px;
                  border: 6px solid #0c0e19;
                  border-radius: 50%;
                  background-color: #0c0e19;
                  z-index: 12;
                }

                .shadow {
                  position: absolute;
                  z-index: 12;
                  height: 118px;
                  width: 118px;
                  border-radius: 50%;
                  overflow: hidden;
                  background-color: rgba(0, 0, 0, 0.4);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 2px solid white;
                  cursor: pointer;
                  top: -87px;
                  left: 20px;

                  i {
                    font-size: 24px;
                  }
                }
              }

              > .cover-img-container {
                display: flex;
                justify-content: center;
                width: 100%;
                position: relative;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                overflow: hidden;
                padding-top: 28%;

                img {
                  position: absolute;
                  top: 0;
                }

                .shadow {
                  position: absolute;
                  z-index: 10;
                  height: 60px;
                  width: 60px;
                  border-radius: 50%;
                  overflow: hidden;
                  background-color: rgba(0, 0, 0, 0.4);
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 2px solid white;
                  cursor: pointer;
                  top: calc(50% - 30px);

                  i {
                    font-size: 18px;
                  }
                }
              }
            }

            .image-error {
              margin: $margin-size-2 0;
              color: red;
            }

            > .social-text-container {
              display: flex;
              flex-direction: column;

              > .social-texts {
                display: flex;
                justify-content: space-between;
                flex-direction: row;
              }
            }

            > .footer {
              float: right;
            }
          }
          @media screen and (max-width: $media-break-sm) {
            .right-area {
              display: none;
            }
          }
        }

        > .connect-wallet-modal {
          > .wallet-option {
            border-radius: 0.4rem;
            cursor: pointer;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .spinner-container {
              width: 80px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            > .wallet-info-container {
              display: flex;

              > .icon-container {
                width: 80px;
                .icon {
                  margin-right: $margin-size-2;
                  font-size: 42px;
                }
              }
              > .show-icon-container {
                width: 80px;

                .square {
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  margin: 0 auto;
                  border: 1px solid white;
                  border-radius: 8px;
                  width: 38px;
                  height: 38px;
                }
                .icon {
                  font-size: 18px;
                }
              }

              > .wallet-name {
                display: flex;
                align-items: center;
              }
            }

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: #252730;
              }
            }

            &.connecting {
              background-color: #252730;
            }
          }
        }

        > .transfer-money-modal {
          .invisible {
            visibility: hidden;
          }

          > .header {
            display: flex;
            justify-content: space-between;
            display: flex;
            align-items: center;

            > .balance-container {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;

              > .balance {
                font-size: $text-size-bigger;
                font-weight: bold;
              }

              > .balance-text {
                margin-top: $margin-size-2;
                font-size: $text-size-small;
                color: $lightgrey;
              }
            }
          }

          > .inputs-container {
            > .edit-payout-text {
              display: flex;
              justify-content: flex-end;
            }
          }

          @media screen and (max-width: $media-break-sm) {
            .invisible {
              display: none;
            }

            > .header {
              justify-content: space-around;
            }
          }
        }

        > .review-transfer-modal {
          .invisible {
            visibility: hidden;
          }

          > .header {
            display: flex;
            justify-content: space-between;
            display: flex;
            align-items: center;

            > .balance-container {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;

              > .balance {
                font-size: $text-size-bigger;
                font-weight: bold;
              }

              > .balance-text {
                margin-top: $margin-size-2;
                font-size: $text-size-small;
                color: $lightgrey;
              }
            }
          }

          > .inputs-container {
            > .edit-payout-text {
              display: flex;
              justify-content: flex-end;
            }
          }

          @media screen and (max-width: $media-break-sm) {
            .invisible {
              display: none;
            }

            > .header {
              justify-content: space-around;
            }
          }
        }

        > .transfer-success-modal {
          > .header {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 84px;
            color: $oigreen;
            margin: $margin-size-5 0;
          }

          > .content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            > .text-1 {
              font-size: $text-size-big;
              margin-bottom: $margin-size-3;
            }

            > .text-2 {
              font-size: 2rem;
              margin-bottom: $margin-size-5;
            }

            > .text-3 {
              font-size: $text-size-medium;
              color: $grey;
              margin-bottom: $margin-size-1;
            }

            > .text-4 {
              font-size: $text-size-medium;
              color: $grey;
            }
          }
        }

        > .publish-via-modal {
          .publish-via-container {
            hr {
              margin: $margin-size-1;
            }

            .switch-row {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 8px;
              border-radius: 5px;

              div {
                display: flex;
                align-items: center;
              }

              .icon {
                margin-right: $margin-size-2;
                font-size: 20px;
              }

              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  background-color: #20212e;
                }
              }
            }
          }
        }

        > .signing-wallet-modal {
          text-align: start;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title {
          }
          .description {
            color: $grey;
            font-size: $text-size-small;
          }
        }
        > .signature-rejected-modal {
          text-align: start;
        }

        > .access-needed-modal {
          text-align: start;
        }

        > .email-authentication-modal {
          .invisible {
            visibility: hidden;
          }

          > .email-auth-container {
            text-align: start;

            .text {
              font-size: $text-size-medium;
              color: white;
            }

            .sub-text {
              color: $grey;
              font-size: $text-size-small;
            }

            > .title {
              font-size: $text-size-bigish;
            }
          }

          @media screen and (max-width: $media-break-sm) {
            .invisible {
              display: none;
            }
          }
        }

        > .sms-authentication-modal {
          .invisible {
            visibility: hidden;
          }

          > .sms-auth-container {
            text-align: start;

            .text {
              font-size: $text-size-medium;
              color: white;
            }

            .sub-text {
              color: $grey;
              font-size: $text-size-small;
            }

            > .title {
              font-size: $text-size-bigish;
            }

            > .content {
              display: flex;
              align-items: center;
              justify-content: space-between;

              > .icon-container {
                display: flex;
                justify-content: center;
                width: 30%;

                i {
                  font-size: 4rem;
                }
              }

              > .instruction-container {
                display: flex;
                flex-direction: column;
                width: 70%;
              }
            }
          }

          @media screen and (max-width: $media-break-sm) {
            .invisible {
              display: none;
            }

            > .sms-auth-container {
              > .content {
                width: 100%;
                justify-content: center;

                > .icon-container {
                  margin: 0;
                  display: none;
                }

                .instruction-container {
                  width: 100%;

                  .input {
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        > .authenticator-app-modal {
          > .authenticator-app-container {
            text-align: start;

            > .title {
            }

            .text {
              font-size: $text-size-medium;
              color: white;
            }

            .sub-text {
              margin-top: $margin-size-1;
              color: $grey;
              font-size: $text-size-small;
            }

            > .backup-code-section {
              width: 100%;

              > .code-container {
                display: inline-block;
                width: 25%;
                height: 36px;

                > .code {
                  height: 100%;
                  display: flex;
                  align-items: center;
                }
              }
            }

            > .row {
              display: flex;
              width: 100%;

              > .icon-container {
                display: flex;
                justify-content: center;
                width: 30%;

                i {
                  font-size: 4rem;
                }
              }

              > .qr-container {
                display: flex;
                justify-content: center;
                width: 30%;

                canvas {
                  background-color: white;
                  padding: 6px;
                  border: 1px solid #d4d4d4;
                  border-radius: 1rem;
                }
              }

              > .instruction-container {
                display: flex;
                flex-direction: column;
                width: 70%;
              }
            }

            @media screen and (max-width: $media-break-sm) {
              > .row {
                align-items: center;
                flex-direction: column;

                > .icon-container {
                  margin-bottom: $margin-size-2;
                }

                > .qr-container {
                  margin-bottom: $margin-size-2;
                }

                > .instruction-container {
                  width: 100%;
                }
              }
            }

            @media screen and (max-width: $media-break-md) {
              > .code {
                display: inline-block;
                margin: 0;
                width: 50%;
                height: 36px;
              }
            }
          }
        }

        > .disable-2fa-modal {
          text-align: start;
          line-height: 1.2rem;

          .text {
            font-size: $text-size-bigish;
            color: white;
          }

          .sub-text {
            margin-top: $margin-size-1;
            font-size: $text-size-medium;
          }
        }

        > .upload-prompt-modal {
          text-align: start;
          line-height: 1.2rem;

          .upload-container {
            display: flex;
            align-items: center;

            .progress-container {
              width: 240px;
              height: 8px;
              background-color: white;
              overflow: hidden;
              border-radius: 8px;

              .progress {
                background-color: #00b7ff;
                height: 8px;
              }
            }
          }

          .text {
            font-size: $text-size-bigish;
            color: white;
          }

          .sub-text {
            margin-top: $margin-size-1;
            font-size: $text-size-medium;
          }
        }

        > .edit-profile-picture-modal {
          > .slider {
            margin-top: $margin-size-4;
            -webkit-appearance: none;
            appearance: none;
            width: 50%;
            margin: 0 auto;
            height: 10px;
            border-radius: 10px;
            background: $bg-color-2;
            outline: none;
            -webkit-transition: 0.2s;
            transition: opacity 0.2s;
          }

          > .slider::-webkit-slider-thumb {
            -webkit-appearance: none;
            appearance: none;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background: $lightestblue;
            cursor: pointer;
          }

          > .slider::-moz-range-thumb {
            width: 25px;
            height: 25px;
            background: $lightestblue;
            cursor: pointer;
          }

          > .avatar-editor-wrapper {
            width: 100%;
          }
        }

        > .send-feedback-modal {
          > .send-feedback-form {
            > .input-div {
              width: 100%;
              position: relative;
              background-repeat: no-repeat;
              background-size: cover;
              display: flex;
              margin-top: $margin-size-2;
              align-items: center;
              justify-content: center;

              > .feedback-image {
                width: 100%;
                height: auto;
              }
            }

            > .switch-container {
              border-top: 1px solid $hr-color;
              border-bottom: 1px solid $hr-color;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;

              > .text {
                font-size: $text-size-bigish;
              }
            }

            > .button-area {
              border-top: 1px solid $hr-color;
              padding-top: $margin-size-2;
              display: flex;
              align-items: flex-end;
              justify-content: flex-end;
            }

            > .information {
              text-align: left;

              > p {
                font-size: $text-size-medium;
              }
            }
          }
        }

        > .change-display-name-modal {
          > .change-display-name-container {
            text-align: start;

            .title {
              font-size: $text-size-bigish;
            }

            .text {
              font-size: $text-size-medium;
              color: white;
            }

            .sub-text {
              margin-top: $margin-size-1;
              color: $grey;
              font-size: $text-size-small;
            }

            .switch-container {
              display: flex;
            }
          }
        }

        > .change-email-modal {
          > .change-email-container {
            text-align: start;

            .title {
              font-size: $text-size-bigish;
            }

            .text {
              font-size: $text-size-medium;
              color: white;
            }

            .sub-text {
              margin-top: $margin-size-1;
              color: $grey;
              font-size: $text-size-small;
            }

            .switch-container {
              display: flex;
            }
          }
        }

        > .account-delete-modal {
          > .account-delete-container {
            text-align: start;

            .title {
              font-size: $text-size-bigish;
            }

            .text {
              font-size: $text-size-medium;
              color: white;
            }
          }
        }

        > .verify-unfollow-modal {
          text-align: start;
          line-height: 1.2rem;

          .title {
            font-weight: bold;
          }

          .description {
            color: $grey;
            font-size: $text-size-small;
          }
        }

        > .add-paypal-account-modal {
          > .add-paypal-account-container {
            > .icon-container {
              .icon {
                font-size: 55px;
              }
            }

            > .text {
              font-size: $text-size-small;
              color: $grey;
              line-height: 1.1rem;
            }

            .paypal-container {
              height: 50px;
            }
          }
        }

        > .add-credit-card-modal {
          > .add-credit-card-container {
            .form {
              > .address-container {
                display: flex;
                align-items: center;

                .icon {
                  font-size: 20px;
                  margin-top: 16px;
                  width: 60px;
                  color: $lightestblue;
                }
              }

              .billing-information-container {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .icon {
                  cursor: pointer;
                }

                > .info {
                  text-align: start;

                  > .text {
                    display: flex;
                    align-items: center;
                  }

                  > .sub-text {
                    font-size: $text-size-small;
                    color: $grey;
                  }
                }
              }
            }
          }
        }

        > .buy-modal {
          > .buy-modal-container {
            .sub-text {
              color: $grey;
              font-size: $text-size-smaller;
              text-align: start;
            }

            .expand-icon {
              cursor: pointer;
              margin-right: $margin-size-1;
            }

            .logo-container {
              width: 50px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;

              .icon {
                font-size: 26px;
              }
            }

            .license-select {
              font-size: $text-size-small;
              text-align: start;
              display: flex;
              align-items: center;

              > .license-wrap {
                display: flex;
                align-items: center;
                cursor: pointer;

                &-blue {
                  width: 100px;

                  .input-wrap {
                    background-color: transparent;
                    border-left: 1px solid #2e3045;

                    .react-select__single-value {
                      color: $oiblue;
                    }
                  }
                }

                &-green {
                  width: 100px;

                  .input-wrap {
                    background-color: transparent;
                    border-left: 1px solid #2e3045;

                    .react-select__single-value {
                      color: $oigreen;
                    }
                  }
                }

                &-pink {
                  width: 100px;

                  .input-wrap {
                    background-color: transparent;
                    border-left: 1px solid #2e3045;

                    .react-select__single-value {
                      color: $oired;
                    }
                  }
                }
              }
            }

            > .license-price-container {
              display: flex;
              align-items: center;
              justify-content: space-between;

              > .text-bigish {
                align-items: center;
                display: flex;
              }
            }

            .collectible-info-container {
              display: flex;
              width: 100%;

              img {
                border-radius: 0.7rem;
              }

              .collectible-info {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .icon-container {
                  width: 24px;
                  height: 24px;
                  border: 1px solid white;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 0.3rem;

                  .icon {
                    font-size: 12px;
                  }
                }

                > .row-1 {
                  display: flex;
                  flex-direction: column;

                  > span {
                    display: flex;
                    font-size: $text-size-small;
                    margin-top: $margin-size-1;
                  }
                }
              }
            }
          }
        }

        > .redeem-modal {
          > .redeem-modal-container {
            text-align: left;

            > .title {
              font-size: $text-size-big;
            }

            > .email {
              font-size: $text-size-small;
              color: $grey;
            }

            > .description {
              font-size: $text-size-small;
            }
          }
        }

        > .unsubscribe-modal {
          > .unsubscribe-modal-container {
            text-align: left;

            > .title {
              font-size: $text-size-medium;
            }

            > .description {
              font-size: $text-size-small;
              color: $grey;
            }

            a {
              font-size: $text-size-small;
            }
          }
        }

        > .share-social-modal {
          > .row-element {
            cursor: pointer;
            transition: 600s;

            > .line-content {
              width: 100%;
              text-align: left;
              height: 60px;
              display: flex;
              align-items: center;
              padding-left: $margin-size-1;

              > .icon {
                font-size: 26px;
              }
            }

            @media (hover: hover) and (pointer: fine) {
              .line-content:hover {
                background-color: #20212e;
                border-radius: 0.4rem;
              }
            }
          }
        }

        > .report-modal {
          > .row-element {
            cursor: pointer;
            transition: 600s;

            > .line-content {
              width: 100%;
              text-align: left;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-left: $margin-size-1;

              > .icon {
                font-size: 20px;
              }
            }

            @media (hover: hover) and (pointer: fine) {
              .line-content:hover {
                background-color: #20212e;
                border-radius: 4px;
              }
            }
          }
        }
        > .confirm-report-modal {
          > .brand-area {
            height: 136px;
            background-color: #141620;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 200px;
            }
          }

          > .line-content {
            cursor: pointer;
            transition: 600s;
            width: 100%;
            text-align: left;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: $margin-size-1;

            > .icon {
              font-size: 20px;
            }
          }

          @media (hover: hover) and (pointer: fine) {
            .line-content:hover {
              background-color: #20212e;
              border-radius: 10px;
            }
          }
        }

        > .invite-social-account-modal {
          > .social-account-container {
            > .tab-container {
              display: flex;
              justify-content: space-between;
              align-items: center;

              > div {
                > div {
                  cursor: pointer;
                }
              }
            }

            > .friend-list-container {
              height: 410px;

              > .collectible-item {
                height: 80px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                > .cell-1 {
                  display: flex;
                  align-items: center;
                  text-align: start;
                }
              }
            }
          }
        }

        > .general-information-modal {
          > .general-information-container {
            .form {
              > .inputs-row {
                display: flex;

                > .input-field {
                  padding: $margin-size-2 0;
                  width: 48%;
                }
              }

              > .address-container {
                display: flex;
                align-items: center;

                .icon {
                  font-size: 20px;
                  margin-top: 16px;
                  width: 60px;
                  color: $lightestblue;
                }
              }
            }
          }

          @media screen and (max-width: $media-break-sm) {
            > .general-information-container {
              .form {
                > .inputs-row {
                  flex-direction: column;
                  margin-top: 0;

                  > .input-field {
                    width: 100%;
                  }
                }

                > .address-container {
                  display: flex;
                  align-items: center;

                  .icon {
                    font-size: 20px;
                    margin-top: 16px;
                    width: 60px;
                    color: $lightestblue;
                  }
                }
              }
            }
          }
        }

        > .add-bank-account-payout-modal {
          > .add-bank-account-payout-container {
            .form {
              > .inputs-row {
                display: flex;

                > .input-field {
                  padding: $margin-size-2 0;
                  width: 48%;
                }
              }
            }
          }

          @media screen and (max-width: $media-break-sm) {
            > .add-bank-account-payout-container {
              .form {
                > .inputs-row {
                  flex-direction: column;
                  margin-top: 0;

                  > .input-field {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }

      > .modal-footer {
        @extend .mt-3;

        > .footer-buttons {
          display: flex;
          flex-direction: row-reverse;
        }

        > .footer {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .dropdown-button {
            position: relative;

            .icon-container {
              cursor: pointer;
              position: absolute;
              top: 0px;
              right: 0px;
              border-left: 1px solid black;
              padding: $margin-size-2;
              height: 100%;
              display: flex;
              align-items: center;
              text-align: right;
            }

            .sub-button {
              position: absolute;
              top: 45px;
              right: 0;
            }
          }

          > .footer-text {
            font-size: $text-size-small;

            > .text-1 {
              color: white;
            }

            > .text-2 {
              color: $lightgrey;
            }
          }
        }
      }
    }
  }

  &.modal-center-buttons {
    > .modal-container {
      > .modal-box {
        > .modal-footer {
          > .footer {
            justify-content: center;
          }
        }
      }
    }
  }
  // Scrollbar
  .modal-content::-webkit-scrollbar {
    width: 4px;
  }

  .modal-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }

  .modal-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #2e3146;
  }

  @mixin modal-fullscreen() {
    > .modal-container {
      border-radius: 0;
      width: 100vw;
      height: 100%;

      > .modal-box {
        max-height: 100vh;
        height: 100vh;

        > .modal-content {
          max-height: 72%;
        }
      }
    }
  }

  &.modal-fullscreen {
    @include modal-fullscreen;
  }

  @media screen and (max-width: $media-break-sm) {
    @include modal-fullscreen;
  }
}

.collectible-summary {
  width: 100%;
  height: 75px;
  display: flex;
  padding: 0 $margin-size-2;
  justify-content: space-between;
  align-items: center;

  > .content {
    display: flex;
    width: 100%;
    height: 60px;
    > .img {
      margin-right: $margin-size-2;
      height: 55px;
      width: 55px;
      border-radius: 0.8rem;
    }

    > .info {
      width: 100%;
      display: flex;
      padding: 2px 0;
      flex-direction: column;
      justify-content: space-between;

      > .cell-1 {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        > .info-text {
          > .text-1 {
            font-size: $text-size-small;
          }

          > .text-2 {
            margin-top: $margin-size-1;
            color: $lightestblue;
            font-size: $text-size-smallest;
          }
        }

        .icon {
          font-size: 12px;
          display: none;
          color: $grey;
        }
      }

      > .cell-2 {
        display: flex;
        color: $grey;
        font-size: $text-size-smaller;
        justify-content: space-between;

        > .d-flex {
          width: 100%;

          > .stacked-icons {
            display: flex;

            > .icon-container {
              display: flex;
              margin-right: $margin-size-2;
              align-items: baseline;

              > .icon {
                font-size: 10px;
                margin-right: $margin-size-1 * 0.5;
                display: block;
              }

              > .text-1 {
                font-size: $text-size-smallest;
              }
            }
          }

          .icon {
            display: none;
          }
        }
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      background-color: #252738;

      > .content {
        > .info {
          > .cell-1 {
            .icon {
              display: block;
            }
          }

          > .cell-2 {
            display: flex;
            color: $grey;
            font-size: $text-size-smaller;
            justify-content: space-between;

            > .d-flex {
              .icon {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.creator-summary {
  width: 100%;
  height: 75px;
  display: flex;
  padding: 0 $margin-size-2;
  justify-content: space-between;
  align-items: center;

  > .content {
    display: flex;
    width: 100%;
    height: 60px;

    > .avatar-img-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > .info {
      margin-left: $margin-size-2;
      width: 100%;
      display: flex;
      padding: 2px 0;
      flex-direction: column;
      justify-content: space-between;

      > .cell-1 {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        > .info-text {
          > .text-1 {
            font-size: $text-size-small;
          }

          > .text-2 {
            margin-top: $margin-size-1;
            font-size: $text-size-smallest;
          }
        }
      }

      > .cell-2 {
        display: flex;
        color: $grey;
        font-size: $text-size-smaller;
        justify-content: space-between;

        > .d-flex {
          width: 100%;
        }
      }
    }

    .buttons-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      font-size: $text-size-smaller;

      div {
        margin: 4px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        border-radius: 2rem;
      }

      > .follow-button {
        border: 1px solid $lightestblue;
        height: 24px;
      }

      > .follow-back-button {
        color: $grey;
        background-color: #252738;
        height: 22px;
        margin-top: $margin-size-1;
      }

      .button {
        min-width: 72px;
        width: 72px;
        padding: 9px 1rem;
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      background-color: #252738;
    }
  }
}

.Toastify {
  // .Toastify__toast-theme--dark {
  //   background-color: $bg-color-1;
  // }
  .Toastify__close-button {
    color: white;
  }

  .Toastify__close-button > svg {
    color: white;
  }

  @media (hover: hover) and (pointer: fine) {
    .Toastify__close-button:hover {
      color: white;
    }
  }

  .Toastify__close-button:focus {
    color: white;
  }
}

.profile-dropdown {
  > .MuiPaper-root {
    background-color: #000000;
    width: 295px;
    border-radius: 10px;

    > .dropdown-content {
      display: flex;
      flex-direction: column;
      color: $white;
      .horizontal {
        width: 100%;
        margin: 0 auto;
        background-color: #1a1a1a;
      }

      .display-public-address {
        background-color: transparent;
        font-size: 16px;
        font-weight: 700;
      }

      > .close-button {
        display: none;
      }

      > .public-address-wrapper {
        margin-top: 16px;
        margin-left: 18px;
        display: flex;
        > .display-public-address {
          padding: 3px 10px;
          background-color: #1a1a1a;
          > .address {
            font-size: 12px;
            color: #8d8d8d;
          }
        }
      }

      > .user-info {
        display: flex;
        margin: 24px $margin-size-3 20px $margin-size-3;

        > .user-profile {
          margin-left: 18px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          > .user-name {
            font-weight: bold;
            font-size: 16px;

            > .display-public-address {
              padding: 0;
            }
          }

          > .slug {
            margin-top: 3px;
            color: #808080;
            font-size: 12px;
            margin-bottom: 12px;
          }

          .to-profile {
            color: $lightestblue;
            font-size: 12px;

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                color: lighten($lightestblue, 10%);
              }
            }
          }
        }
      }

      > .wallet-info {
        > .info {
          display: flex;
          margin: $margin-size-3;
          margin-bottom: 16px;

          > .icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            > svg {
              height: 100%;
              color: #808080;
            }
          }

          > .text {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .eth {
              font-weight: bold;
              font-size: $text-size-bigish;
              margin-bottom: $margin-size-1;
            }

            .balance {
              font-size: 13px;
              color: #808080;
              display: flex;
              align-items: center;
            }
          }
        }
      }

      > .add-funds {
        .icons {
          svg {
            width: 25px;
            height: 25px;
            margin-left: $margin-size-1;
          }
        }
      }

      > .convert {
        .convert-text {
          color: white;
        }
        .icon {
          svg {
            margin-left: $margin-size-1;
            color: $white;
          }
        }
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: $bg-color-1;
          }
        }
      }

      > .add-fund {
        cursor: pointer;
        .add-fund {
          color: white;
        }
        .card-icon {
          margin-left: $margin-size-1;
          border-radius: 1px;

          // &.apple {
          //   color: black;
          //   width: 20px;
          //   border-radius: 2px;
          //   overflow: hidden;
          //   margin-bottom: 2px;
          //   background-color: white;
          // }
        }
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: $bg-color-1;
          }
        }
      }

      > .account {
        display: flex;
        flex-direction: column;

        > .link {
          color: $white;
          padding: 1rem $margin-size-3;
        }

        @media (hover: hover) and (pointer: fine) {
          > .link:hover {
            background-color: $bg-color-1;
          }
        }

        > .plus {
          font-size: $text-size-bigish;
          font-weight: 600;
          color: $border-color-2;
        }

        > .feedback {
          display: flex;
          flex-direction: column;

          > .title {
            font-size: $text-size-bigish;
            font-weight: 400;
            margin-bottom: $margin-size-1;
          }

          > .subtitle {
            font-size: $text-size-small;
            color: #808080;
          }
        }
      }

      > .log-out {
        margin: $margin-size-3;
        a {
          color: $white;
        }
      }
    }
  }
}

.context-dropdown-popover {
  > .MuiPaper-root {
    margin-top: 5px;
    background-color: $black;
    width: 200px;
    border-radius: 12px;
    border: 1px solid #20212e;
    overflow: hidden;
    z-index: 9999;

    .option {
      i {
        color: $grey;
      }
    }

    > .context-dropdown {
      > a {
        color: $white;
        display: block;
        padding: $margin-size-2;
        cursor: pointer;
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: $bg-color-1;
          }
        }
      }
      > div {
        padding: $margin-size-2;
        color: $white;
        cursor: pointer;

        a {
          color: $white;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: $bg-color-1;
          }
        }
      }
    }
  }
}

.like-indicator {
  color: #d1d1d3;
  font-size: 20px;
  width: 70px;
  display: flex;
  align-items: center;
  margin-right: $margin-size-2;
  transition: 0.5s;

  > .like-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    font-weight: 100;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        width: 40px;
        height: 40px;
        background-color: #434659;
      }
    }
  }

  span {
    font-size: 14px;
    color: #d1d1d3;
  }

  &.passive {
    color: #85868c;

    > span {
      color: #85868c;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: #ff009e;
      cursor: pointer;
    }
  }

  &.is-liked {
    color: #ff009e;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $white;
      }
    }

    span {
      color: #ff009e;
    }
  }
}

.bookmark-indicator {
  color: #d1d1d3;
  font-size: 20px;

  > .bookmark-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: 0.5s;
    font-weight: 100;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        width: 40px;
        height: 40px;
        background-color: #434659;
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      color: $white;
    }
  }

  &.is-bookmarked {
    color: #d1d1d3;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        color: $white;
      }
    }
  }
}

.app-spinner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 999;
  background: #191b26;
}

.app-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 999;
  background: rgba(0, 0, 0, 0.3);

  > .spinner {
    width: 70px;
    text-align: center;
    color: white;

    div {
      width: 16px;
      height: 16px;
      background-color: #d3eaea;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
      }

      40% {
        -webkit-transform: scale(1);
      }
    }

    @keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }

      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}

.inline-loader {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 999;

  > .spinner {
    width: 70px;
    text-align: center;
    color: white;

    div {
      width: 16px;
      height: 16px;
      background-color: #d3eaea;
      border-radius: 100%;
      display: inline-block;
      -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    }

    .bounce1 {
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    .bounce2 {
      -webkit-animation-delay: -0.16s;
      animation-delay: -0.16s;
    }

    @-webkit-keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
      }

      40% {
        -webkit-transform: scale(1);
      }
    }

    @keyframes sk-bouncedelay {
      0%,
      80%,
      100% {
        -webkit-transform: scale(0);
        transform: scale(0);
      }

      40% {
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
}

.fallback-loader {
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: flex;
}

.not-found {
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  align-items: center;
  justify-content: center;

  > .not-found-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    > .fourzerofour {
      font-size: 70px;
      font-weight: 500;
    }

    > .notfound {
      font-size: $text-size-big;
      font-weight: 500;
    }

    > .description {
      font-size: $text-size-medium;
    }

    > .link {
      display: flex;

      > .vertical {
        width: 2px;
        border: 1px solid $link-active-color;
      }
    }
  }
}

.placeholder-component {
  width: auto;
  height: auto;

  > .line {
    width: 150px;
    height: 12px;
    border: 2px solid $bg-color-2;
    border-radius: 10px;
    background-color: #20212e;
  }

  > .rectangle {
    width: 100%;
    height: 100%;
    border: 2px solid #20212e;
    border-radius: 10px;
    background-color: #20212e;
  }

  .is-load-animated {
    $grey-nuance-lighter: adjust-color(#20212e, $lightness: 3%);
    $grey-nuance-darker: adjust-color(#20212e, $lightness: 7%);
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-name: placeload;
    animation-name: placeload;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    background: $bg-color-2;
    background: linear-gradient(to right, $grey-nuance-lighter 8%, $grey-nuance-darker 18%, $grey-nuance-lighter 33%);
    -webkit-background-size: 800px 204px;
    background-size: 1200px 204px;
    position: relative;
  }

  @-webkit-keyframes placeload {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }

  @keyframes placeload {
    0% {
      background-position: -468px 0;
    }

    100% {
      background-position: 468px 0;
    }
  }
}

.hashtag-card-square-2 {
  width: 100%;
  $detail-height: 50px;
  $ratio: 100;

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  background-color: #232536;
  border: 1px solid $lightblue;
  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    margin-bottom: 4px;
    margin-top: 4px;
    cursor: pointer;
    > .hashtag-container {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #20212e;
      border: 2px solid white;
      i {
        font-size: 20px;
      }
    }
  }
  .height-decider {
    padding-bottom: calc(#{$ratio}% + 0px);
  }

  > .ellipsis {
    position: absolute;
    top: $margin-size-2;
    right: $margin-size-2;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: $margin-size-2;

    > .image {
      cursor: pointer;
    }

    > .hashtag-info {
      text-align: center;

      > .name {
        font-size: $text-size-bigish;
        cursor: pointer;
        max-width: 16ch;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      > .category {
        color: $lightestblue;
        display: flex;
        justify-content: center;
        font-size: $text-size-small;

        > .vertical {
          width: 3px;
          height: 15px;
          color: $lightestblue;
        }
      }
    }

    > .follow-count {
      font-size: $text-size-small;
      justify-content: center;
      height: 22px;
      text-align: center;
      width: 100%;
      align-items: flex-start;
      flex-wrap: wrap;
      display: flex;

      .follower {
        color: $grey;
      }

      span {
        white-space: nowrap;
      }
    }

    > .follow-button {
      width: 80%;
      white-space: nowrap;

      button {
        padding: 7px 0;
      }
    }
  }
}

.account-card {
  width: 100%;
  $detail-height: 50px;
  $ratio: 100;

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
  background-color: #232536;
  border: 1px solid $lightblue;
  .height-decider {
    padding-bottom: calc(#{$ratio}% + 0px);
  }

  > .ellipsis {
    position: absolute;
    top: $margin-size-2;
    right: $margin-size-2;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: $margin-size-2;

    > .image {
      cursor: pointer;
    }

    > .account-info {
      text-align: center;

      > .name {
        font-size: $text-size-bigish;
        cursor: pointer;
      }

      > .category {
        color: #9798a3;
        display: flex;
        justify-content: center;
        font-size: $text-size-small;

        > .vertical {
          width: 3px;
          height: 15px;
          color: $lightestblue;
        }
      }
    }

    > .follow-count {
      font-size: $text-size-small;
      justify-content: center;
      height: 44px;
      text-align: center;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
      display: flex;

      .follower {
        color: $grey;
      }
      span {
        white-space: nowrap;
      }
    }

    > .follow-button {
      height: 32px;
      width: 80%;
      white-space: nowrap;

      button {
        padding: 7px 0;
      }
    }
  }
}

.profile-describer-card {
  width: 320px;
  height: 280px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  background-color: black;
  border: 1px solid #20212e;

  &.block {
    height: 220px;
  }

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    position: absolute;
    padding: $margin-size-3;
    padding-bottom: 16px;

    .category {
      color: $oiblue;
      margin-bottom: 3px;
    }

    > .top-area {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      > .account-profile {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      > .name {
        color: white;
        font-size: $text-size-bigish;
        font-weight: bold;
      }

      > .slug {
        font-size: $text-size-small;
        color: $grey;
        margin-top: 4px;
      }
    }

    > .bottom-area {
      > .follow-count {
        a {
          color: white;
        }

        .follow {
          color: $grey;
        }
      }
    }
  }
}

.collectible-card-wrapper {
  padding: $margin-size-1;
}
.carousel-card-wrapper {
  padding: $margin-size-1;
  @media screen and (max-width: 700px) {
    padding: 0 !important;
  }
}
.placeholder-card {
}

.discover-card-group-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .carousel-button {
    background-color: rgba($color: #000000, $alpha: 0.4);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    z-index: 100;
    font-size: 24px;

    justify-content: center;
    color: white;
    transition: 0.2s;
    cursor: pointer;
    display: none;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #252730;
      }
    }
  }
  .left-button {
    position: absolute;
    left: 44px;
    top: 40%;
  }
  .right-button {
    position: absolute;
    right: 44px;
    top: 40%;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .carousel-button {
        display: flex;
      }
    }
  }

  .discover-card-group {
    width: 100%;
    max-width: 1920px;
    > .group-header {
      margin: $margin-size-2;
      > .group-title {
        .group-title-text {
          font-size: 18px;
          font-weight: 600;
        }

        .see-all {
          white-space: nowrap;
          font-size: 18px;
          min-width: 250px;
        }
      }

      .subtitle {
        font-size: 16px;
        color: $grey;
      }

      > .group-line {
        position: relative;

        &:before {
          content: '';
          display: block;
          width: 100%;
          height: 2px;
          background: adjust-color($border-color-1, $lightness: -0%);
          border-radius: 4px;
        }

        > .group-subtitle {
          font-size: $text-size-small;
          color: $grey;
          padding-top: 2px;
          padding-left: 2px;
        }
      }
    }

    > .all-group-items {
      //padding: 0px $margin-size-2;
      display: grid;
      margin: 0 auto;
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (min-width: 730px) {
      > .all-group-items {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media screen and (min-width: 990px) {
      > .all-group-items {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    @media screen and (min-width: 1180px) {
      > .all-group-items {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    @media screen and (min-width: 1368px) {
      > .all-group-items {
        grid-template-columns: repeat(6, 1fr);
      }
    }
    @media screen and (min-width: 1800px) {
      > .all-group-items {
        grid-template-columns: repeat(7, 1fr);
      }
    }

    > .group-items {
      position: relative;

      .collectible-card-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;

        .account-card {
          width: 100%;
        }
        .hashtag-card-square-2 {
          width: 100%;
        }

        .collectible-card {
        }

        .collectible-card2 {
        }

        .feature-card {
        }

        .placeholder-card {
        }
      }

      .carousel-button-group {
        position: absolute;
        top: -24px;
        right: 15px;
        font-size: 10px;
        color: grey;

        @media (hover: hover) and (pointer: fine) {
          .left-button:hover {
            cursor: pointer;
            color: white;
          }

          .right-button:hover {
            cursor: pointer;
            color: white;
          }
        }
      }

      .react-multi-carousel-list {
        display: flex;
        align-items: center;
        overflow: hidden;
        //position: relative;
      }

      .react-multi-carousel-track {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: row;
        position: relative;
        transform-style: preserve-3d;
        backface-visibility: hidden;
        will-change: transform, transition;
        z-index: 10;
      }

      .react-multiple-carousel__arrow {
        position: absolute;
        outline: none;
        transition: all 0.5s;
        border-radius: 35px;
        z-index: 1000;
        border: none;
        background: rgba(0, 0, 0, 0.5);
        min-width: 43px;
        min-height: 43px;
        opacity: 1;
        cursor: pointer;
      }

      @media (hover: hover) and (pointer: fine) {
        .react-multiple-carousel__arrow:hover {
          background: rgba(0, 0, 0, 0.8);
        }
      }

      .react-multiple-carousel__arrow::before {
        font-size: 20px;
        color: #fff;
        display: block;
        font-family: revicons;
        text-align: center;
        z-index: 2;
        position: relative;
      }

      .react-multiple-carousel__arrow--left {
        left: calc(4% + 1px);
      }

      .react-multiple-carousel__arrow--left::before {
        content: '\e824';
      }

      .react-multiple-carousel__arrow--right {
        right: calc(4% + 1px);
      }

      .react-multiple-carousel__arrow--right::before {
        content: '\e825';
      }

      .react-multi-carousel-dot-list {
        position: absolute;
        bottom: 0;
        display: flex;
        left: 0;
        right: 0;
        justify-content: center;
        margin: auto;
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;
      }

      .custom-dot-list {
        justify-content: flex-end;
        height: 100%;
        top: -26px;
        right: 6px;

        @media screen and (max-width: 450px) {
          top: -20px;
        }
        .react-multi-carousel-dot {
          button {
            display: inline-block;
            width: 22px;
            height: 5px;
            border-radius: 12px;
            opacity: 1;
            transition: background 0.3s;
            border: none;
            margin: 0;
            margin-right: 6px;
            outline: 0;
            cursor: pointer;
            background: #474951;
            position: relative;
            &::before {
              content: '';
              position: absolute;
              top: -9px;
              left: -2px;
              width: 26px;
              height: 22px;
            }
          }
        }

        .react-multi-carousel-dot--active {
          button {
            background: white;
          }
        }
      }

      .react-multi-carousel-dot button {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 1;
        padding: 5px 5px 5px 5px;
        box-shadow: none;
        transition: background 0.5s;
        border-width: 2px;
        border-style: solid;
        border-color: grey;
        padding: 0;
        margin: 0;
        margin-right: 6px;
        outline: 0;
        cursor: pointer;
      }

      @media (hover: hover) and (pointer: fine) {
        .react-multi-carousel-dot button:hover:active {
          background: #474951;
        }
      }

      .react-multi-carousel-dot--active button {
        background: white;
      }

      .react-multi-carousel-item {
        transform-style: preserve-3d;
        backface-visibility: hidden;
      }

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .react-multi-carousel-item {
          flex-shrink: 0 !important;
        }

        .react-multi-carousel-track {
          overflow: visible !important;
        }
      }
    }
  }
}

.collectible-buy-button {
  > .button {
    padding: 7px;
    min-width: 5rem;
    font-size: 0.9rem;
  }
}

.notification-card {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 15px 10px;
  border-radius: 10px;
  background-color: #232636;
  min-height: 90px;
  cursor: pointer;

  > .container-left {
    height: 100%;
    display: flex;

    align-items: flex-start;

    > .img-container {
      .img {
        border-radius: 10px;
        width: 60px;
        height: 60px;

        -o-object-fit: cover;
        object-fit: cover;
      }

      .reminder-img {
        border-radius: 10px;
        width: 50px;
        height: 50px;
        margin: 10px;
        background-color: #191b26;
        border-radius: 50%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    > .reminder-img-container {
      width: 60px;
      height: 60px;
      background-color: #191b26;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .reminder-img {
        width: 30px;
        height: 30px;
      }
    }

    > .notification-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-left: 15px;
      height: 100%;
      > .name-container {
        display: flex;
        align-items: center;
        > .name {
          font-size: 14px;
          color: $white;

          > p {
            margin-block-end: 0;
            margin-block-start: 0;
          }
        }

        .antic-icon {
          width: 16px;
          height: 16px;
          margin-left: 0.2rem;
          margin-right: 0;
        }

        > .slug {
          margin-left: 5px;
          color: $grey;
        }

        > .date {
          color: $grey;
          margin-left: 4px;
        }
      }

      > .category-container {
        display: flex;
        align-items: center;
        line-height: 20px;
        margin-top: 2px;

        > .category {
          display: flex;
          align-items: center;

          .follows-you-badge {
            background-color: #1e1f2d;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20px;
            font-size: 12px;
            color: #8f8f96;
            padding: 4px 8px;
            margin-left: 7px;
          }
        }
      }

      > .statuse {
        font-size: $text-size-medium;
        color: $white;
        margin-top: 6px;

        > p {
          margin-block-end: 0;
          margin-block-start: 0;
          width: 100%;
        }

        > div {
          max-width: 250px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      > .date {
        font-size: $text-size-small;
        color: $grey;
      }
    }
  }

  > .container-right {
    display: flex;
    align-items: center;

    > .icon-circle {
      color: $grey;
      svg {
        width: 18px;

        path {
          fill: $grey;
        }
      }
      > .circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $oired;
      }
    }
  }

  &.active {
    background-color: #2e3146;
  }
}

.notification-card-group {
  width: 100%;
  height: auto;

  > .content {
    display: grid;
    grid-row-gap: 10px;
  }
}

.collectible-preview-carousel {
  width: 100%;
  height: 100%;

  .restricted-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    //    background-color: rgba(0, 0, 0, 0.98);
    z-index: 10;

    > .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .icon {
        font-size: $text-size-bigger;
      }

      > .title {
        font-size: $text-size-bigish;
        font-weight: 700;
      }

      > .sub-title {
        font-size: $text-size-medium;
      }

      > .button {
      }
    }
  }

  > .image {
    height: 100%;
    text-align: center;

    > .cover-image {
      object-fit: contain;
    }
  }

  > .carousel-button {
    width: 100%;
    position: absolute;
    bottom: $margin-size-3;
    display: flex;
    align-items: center;
    justify-content: center;

    > .buttonc {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $grey;

      > .active {
        background-color: $white;
      }
    }
  }

  > .category-icon-box {
    position: absolute;
    bottom: $margin-size-3;
    right: $margin-size-3;
    width: 23px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $white;
    border-radius: 5px;
  }
}

.share-social-media {
  padding: $margin-size-1;
  color: $border-color-2;
  cursor: pointer;
}

.follower-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: $margin-size-2;
  border-radius: 10px;
  background-color: #232636;
  cursor: pointer;
  margin-bottom: $margin-size-2;

  > .container-left {
    display: flex;
    align-items: center;
    flex: 1;

    > .profile-image {
    }

    > .follower-info {
      .actor-follow-state {
        font-size: $text-size-small;
        color: #8f8f96;
        padding: 4px 8px;
        border-radius: 8px;
        background-color: #1e1f2d;

        font-size: 10px;
      }
      > .name-container {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        > .name {
          font-size: 14px;
          color: $white;
          font-weight: bold;

          > p {
            margin-block-end: 0;
            margin-block-start: 0;
          }
        }

        > .slug {
          margin-left: 3px;
          color: $grey;
        }

        @media screen and (max-width: 450px) {
          flex-direction: column;
          align-items: flex-start;

          > .slug {
            margin-top: 4px;
            margin-left: 0;
          }
        }
      }

      > .category {
        margin-top: 5px;
        font-size: 14px;
        margin-left: 0px;
        align-items: center;
        min-height: 16px;
        width: min-content;

        @media screen and (max-width: 450px) {
          .actor-follow-state {
            white-space: nowrap;
            margin: 0 2px;
          }
        }

        > p {
          margin-block-end: 0;
          margin-block-start: 0;
        }
      }

      > .followers {
        font-size: 14px;
        color: $grey;
        margin-top: 6px;
      }
    }
  }

  > .container-right {
    display: flex;
    align-items: center;

    > .collectible-more {
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      transition: 0.5s;
      font-weight: 100;
      color: #85868c;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          cursor: pointer;
          background-color: #434659;
          color: white;
        }
      }
    }
  }

  &.active {
    background-color: $bg-color-2;
  }
}

.tooltip {
  > .MuiTooltip-tooltip {
    background-color: transparent;
    padding: 0;
    font-size: 1rem;
    line-height: 1rem;
  }

  .MuiTooltip-arrow {
    color: #20212e;
  }
}

.comments {
  .comment-button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }

  > .comments-header {
    display: flex;
    justify-content: space-between;
    color: $grey;

    .comment-button {
      font-size: 12px;
    }
  }

  .comment-text {
    font-weight: 600;
    font-size: $text-size-small;
  }

  .comment-box {
    .input-validation-error {
      display: none;
    }
    .user-comment {
      flex-flow: row nowrap;
      display: flex;
      width: 100%;
      align-items: center;
      .comment-input-container {
        flex-grow: 1;
        display: flex;
        align-items: center;
        width: 100%;

        form {
          width: 100%;
          margin: 0;
        }

        .input {
          width: 100%;
        }
      }
    }
  }

  .comment-container {
    .comment-button {
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      flex-wrap: wrap;
      color: $grey;
    }

    .comment-display {
      margin-bottom: $margin-size-3;

      .row-1 {
        display: flex;
        flex-flow: row nowrap;
        .col-2 {
          .comment-content {
            position: relative;
            .dropdown-icon {
              position: absolute;
              right: 0px;
              top: -15px;
              color: #85868c;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.5s;

              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  cursor: pointer;
                  background-color: #434659;
                  color: white;
                }
              }
            }
          }
        }

        > .col-1 {
        }

        > .col-2 {
          flex-grow: 1;
          font-size: $text-size-small;

          .comment-header {
            margin-bottom: $margin-size-1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .profile-info {
              font-weight: bold;
              font-size: 14px;
              display: flex;
              align-items: baseline;
            }

            a {
              color: white;
              font-weight: bold;
            }

            .slug {
              margin-left: 6px;
              color: $grey;
              font-weight: 500;
            }

            .date {
              margin-left: 6px;
              color: $grey;
              font-weight: 500;
            }
          }

          .comment-content {
            padding-right: 36px;
            form {
              margin: 0;
            }

            font-size: 14px;
          }

          .comment-details {
            margin-top: 8px;
            font-size: $text-size-small;
            font-weight: bold;
            display: flex;
            align-items: center;

            .comment-icon {
              display: flex;
              align-items: center;

              .comment {
                font-size: 18px;
                color: #85868c;
                transition: 0.5s;

                @media (hover: hover) and (pointer: fine) {
                  &:hover {
                    color: $oiblue;
                    cursor: pointer;
                  }
                }
              }

              span {
                margin-top: 2px;
                color: #85868c;
              }
            }

            .like-indicator {
              width: auto;

              > .like-icon {
                width: auto;
                height: auto;

                @media (hover: hover) and (pointer: fine) {
                  &:hover {
                    background-color: transparent;
                  }
                }
              }
            }

            .icon {
              font-size: 18px;
            }

            .button {
              font-weight: bold;
              padding-left: $margin-size-1;
              padding-right: $margin-size-1;
            }
          }
        }
      }

      .row-2 {
        > .reply-box {
          display: flex;
          width: 100%;
          align-items: center;

          form {
            margin: 0;
            width: 100%;
            padding-left: $margin-size-2;
          }

          &.parent-reply {
            padding-left: calc(#{$margin-size-2} + 38px);
          }

          &.child-reply {
            padding-left: 0;
          }
        }
      }

      > .child-comments {
        padding-left: calc(#{$margin-size-2} + 38px);
      }
    }
  }
}

.detailed-comments {
  .top-border {
    margin: 0 $margin-size-2;
    //    padding: $margin-size-2 0;
    height: 0px;
    border-top: solid 1px $hr-color;
  }

  .input-validation-error {
    display: none;
  }
  .comment-button {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
  }

  > .comments-header {
    display: flex;

    .comment-button {
      font-size: 12px;
    }
  }

  .comment-text {
    font-weight: 600;
    font-size: $text-size-small;
  }

  .comment-box {
    display: flex;
    flex-flow: row nowrap;

    .comment-input-container {
      flex-grow: 1;
      display: flex;
      align-items: center;
      width: 100%;

      form {
        width: 100%;
        margin: 0;
      }

      .input {
        width: 100%;
      }
    }
  }

  .comment-container {
    .comment-button {
      cursor: pointer;
      font-size: 13px;
      font-weight: 500;
      flex-wrap: wrap;
      color: $grey;
    }

    .comment-display {
      margin-bottom: $margin-size-3;

      .row-1 {
        display: flex;
        flex-flow: row nowrap;

        .col-2 {
          .comment-content {
            position: relative;
            .dropdown-icon {
              position: absolute;
              right: 0px;
              top: -15px;
              color: #85868c;
              width: 36px;
              height: 36px;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              transition: 0.5s;

              @media (hover: hover) and (pointer: fine) {
                &:hover {
                  cursor: pointer;
                  background-color: #434659;
                  color: white;
                }
              }
            }
          }
        }

        > .col-1 {
        }

        > .col-2 {
          flex-grow: 1;
          font-size: $text-size-small;

          .comment-header {
            margin-bottom: $margin-size-1;
            display: flex;
            justify-content: space-between;

            .profile-info {
              display: flex;
              align-items: center;

              a {
                color: white;
                font-weight: bold;
              }

              .slug {
                margin-left: 6px;
                color: $grey;
                font-weight: 500;
              }

              .date {
                margin-left: 6px;
                color: $grey;
                font-weight: 500;
              }
            }

            .profile-info {
              display: flex;
              align-items: center;
            }
            a {
              color: white;
              font-weight: bold;
            }

            .date {
              font-weight: 600;
              color: $grey;
            }
          }

          .comment-content {
            padding-right: 36px;
            form {
              margin: 0;
            }

            font-size: 14px;
          }

          .comment-details {
            margin-top: 8px;
            font-size: $text-size-small;
            font-weight: bold;
            display: flex;
            align-items: center;

            .comment-icon {
              display: flex;
              align-items: center;

              .comment {
                font-size: 18px;
                color: #85868c;
                transition: 0.5s;

                @media (hover: hover) and (pointer: fine) {
                  &:hover {
                    color: $oiblue;
                    cursor: pointer;
                  }
                }
              }

              span {
                margin-top: 2px;
                color: #85868c;
              }
            }

            .like-indicator {
              width: auto;

              > .like-icon {
                width: auto;
                height: auto;

                @media (hover: hover) and (pointer: fine) {
                  &:hover {
                    background-color: transparent;
                  }
                }
              }
            }

            .icon {
              font-size: 18px;
            }

            .button {
              font-weight: bold;
              padding-left: $margin-size-1;
              padding-right: $margin-size-1;
            }
          }
        }
      }

      .row-2 {
        > .reply-box {
          display: flex;
          width: 100%;
          align-items: center;

          form {
            margin: 0;
            width: 100%;
            padding-left: $margin-size-2;
          }

          &.parent-reply {
            padding-left: calc(#{$margin-size-2} + 38px);
          }

          &.child-reply {
            padding-left: 0;
          }
        }
      }

      > .child-comments {
        padding-left: calc(#{$margin-size-2} + 38px);
      }
    }
  }
}

.carousel {
  position: relative;
  width: 100%;
  height: 600px;

  .height-decider {
    padding-bottom: 562px !important;
  }

  .video-react-big-play-button {
    display: none;
  }
  > .carousel-button-group {
    width: 100%;
    top: 50% !important;
    right: 0 !important;

    > .buttons {
      width: 100%;
      justify-content: space-between;
      padding: 0 $margin-size-3;

      div {
        background-color: rgba($color: #000000, $alpha: 0.4);
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        z-index: 100;
        font-size: 32px;
        justify-content: center;
        color: white;
      }

      > .right-button::before {
        margin-left: -9px;
      }

      > .left-button {
        justify-content: center;
      }
    }
  }

  .carousel-button {
    background-color: rgba($color: #000000, $alpha: 0.4);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    z-index: 100;
    font-size: 30px;
    justify-content: center;
    color: white;
    transition: 0.2s;
    cursor: pointer;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #252730;
      }
    }
  }
  .left-button {
    position: absolute;
    left: 10px;
    top: 50%;
  }
  .right-button {
    position: absolute;
    right: 10px;
    top: 50%;
  }

  @media (max-width: 700px) {
    height: auto;

    .react-multi-carousel-dot-list {
      bottom: 7px !important;
      button {
        width: 7px !important;
        height: 7px !important;
        border: none !important;
        background-color: #707070;
      }

      .react-multi-carousel-dot--active {
        button {
          background-color: $white;
        }
      }
    }

    .height-decider {
      padding-bottom: 100% !important;
    }

    .discover-card-group {
      margin-top: $margin-size-2;

      .collectible-card2 {
        margin: 0 0 30px 0 !important;
        border-radius: 0;
        //margin: $margin-size-1;

        .collectible-detail {
          background-color: transparent;
          border-bottom: 1px solid #2e3146;
        }

        .price {
          font-size: 16px;
          font-weight: 600;
        }

        .status {
          font-size: 16px;
          font-weight: 600;
        }

        .edition {
          font-size: 16px;
        }

        .collectible-icon {
          width: 30px !important;
          height: 30px !important;
          border: 1px solid $white !important;

          > i {
            font-size: 14px;
          }
        }

        .creator-images {
          .profile-avatar {
            width: 30px !important;
            height: 30px !important;
          }
        }
      }
    }

    .react-multi-carousel-list {
      height: 100%;
    }
  }
}

.post-preview {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  display: flex;
  justify-content: center;
  background-color: $black;
  cursor: pointer;

  .creator-images {
    position: absolute;
    top: $margin-size-2;
    right: $margin-size-2;
  }

  .restricted-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    > .text {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 80%;

      > .icon {
        font-size: $text-size-bigger;
      }

      > .title {
        font-size: $text-size-bigish;
        font-weight: 700;
      }

      > .sub-title {
        font-size: $text-size-medium;
      }

      > .button {
      }
    }
  }

  > .cover-image {
    position: absolute;
    top: 0;
    width: auto;
    height: 100%;
  }

  > .collectible-video {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;

    > .video-react-video {
      height: 100%;
    }

    .video-react-big-play-button {
      display: none;
    }
  }

  > .collectible-icons {
    position: absolute;
    bottom: $margin-size-2;
    right: $margin-size-2;
    display: flex;
    flex-direction: column;
    align-items: center;

    .collectible-icon {
      font-size: 14px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      border: 1px solid $lightgrey;
      background-color: rgba(0, 0, 0, 0.5);
      text-shadow: 0px 0px 3px $black;
      &--borderless {
        border: none;
      }
      &--ethereum {
        background-color: $ethereum;
      }
      &--polygon {
        background-color: $polygon;
      }
      &--avalanche {
        background-color: $avalanche;
      }
      &--binance {
        background-color: $binance;
      }
      &--default {
        background-color: $oigreen;
      }
    }
  }
  > .auction-countdown {
    position: absolute;
    top: $margin-size-2;
    left: $margin-size-2;
    padding: 3px 8px;
    border-radius: 12px;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
  }
  > .price-info {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: $margin-size-2;
    left: $margin-size-2;

    > div {
      padding: 3px 8px;
      border-radius: 12px;
      font-size: 14px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.mobile-search-screen {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $bg-color-1;
  z-index: 999;

  > .header {
    height: $appheaderHeight;
    width: 100%;
    background-color: $bg-color-1;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border: 1px solid transparent;
    border-bottom: 1px solid $hr-color;

    > .back-icon {
      height: 41.03px;
      width: 41.03px;
      margin-left: $margin-size-2;

      > .back-button {
        background: $lightblue;
        cursor: pointer;
        height: 41.03px;
        width: 41.03px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        color: white;
      }
    }

    > .input {
      width: 100%;
      margin: 0 $margin-size-2;

      > .global-search {
        position: relative;

        > .input {
          i {
            color: grey;
          }
          > .input-validation-error {
            display: none;
          }
        }

        > .history-box {
          position: absolute;
          top: 56.5px;
          left: -64px;
          width: 100vw;
          height: auto;
          background-color: $bg-color-1;
          .hashtag {
            color: $oiblue;
          }
          .recently {
            color: #9798a3;
            padding: $margin-size-2 $margin-size-2;
          }

          i {
            color: #9798a3;
          }

          .recently-searched-accounts {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin: 0 $margin-size-2;

            > .content-wrapper {
              display: flex;
              align-items: center;
              gap: 10px;
              width: 100%;
              height: 60px;

              .profile-avatar {
                > img, .placeholder {
                  border: 1px solid;
                  border-color: #00B7FF !important;
                }
              }
              
              .account-info {
                .account-name {
                  color: $white;
                  font-size: 16px;
                  font-weight: bold;
                  display: inline-block;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis; 
                  -o-text-overflow: ellipsis;
                  max-width: 200px;
                }

                .account-slug {
                  color: rgba(#ffffff, 0.5);
                  font-size: 16px;
                }
              }

              .recently-account-icon {
                display: flex;
                justify-content: flex-end;
                width: 100%;

                i {
                  rotate: 45deg;
                }
              }
            }
          }

          i {
            color: #9798a3;
          }

          .history-item {
            padding: 0 $margin-size-2;
            color: $white;
            height: 60px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > .history-item-left-content {
              display: flex;
              align-items: center;
              .search-icon-container {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(#ffffff, 0.1);
                margin-right: $margin-size-2;
              }
            }

            > .history-item-right-content {
              rotate: 45deg;
            }

            &.hashtag {
              color: $oiblue;
            }

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                background-color: rgba(#000000, .1);
              }
            }
          }

          .search-item {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 $margin-size-2;
            cursor: pointer;

            &:hover {
              background-color: rgba(#000000, .1);
            }

            > .left-side {
              display: flex;
              align-items: center;
              gap: 10px;

              .content {
                .name {
                  font-size: 16px;
                  font-weight: bold;
                  color: $white;
                }

                .category {
                  font-size: 16px;
                  color: #00B7FF;
                }

                .slug {
                  color: rgba(#ffffff, .5);
                }
              }

              path {
                fill: #9798a3;
              }

              &.square {
                .profile-avatar {
                  > img {
                    border: none;
                    border-radius: 5px;
                  }

                  > .placeholder {
                    border: 1px solid;
                    border-radius: 5px;
                  }
                }
              }

              &:not(.square) {
                .profile-avatar {
                  > img, .placeholder {
                    border: 1px solid;
                    border-color: #00B7FF !important;
                  }
                }
              }
            }

            > .right-side {
              display: flex;
              align-items: center;
              gap: 5px;
              color: rgba(#ffffff, 0.5);
              visibility: hidden;
            }

            &:hover {
              > .right-side {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  > .search-history {
    height: 100%;

    > .item {
      width: 100%;
      padding: 10px $margin-size-2;
    }
  }
}

.oix-spinner {
  > .spinner-area {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: spin 1s linear infinite;
    background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);

    @keyframes spin {
      from {
        transform: rotate(0deg);
      }

      to {
        transform: rotate(359deg);
      }
    }

    > .spinner {
      background-color: $bg-color-2;
      width: 80px;
      height: 80px;
      border-radius: 50%;
    }
  }
}

.collectible-card2 {
  $detail-height: 78px;
  $ratio: 100;
  width: 100%;
  box-sizing: content-box;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  cursor: pointer;
  transform: translateZ(0);
  color: white;

  &:hover {
    color: white;
  }

  .height-decider {
    padding-bottom: calc(#{$ratio}% + #{$detail-height});
  }

  .collectible-more-container {
    position: absolute;
    right: 12px;
    top: $margin-size-2;
    font-size: 16px;
    z-index: 10;
    padding: 4px;
    border-radius: 50%;

    cursor: pointer;
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    position: absolute;
    top: $margin-size-2;
    left: $margin-size-2;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;

    div {
      display: flex;
      padding: 3px 6px;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }

    .draft {
      .icon {
        color: #606060;
      }
    }

    .time {
      .icon {
        color: white;
      }
    }
    .minted {
      .icon {
        color: #ff9900;
      }
    }

    .transferring {
      .icon {
        color: #8800ff;
      }
    }

    .unlisted {
      .icon {
        color: #ff9900;
      }
    }

    .listed,
    .owned {
      .icon {
        color: #00dcaf;
      }
    }

    .sold {
      .icon {
        color: #ff009e;
      }
    }
  }
  .collectible-top-container {
    width: 100%;
    height: calc(100% - #{68px});
    position: absolute;
    cursor: pointer;
    background-color: #2c2d3f;
    .collectible-image,
    .collectible-video > video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .collectible-cover-image {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      > .collectible-cover-image-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75px;
        height: 75px;
        border: 4px solid #4d4d58;
        border-radius: 50%;

        svg {
          fill: #4d4d58;
          width: 80%;
          height: 80%;
        }
      }
    }

    .collectible-cover-image-processing {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #20212e;
      position: relative;

      > .collectible-cover-image-circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 78px;
        height: 78px;
        border-radius: 50%;
        background-image: linear-gradient(0deg, #b603e9 0%, #7030f0 50.62%, #09dfff 100%);
        animation: spin 2s linear infinite;

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }

          to {
            transform: rotate(359deg);
          }
        }
      }

      > .collectible-cover-image-inner-circle {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 71px;
        height: 71px;
        border-radius: 50%;
        background-color: #20212e;
        font-size: 24px;
        color: #4c4d57;
      }
    }

    > .collectible-icons {
      position: absolute;
      bottom: $margin-size-2;
      right: $margin-size-2;
      display: flex;
      flex-direction: column;
      align-items: center;

      .collectible-icon {
        @extend .text-small;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        border: 1px solid $lightgrey;
        background-color: rgba(0, 0, 0, 0.5);
        text-shadow: 0px 0px 3px $black;
        &--borderless {
          border: none;
        }
        &--ethereum {
          background-color: $ethereum;
        }
        &--polygon {
          background-color: $polygon;
        }
        &--avalanche {
          background-color: $avalanche;
        }
        &--binance {
          background-color: $binance;
        }
        &--default {
          background-color: $oigreen;
        }
      }
    }

    > .creator-images {
      position: absolute;
      top: $margin-size-2;
      right: $margin-size-2;
    }

    > .price-info {
      display: flex;
      align-items: center;
      position: absolute;
      bottom: $margin-size-2;
      left: $margin-size-2;

      > div {
        padding: 3px 8px;
        border-radius: 12px;
        font-size: 12px;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }

  .collectible-detail {
    height: 68px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0;

    .collectible-detail-user-info {
      .collectible-title {
        width: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        color: white;
      }
      .collectible-dark-title {
        color: #9798a3;
      }
    }

    .collectible-category {
      @extend .text-small;
      color: $oiblue !important;
      margin-top: 0.2rem;

      width: max-content;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: lighten($oiblue, 10%);
        }
      }
    }
  }
}

.shaka-player {
  .player-style {
    padding: 12px 2px;
    background: $lightblue !important;
    border-radius: 8px;
    bottom: 70px;
    color: white;
    border: 1px solid $lightblue;
  }

  > .shaka-video-container {
    .shaka-overflow-menu {
      @extend .player-style;

      .shaka-current-selection-span {
        color: $lightgrey;
      }
    }

    .shaka-settings-menu {
      @extend .player-style;

      .shaka-current-selection-span {
        color: $lightgrey;
      }
    }

    .shaka-overflow-menu button,
    .shaka-settings-menu button {
      color: white;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: $lightblue;
        }
      }
    }

    .shaka-scrim-container {
      background: linear-gradient(to top, #000 0, rgba(0, 0, 0, 0) 9%);
    }

    .shaka-bottom-controls {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 1.5%;
      padding-top: 1.5%;
      width: 100%;

      .shaka-controls-button-panel {
        margin-top: $margin-size-1;
      }
    }

    .shaka-play-button {
      padding: 2.5%;
    }

    .shaka-play-button[icon-play] {
      background: transparent;
    }

    .shaka-small-play-button {
      font-size: 34px;
    }
  }
}

.display-public-address {
  display: flex;
  align-items: center;
  background-color: #444659;
  padding: $margin-size-1 $margin-size-2;
  border-radius: 15px;
  font-size: $text-size-medium;
  font-weight: 500;
  cursor: pointer;

  > .icon {
    margin-left: $margin-size-2;
    color: #7b7c89;

    &.check-circle {
      color: $link-color;
    }
  }
}

.highlight {
  background-color: $lightblue;
  border-radius: 20px;
  > .title {
    padding: $margin-size-2;
    border-bottom: 1px solid $hr-color;
    color: #9798a3;
    font-size: 14px;
  }

  > .footer {
    padding: $margin-size-2;
    a {
      color: $oiblue;
      font-size: 12px;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: lighten($oiblue, 10%);
        }
      }
    }
  }

  > .placeholder-card {
    cursor: pointer;
    border-bottom: 1px solid $hr-color;
    padding: 1.6px $margin-size-2;
    width: 360px;
    height: 80px;
  }

  > .trend-card {
    cursor: pointer;
    border-bottom: 1px solid $hr-color;
    padding: 8px $margin-size-2;
    display: flex;
    width: 360px;
    height: 80px;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #252738;
      }
    }

    > .img-container {
      width: 60px;
      height: 60px;
      margin-right: 8px;

      img {
        border-radius: 10px;
        width: 60px;
        height: 60px;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    > .content {
      width: 100%;
      margin: 1px 0px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      > .info {
        > .title {
          font-weight: 600;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        > .category {
          line-height: 16px;
          margin-top: 4px;
          color: $oiblue;
          font-size: 14px;
          width: max-content;
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: lighten($oiblue, 10%);
            }
          }
        }
      }

      > .icons {
        display: flex;
        position: relative;
        width: 100%;
        margin-top: -20px;
        max-height: 14px;

        > .trending-now-text {
          font-size: 14px;
          color: rgba(#ffffff, 0.5);
        }

        > .right-icon {
          position: absolute;
          right: 0px;
          width: auto;

          path {
            fill: #92939c;
          }
        }
      }
    }
  }

  > .auction-card {
    cursor: pointer;
    border-bottom: 1px solid $hr-color;
    padding: 8px $margin-size-2;
    display: flex;
    width: 360px;
    height: 80px;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #252738;
      }
    }

    > .img-container {
      width: 60px;
      height: 60px;
      margin-right: 8px;
      img {
        border-radius: 10px;
        width: 60px;
        height: 60px;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    > .content {
      width: 100%;
      margin: 1px 0px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      > .info {
        > .title {
          font-size: 14px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        > .category {
          margin-top: 4px;
          color: $oiblue;
          font-size: 14px;

          width: max-content;
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: lighten($oiblue, 10%);
            }
          }
        }
      }

      > .icons {
        display: flex;
        position: relative;
        div {
          margin-right: $margin-size-1;
          display: flex;
          color: #92939c;

          font-size: 12px;
          i {
            margin-right: 2px;
          }
        }
      }
    }
  }
  > .recommended-account-card {
    cursor: pointer;
    border-bottom: 1px solid $hr-color;
    padding: 8px $margin-size-2;
    display: flex;
    width: 360px;
    align-items: center;
    height: 80px;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #252738;
      }
    }

    > .img-container {
      margin-right: 8px;
      display: flex;
      align-items: center;
    }

    > .content {
      width: 100%;
      margin: 1px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > .info {
        > .title {
          font-weight: bold;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: nowrap;
          max-width: 15ch;
          text-overflow: ellipsis;
        }
        > .info-category {
          height: 17px;
          margin-top: 4px;
          display: flex;
          align-items: center;

          > .category {
            color: $grey;
            font-size: 14px;
          }
          .follow-back-button {
            margin-left: 8px;
            background-color: #1e1f2d;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70px;
            height: 17px;
            font-size: 10px;
            color: #8f8f96;
          }
        }
      }
    }
  }
  > .hashtag-card {
    cursor: pointer;
    border-bottom: 1px solid $hr-color;
    padding: 8px $margin-size-2;
    display: flex;
    width: 360px;
    align-items: center;
    height: 80px;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: #252738;
      }
    }

    > .img-container {
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      > .hashtag-container {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #20212e;
        border: 2px solid white;
        i {
          font-size: 20px;
        }
      }
    }

    > .content {
      width: 100%;
      margin: 1px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > .info {
        > .title {
          font-weight: bold;
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        > .follower-count {
          color: $grey;
          margin-top: 4px;
          font-size: 14px;
        }
      }

      .follow-back-button {
        background-color: #1e1f2d;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 20px;
        font-size: 10px;
        margin-top: 8px;
        color: #85858d;
      }
    }
  }
}

.blur-5 {
  @include blur(5px);
}
.blur-10 {
  @include blur(10px);
}
.blur-15 {
  @include blur(15px);
}
.blur-20 {
  @include blur(20px);
}
.blur-30 {
  @include blur(30px);
}