.mainSlider {
  margin-top: -14px;
  position: relative;

  .discover-carousel .react-multi-carousel-list .custom-dot-list {
    top: unset;
    bottom: 23px;
  }
  .discover-carousel .react-multi-carousel-list .custom-dot-list .react-multi-carousel-dot button {
    background: white;
    opacity: 0.3;
    width: 100px;
  }

  .react-multi-carousel-dot--active > button{
    opacity: 1 !important;
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    width: 100%;
    aspect-ratio: 1920/680;
  }

  &__learnMore {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 60px;
  }

  &__learnMoreText {
    &:hover {
      text-decoration: underline;
    }
  }

  &__play {
    padding-right: 5px;
  }

  &__buttons {
    bottom: 130px;
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 40px;

    .button {
      font-size: 18px;
      width: 200px;
      height: 50px;
    }
  }

  &__image {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: fill;

    &--mobile {
      display: none;
    }
  }

  &__title {
    font-size: 64px;
    line-height: 72px;
    margin-bottom: 28px;
    width: 100%;
    text-align: center;
    margin-top: auto;
    font-weight: 600;
  }

  &__subTitle {
    text-align: center;
    font-weight: 600;
    width: 100%;
    font-size: 36px;
    line-height: 41px;
    margin-bottom: 90px;
  }
  @media screen and (max-width: 1650px) {
    &__card {
    }
    &__title {
      font-size: 46px;
      margin-bottom: 25px;
    }
    &__learnMore {
      margin-bottom: 46px;
    }

    &__subTitle {
      font-size: 28px;
      text-align: center;
    }

    &__buttons {
      margin-bottom: 28px;

      .button {
        font-size: 16px;
      }
    }

    .discover-carousel .react-multi-carousel-list .custom-dot-list {
      bottom: 16px;
    }
  }

  @media screen and (max-width: 1280px) {
    &__subTitle {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 600px) {
    &__card {
      justify-content: flex-start;
      height: 645px;
    }
    &__title {
      padding-right: 30px;
      margin-top: 20px;
      font-size: 48px;
      text-align: start;
      line-height: 48px;
    }

    &__subTitle {
      text-align: start;
      font-size: 24px;
      line-height: 30px;
    }

    &__buttons {
      margin-top: auto;
      .button {
        width: 156px;
      }
    }

    &__image {
      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
    }
  }
}
