.articles {
  width: 100%;
  margin-top: 30px;
  padding: 0 30px 30px 30px;

  .discover-carousel {
    padding-bottom: 40px;

    .right-button, .left-button {
      top: calc(50% - 60px);
    }
  }

  .react-multi-carousel-list {
    border-bottom: 1px solid #2e3247;
  }

  &__card {
    margin: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  &__header {
    font-weight: 600;
    font-size: 30px;
    line-height: 34px;
    margin-bottom: 55px;
    margin-left: 0.4rem;
  }

  &__image {
    border-radius: 15px;
    margin-bottom: 18px;
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;
  }

  &__title {
    width: 100%;
    text-align: start;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 18px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__date {
    width: 100%;
    text-align: start;
    font-size: 18px;
    color: $grey;
  }

  @media screen and (max-width: 700px) {
    padding: 0 4px;
  }

  @media screen and (min-width: 700px) {
    .discover-carousel .react-multi-carousel-list .react-multi-carousel-dot-list {
      display: none;
    }
  }

  @media screen and (max-width: 650px) {
    .discover-carousel {
      height: 405px;
      padding-top: 10px;
      
    }
    &__card {
      // height: 225px;
    }

    &__header {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
}
